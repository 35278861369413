/**
 * Private Data Streamer API
 * Private Data Streamer Swagger API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: dagur@kodi.is
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The type of the order.  Not all order types are supported by all trading gateways.
 */
export type OrderType =
    'limit' | 'market' | 'market_to_limit' |
    'pegged' | 'nordic_at_mid' | 'limit_on_open' |
    'limit_on_close' | 'market_on_open' | 'market_on_close' |
    'imbalance_on_open' | 'imbalance_on_close' | 'stop' |
    'stop_limit' | 'with_or_without' | 'limit_or_better' |
    'limit_with_or_without' | 'on_basis' | 'on_close' |
    'forex_market' | 'previously_quoted' | 'previously_indicated' |
    'forex_limit' | 'forex_swap' | 'forex_previously_quoted' | 'funari';

export const Order = {
    limit: 'limit' as OrderType,
    market: 'market' as OrderType,
    market_to_limit: 'market_to_limit' as OrderType,
    pegged: 'pegged' as OrderType,
    nordic_at_mid: 'nordic_at_mid' as OrderType,
    limit_on_open: 'limit_on_open' as OrderType,
    limit_on_close: 'limit_on_close' as OrderType,
    market_on_open: 'market_on_open' as OrderType,
    market_on_close: 'market_on_close' as OrderType,
    imbalance_on_open: 'imbalance_on_open' as OrderType,
    imbalance_on_close: 'imbalance_on_close' as OrderType,
    stop: 'stop' as OrderType,
    stop_limit: 'stop_limit' as OrderType,
    with_or_without: 'with_or_without' as OrderType,
    limit_or_better: 'limit_or_better' as OrderType,
    limit_with_or_without: 'limit_with_or_without' as OrderType,
    on_basis: 'on_basis' as OrderType,
    on_close: 'on_close' as OrderType,
    forex_market: 'forex_market' as OrderType,
    previously_quoted: 'previously_quoted' as OrderType,
    previously_indicated: 'previously_indicated' as OrderType,
    forex_limit: 'forex_limit' as OrderType,
    forex_swap: 'forex_swap' as OrderType,
    forex_previously_quoted: 'forex_previously_quoted' as OrderType,
    funari: 'funari' as OrderType
};
