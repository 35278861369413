import { Subscription } from "rxjs";
import { ListInfo } from "../../../Types/LMDTypes";
import { SymbolWindowComponent } from "../AbstractWindow";
import { OrderMessage, TradeMessage } from "../../../Types/Websocket";
import { MUITableState } from "../../../Types/MUITable";
import { getRetailHandler } from "../../../StateManager";
import { RetailCustomerSuccess } from "../../../Types/RetailTradingTypes";
import { useEffect, useState } from "react";
import { ViewOrderMUITable } from "../../Tables/ViewOrderMUIProTable";
import { OrderColumnsInfo } from "../ViewOrders/Columns";
import { formatNumber, formatTimeWithDate } from "../../../Utils/Formatting";
import TableBar from "../../UI-Elements/TableBar";
import PortfolioOverview from "./PortfolioOverview";
import SegmentedControl from "./SegmentedControl";
import { TradeColumnsInfo } from "../ViewTrades/Columns";

type SaveState = {
  linked: boolean;
  symbolOrList: ListInfo | string | undefined;
  tableState: MUITableState | undefined;
  activeToggle: "Portfolio" | "Orders" | "Trades";
};

type RunState = {
  orders: OrderMessage[];
  symbols: string[];
  lists: ListInfo[];
  loading: boolean;
  isOpen: boolean;
  trade_status: string;
  trader: string;

  customerInfo: RetailCustomerSuccess | undefined;
};
const segments = [
  { label: "Portfolio", value: "Portfolio" },
  { label: "Orders", value: "Orders" },
  { label: "Trades", value: "Trades" }
];
export class RetailTrading extends SymbolWindowComponent<RunState, SaveState> {
  linkSubscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      orders: [],
      symbols: [],
      lists: [],
      loading: false,
      trade_status: "all",
      trader: "any",
      isOpen: false,

      //real states
      activeToggle: "Portfolio",
    };
    this.linkSubscriptions = [];
  }

  async setNewSymbol(symbol: string) {
    this.setState({ symbol });
  }

  handleSegments() {
    switch (this.state.activeToggle) {
      case "Portfolio":
        return <PortfolioOverview />;
      case "Orders":
        return <ViewRetailOrders />;
      case "Trades":
        return <ViewRetailTrades />;
    }
  }

  render() {
    return (
      <div className="window KM_retailTrading" style={{
        gridTemplateRows: this.state.activeToggle === 'Portfolio' ? '26px 45px calc(100% - 71px)' : '26px calc(100% - 26px)',
      }}>
        <div className="toggleButtonsRow">
          <SegmentedControl segments={segments} onSegmentChange={(item) => this.setState({ activeToggle: item as "Portfolio" | "Orders" | "Trades" })} />
        </div>

        {this.handleSegments()}
      </div>
    );
  }
}


const ViewRetailOrders: React.FC = () => {
  //subscribe to orders //
  const [orders, setOrders] = useState<OrderMessage[]>([]);
  const [tableState, setTableState] = useState<MUITableState | undefined>(undefined);

  useEffect(() => {
    const subscription = getRetailHandler("view_orders").subscribe((orders) => {
      setOrders(orders);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []);
  return <ViewOrderMUITable
    modifyRow={true}
    rows={orders.sort((a, b) => {
      return new Date(b.last_updated).getTime() - new Date(a.last_updated).getTime();
    }).map((order, index) => {
      return { ...order };
    })}
    columns={OrderColumnsInfo}
    cell={(column, row) =>
      formatOrderCell(column, row as unknown as OrderMessage)
    }
    tableState={tableState}
    saveState={(state) => setTableState(state)}
    loading={false}
  />
}
const ViewRetailTrades: React.FC = () => {
  //subscribe to trades
  const [trades, setTrades] = useState<TradeMessage[]>([]);
  const [tableState, setTableState] = useState<MUITableState | undefined>(undefined);

  useEffect(() => {
    const subscription = getRetailHandler("view_trades").subscribe((trades) => {
      setTrades(trades);
    });
    return () => {
      subscription.unsubscribe();
    }
  }, []);
  return (
    <ViewOrderMUITable
      rows={trades.map((order, index) => {
        return { ...order, id: order.symbol + index };
      })}
      columns={TradeColumnsInfo}
      cell={(column, row) =>
        formatTradeCell(column, row as unknown as OrderMessage)
      }
      tableState={tableState}
      saveState={(state) => setTableState(state)}
      loading={false}
    />
  );
}

function formatTradeCell(column: string, row: OrderMessage): React.JSX.Element {
  let value = row[column];
  const style = {};
  if (typeof value === 'string') {
    style['justifyContent'] = 'flex-start';
  }
  if (column === "transactionTime") value = formatTimeWithDate(value as string);
  else if (column === "side") return <TableBar value={value} />;
  else if (column === "order_id") {
    style['justifyContent'] = 'flex-start';
    return <div className="center-container fill" style={style}>{value}</div>;
  }
  else if (typeof value === 'number') {
    style['justifyContent'] = 'flex-end';
    value = formatNumber(value);
  }
  return (
    <div className="center-container fill" style={style}>
      {value}
    </div>
  );
}

function formatOrderCell(column: string, row: OrderMessage): JSX.Element {
  var value = row[column];
  const style = {};
  if (typeof value === 'string') {
    style['justifyContent'] = 'flex-start';
  }
  if (column === "created_at") value = formatTimeWithDate(value as string);
  else if (column === "last_updated") value = formatTimeWithDate(value as string);
  else if (column === "side") return <TableBar value={value} />;
  else if (column === "time_in_force" && value === "good_till_cancel") {
    style['justifyContent'] = 'flex-start';
    value = "GTC";
  }
  else if (typeof value === 'number') {
    style['justifyContent'] = 'flex-end';
    value = formatNumber(value);
  }
  return (
    <div className="center-container fill" style={style}>
      {value}
    </div>
  );
}