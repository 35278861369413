import { SymbolMessageHandler } from "./AbstractHandler";
import { MBOOrder, MessageType, SymbolMessageType } from "../../../Types/Websocket";
import { Environment } from "../../../Config/Environment";

type MarketByOrderMessage = {
    orders: {
        yield?: number,
        dirty?: number,
        participant?: string,
        duration?: number,
        vbp?: number,

        order_class: "round_lot";
        order_key: number;
        price: number;
        side: "ask" | "bid";
        time: string;//"14:21:35:812"
        update_code: "insert" | "deleted" | 'update';
        volume: number;
    }[];
    symbol: string;
    initial: boolean;
}

type SymbolMarketByOrderInfo = {
    ask: MBOOrder[];
    bid: MBOOrder[];
}

export class MBOHandler extends SymbolMessageHandler<MarketByOrderMessage, SymbolMarketByOrderInfo> {
    messageType: SymbolMessageType = MessageType.market_by_order;

    initialValue(symbol: string): SymbolMarketByOrderInfo {
        return { ask: [], bid: [] };
    }

    messageSymbol(message: MarketByOrderMessage): string {
        return message.symbol
    }

    handleSymbolsMessage(symbol: string, message: MarketByOrderMessage): void {
        if (message.initial) this.symbolHandlerDetails[symbol].symbolInfo = this.initialValue(symbol);
        const symbolInfo = this.symbolHandlerDetails[symbol].symbolInfo;

        message.orders.forEach(orderUpdate => {
            const side = orderUpdate.side;
            const orders: MBOOrder[] = symbolInfo[side];
            if (orderUpdate.update_code === 'deleted') {
                const index = orders.findIndex(order => order.order_key === orderUpdate.order_key);
                if (index !== -1) orders.splice(index, 1);
            } else if (orderUpdate.update_code === 'insert') {
                let i = 0; while (i < orders.length && orderUpdate.price < orders[i].price) i++;

                const order: MBOOrder = {
                    volume: orderUpdate.volume,
                    price: orderUpdate.price,
                    order_key: orderUpdate.order_key,

                    participant: orderUpdate.participant,
                    dirty: orderUpdate.dirty,
                    yield: orderUpdate.yield,
                    time: orderUpdate.time
                };
                orders.splice(i, 0, order);
            } else if (orderUpdate.update_code === 'update') {
                const index = orders.findIndex(order => order.order_key === orderUpdate.order_key);
                if (index !== -1) Object.keys(orderUpdate).forEach(column => orders[index][column] = orderUpdate[column]);
            } else {
                if (Environment === 'development') console.error('Unkown order by market type:', orderUpdate.update_code);
            }
        });
        this.pushUpdate(symbol);
    }
}