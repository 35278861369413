import { ColumnInfo } from "../../../Types/MUITable";


export const TradeTickerColumnsInfo: ColumnInfo[] = [
    { name: "symbol", defaultHidden: false, dataType: 'string', alignment: 'left' },
    { name: "datetime_exec", defaultHidden: false, dataType: 'string', alignment: 'left' },
    { name: "time_executed", defaultHidden: true, dataType: 'string', alignment: 'left' },
    { name: "buyer", defaultHidden: false, dataType: 'string', alignment: 'center' },
    { name: "aggressive_party", defaultHidden: true, dataType: 'string', alignment: 'center' },
    { name: "seller", defaultHidden: false, dataType: 'string', alignment: 'center' },
    { name: "price", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "yield", defaultHidden: true, dataType: 'number', alignment: 'center' },
    { name: "price_dirty", defaultHidden: true, dataType: 'number', alignment: 'right' },
    { name: "duration", defaultHidden: true, dataType: 'string', alignment: 'right' },
    { name: "price_per_point", defaultHidden: true, dataType: 'string', alignment: 'right' },
    { name: "volume", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "value", defaultHidden: false, dataType: 'number', alignment: 'right' },
    { name: "standard", defaultHidden: true, dataType: 'string', alignment: 'left' },
    { name: "trade_cancellation", defaultHidden: false, dataType: 'boolean', alignment: 'center' },
    { name: "trade_cancellation_time", defaultHidden: false, dataType: 'string', alignment: 'left' },
    { name: "trade_updates_last_paid", defaultHidden: true, alignment: 'center' },
    { name: "trade_updates_high_low", defaultHidden: true, alignment: 'center' },
    { name: "trade_updates_average", defaultHidden: true, alignment: 'center' },
    { name: "trade_updates_turnover", defaultHidden: true, alignment: 'center' },
    { name: "is_latest_trade", defaultHidden: true, alignment: 'center' },
    { name: "outside_spread", defaultHidden: true, alignment: 'center' },
    { name: "settlement_date", defaultHidden: true, alignment: 'center' },
    { name: "timestamp_agreement", defaultHidden: true, alignment: 'center' },
    { name: "timestamp_dissemination", defaultHidden: true, alignment: 'center' },
    { name: "trade_id", defaultHidden: false, dataType: 'number', alignment: 'left' },
    { name: "trade_number", defaultHidden: true, alignment: 'right' },
    { name: "class", defaultHidden: true, alignment: 'left' },
    { name: "trade_type_name", defaultHidden: true, alignment: 'left' },
    { name: "diff_last_price", defaultHidden: true, alignment: 'right' }
];