import { BehaviorSubject } from "rxjs";
import { filter, first } from "rxjs/operators";
import Bugsnag from "@bugsnag/js";
import {
  AuthenticationMessage,
  MessageType,
  OmsMessage,
  StreamerError,
  SubscriptionCommands,
  UnAuthorizedMessage,
  WSStatus,
} from "../../Types/Websocket";

import {
  PDSWebSocketConsoleLogging,
  WebSocketSubscriptionLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import { hideDefaultModal, openDefaultModal } from "../../Components/Main/Main";
import { MultipleSessionsModal } from "../../Components/Modals/MultipleSessionsModal";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";
import { LMDInterface } from "../LMD";
import { ViewOrdersHandler } from "./Handlers/ViewOrdersHandler";
import { ViewTradesHandler } from "./Handlers/ViewTradesHandler";

const WEBSOCKET_AUTHENTICATION_TIMEOUT = 10000;
class WaitableVariable<T> {
  behaviorSubject: BehaviorSubject<T | null>;
  constructor() {
    this.behaviorSubject = new BehaviorSubject(null as T | null);
  }

  // Only returns non null values
  waitForValue(): Promise<T> {
    return new Promise((resolve) =>
      this.behaviorSubject
        .pipe(
          filter((value) => value !== null),
          first()
        )
        .subscribe((value) => resolve(value as T))
    );
  }

  value(): T | null {
    return this.behaviorSubject.value;
  }

  set(value: T) {
    this.behaviorSubject.next(value);
  }

  clear() {
    this.behaviorSubject.next(null);
  }
}

export type ManageSubscriptionMessage = {
  type: MessageType;
  command: SubscriptionCommands;
  message?: any;
};
export type RetailManagerHandlers = {
  view_orders: ViewOrdersHandler;
  view_trades: ViewTradesHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class RetailWebsocketManager {
  //Current socket
  public currentSocket: MFSocket | null;
  //available sockets
  // public availableSockets: WebsocketConnectionData[];

  // Handlers for different types of messages.
  private handlers: RetailManagerHandlers;

  public lastHeartBeat: BehaviorSubject<Date> = new BehaviorSubject(new Date());

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof RetailManagerHandlers>(
    type: T
  ): RetailManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {

    // this.availableSockets = [];
    this.currentSocket = null;

    this.handlers = {
      view_orders: new ViewOrdersHandler(),
      view_trades: new ViewTradesHandler(),
    };
  }
  public connectToSocket(socketUrl: string, token: string, name: string, callback: (e) => void) {
    //if there exists  a socket, close it
    // If there's an existing socket, clean it up before creating a new one
    if (this.currentSocket) {
      this.currentSocket.status.complete(); // Unsubscribe from the status
      this.currentSocket.closeSocket(); // Close the socket
      this.currentSocket = null; // Set the current socket to null

    }
    // Create a new socket
    const newSocket = new MFSocket(socketUrl, name ?? '', token, this.handlers, this.getHandler.bind(this), this.lastHeartBeat);
    this.currentSocket = newSocket;

    // Subscribe to the status of the socket
    newSocket.status.subscribe(async (status) => {

      //success hide the modal, remove the component from the root and add it to the root also fetch the customer info
      if (status === 'connected') {
        AppStateManager.hasAccessToRetail.next(true);
        callback("connected");
        //fetch customer info
        const customerinfo = await LMDInterface.getRetailCustomer(new URL(socketUrl).host);
        if ('customer_id' in customerinfo) {
          AppStateManager.customerInfo = customerinfo;
        } else {
          console.error('Failed to fetch customer info:', customerinfo);
        }
        // AppStateManager.hasAccessToRetail.next(true);
        // callback("connected");
      }
      //if closed, show the modal and show a toast
      else if (status === 'closed') {
        callback('closed');
      }
    });
  }

  private async send(message: ManageSubscriptionMessage) {
    // Wait to send the connection untill the WSManager is connected.
    if (this.currentSocket) {
      this.currentSocket.status
        .pipe(
          filter((status) => status === "connected"),
          first()
        )
        .subscribe(async (connectedStatus) => {
          if (WebSocketSubscriptionLogging)
            console.log("Sending over websocket:");
          if (WebSocketSubscriptionLogging) console.log(message);
          //@ts-ignore
          const socket = await this.currentSocket.socket.waitForValue();
          socket.send(JSON.stringify(message));
        });
    }
  }
  async manageTimeSubscription(command: SubscriptionCommands) {
    return this.send({ type: MessageType.time, command });
  }
}

export class MFSocket {
  public socket: WaitableVariable<WebSocket>;
  public url: string;
  public name: string | undefined;
  public HB: BehaviorSubject<Date>;
  private multiple_session_detected: boolean;

  status: BehaviorSubject<WSStatus>;
  error: string | null;
  handlers: RetailManagerHandlers;
  getHandler: (type: keyof RetailManagerHandlers) => RetailManagerHandlers[keyof RetailManagerHandlers];
  private token: string | null;
  public retryTimer: {
    active: boolean,
    intervalSeconds: number,
    timer: number | null,
    networkLost: boolean,
  }
  constructor(url: string, name: string, token: string | null, handlers: RetailManagerHandlers, getHandler: (type: keyof RetailManagerHandlers) => RetailManagerHandlers[keyof RetailManagerHandlers], lastHeartBeat: BehaviorSubject<Date>) {
    this.url = url;
    this.token = token;
    this.socket = new WaitableVariable<WebSocket>();
    // this.createSocket(url, token);
    this.status = new BehaviorSubject<WSStatus>("disconnected");
    this.error = null;
    this.handlers = handlers;
    this.getHandler = getHandler;
    this.name = name;
    this.HB = lastHeartBeat;
    this.retryTimer = {
      active: true,
      intervalSeconds: 0,
      timer: null,
      networkLost: false,
    }
    this.multiple_session_detected = false;
    this.initializeSocket();
  }
  private toastErrorHandler(status: string) {
    const active = toast.isActive(this.name ?? 1);
    //status closed 
    if (status === 'closed') {
      if (!active) {
        //create new toast if not active
        toast.error(<span>{`Streamer ${this.name} has been closed`}</span>,
          {
            toastId: this.name,
            closeButton: true,
            autoClose: 2000,
            closeOnClick: false,
            icon: ICONS.error
          })
      } else {
        //update existing toast
        toast.update(this.name as string, {
          render: <span>{`Streamer ${this.name} has been closed`}</span>,
          toastId: this.name,
          closeButton: true,
          autoClose: 2000,
          closeOnClick: false,
          type: "error",
          icon: ICONS.error

        })
      }
    }
    //Connected status
    else if (status === 'connected') {
      if (active) {
        toast.update(this.name as string, {
          render: `Connected to ${this.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          toastId: this.name,
          type: "success",
        })
        // toast.dismiss(this.name as string);
      }
      else {
        toast.success(`Connected to ${this.name}`, {
          toastId: this.name,
          autoClose: 2000,
          icon: ICONS.success,
          type: "success",
          hideProgressBar: true,
        });
      }
    }
    //Offline status
    else if (status === 'offline') {
      toast.error(`Server is offline for ${this.name}`, {
        toastId: this.name,
      });
    }
  }
  private initializeSocket() {
    //subscribe to the status of the socket
    this.status.subscribe((status) => {
      this.toastErrorHandler(status);

      if (WebSocketSubscriptionLogging) console.log("Socket status changed:", this.name, status);
      if (status === 'disconnected') {
        if (!this.retryTimer.active || this.retryTimer.timer) return;
        if (this.retryTimer.intervalSeconds > 60) {
          this.status.next('closed');
        }
        this.retryTimer.timer = window.setTimeout(() => {
          this.retryTimer.intervalSeconds += 5;
          this.retryTimer.active = true;
          this.retryTimer.timer = null;
          //clear socket
          this.socket.clear();
          this.socket.set(this.createSocket());
        }, this.retryTimer.intervalSeconds * 1000);
      } else if (status === 'connected' || status === 'connecting') {
        this.retryTimer.timer = null;
        this.retryTimer.networkLost = false;
      }
    });
  }
  private openSocket(socket: WebSocket | null) {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        type: 'authentication',
        command: "logon",
        message: {
          token: this.token,
          token_type: "Bearer",
          expires_in: 86400,
        },
      })
    );
  }

  public createSocket(): WebSocket {
    this.multiple_session_detected = false;
    this.status.next("connecting");
    const socket = new WebSocket(this.url);

    socket.addEventListener("open", (event) => this.openSocket(socket));

    socket.addEventListener('close', () => {
      this.closeSocket();
    });
    socket.addEventListener('error', this.handleError.bind(this));
    socket.addEventListener('message', (event) => { this.handleMessage(JSON.parse(event.data)) });

    window.setTimeout(() => {
      if (this.status.value === 'connecting') {
        this.retryTimer.active = false;
        this.status.next("disconnected");
        this.error = "Websocket connection timeout";
        //TO DO DISPLAY THIS ERROR!!!
      }
    }, WEBSOCKET_AUTHENTICATION_TIMEOUT);

    return socket;
  }
  private handleErrorMessages(error: { reason: string, status: string }) {
    if (error.reason === StreamerError.MarketFeedNotOnline || error.status === "offline")
      this.closeSocket();
    else if (error.reason === StreamerError.InvalidSymbol) { }
    // do nothing
    else if (error.reason === StreamerError.Unauthorized) { }
    // do nothing
    else if (error.reason === StreamerError.AdminClosed) { }
    // do nothing
    else {
      // this.disconnect();
    }
  }

  private handleMessage(messageEvent: {
    message: AuthenticationMessage | OmsMessage[] | OmsMessage | any;
    type: MessageType | "status" | "authentication" | "oms" | "error";
    command: "order" | "trade";
  }) {
    // this.lastHeartBeat.next(new Date());
    const broker = this.name;
    const { message, type } = messageEvent;

    if (PDSWebSocketConsoleLogging) console.log("Message from server:", type, message);
    if (type === "error") {
      this.handleErrorMessages(message);
    }
    else if (type === "status") {
      const { status } = message as { status: any };
      this.handleStatus(status);
    } else if (type === "authentication") {
      this.handleAuthentication(message);

      // Check if message is an array
    } else if (Array.isArray(messageEvent)) {
      //if it's array than check if it' has type OMS
      if (messageEvent[0].type === "oms") {
        messageEvent.forEach((message: OmsMessage) => {
          if (message.command === "order") {
            this.getHandler("view_orders").handleMessage(
              message.message.map(item => ({ ...item, broker }))
            );
          }
          else if (message.command === "trade") {
            this.getHandler("view_trades").handleMessage(
              message.message.map(item => ({ ...item, broker }))
            );
          }
          console.log('message', message)

        });
      }
    }
    //check if it's an object of type OmsMessage
    else if (messageEvent.type === "oms") {
      console.log('messageEvent', messageEvent)
      if (messageEvent.command === "order") {
        this.getHandler("view_orders").handleMessage(
          [{ ...messageEvent.message, broker }]
        );
      } else if (messageEvent.command === "trade") {
        this.getHandler("view_trades").handleMessage([{ ...message, broker }]);
      }
    } else {
      console.error("Unhandled message from server:");
      console.log({ type });
      console.log(message);
      //TO:DO
      // SHOW ERROR MESSAGE AS TOAST FOR USER 
    }
  }

  public multipleSessions(): void {
    this.multiple_session_detected = true;
    const socket = this.socket.value()
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = false;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      this.status.next('multiple_session');
      openDefaultModal(
        <MultipleSessionsModal reconnect={() => {
          this.createSocket();
          hideDefaultModal();
        }} />
      );
    }
  }
  public closeSocket(): void {
    const socket = this.socket.value()
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = true;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      //to prevent the manager from trying to create another socket when the socket gets marked as closed
      //since the user will have that option to reconnect
      !this.multiple_session_detected && this.status.next('closed');
    }
  }
  private handleAuthentication(message: AuthenticationMessage | UnAuthorizedMessage) {
    if (message.authenticated) {
      this.retryTimer.intervalSeconds = 0;
      this.error = null;
      this.status.next("connected");
    } else {
      const unAuthMessage = message as UnAuthorizedMessage
      const unAuthMsg = unAuthMessage.text ?? unAuthMessage.reason
      if (unAuthMsg === StreamerError.MultipleSessions) {
        //DISPLAY ALERT FOR USER TO EITHER LOG OUT OR CLOSE OTHER SESSIONS
        //RECCONNECT WITH THE SAME TOKEN
        this.multipleSessions();
      } else if (unAuthMsg === StreamerError.TokenExpired || unAuthMsg === StreamerError.PDSTokenExpired) {
        //FORCE FETCH NEW LOGIN STATE
        // LOG THE USER OUT
        console.log('Token expired')
        AppStateManager.logout();
      } else if (unAuthMsg === StreamerError.OMSTimeout
        || unAuthMsg === StreamerError.OMSUnavailable
        || unAuthMsg === StreamerError.OMSUserNotFound
        || unAuthMsg === StreamerError.OMSOtherError) {
        Bugsnag.notify(new Error(unAuthMsg));
      } else if (unAuthMsg === StreamerError.SystemNotAllowed) {
        this.closeSocket();
      }
      this.status.next("closed");
    }
  }

  private handleStatus(status: "offline" | "online" | "catchup") {
    if (status === "online") {
      this.error = null;
      this.status.next("connected");
    } else if (status === "offline") {
      this.error = "Server is offline";
      // this.closeSocket();
      this.status.next("closed");
    } else if (status === "catchup") {
      this.error = "Server is in catchup";
      this.status.next("connecting");
    }
  }

  private handleError(event) {
    // IDK what to do with this information yet...
    console.error("Error on socket:");
    console.error(event);
    //this.error = JSON.stringify(event);
  }

}