import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppStateManager } from "../../../StateManager";
import { useEffect, useMemo, useState } from "react";
import { ColumnInfo } from "../../../Types/MUITable";
import { EndOfDayPrice, isPortfolioOverviewSuccess, Position, RetailCustomerError } from "../../../Types/RetailTradingTypes";
import { MUITable } from "../../Tables/MUIProTable";
import { LMDInterface } from "../../../KodiInterface/LMD";
import { positionColumns } from "./PortfolioColumns";
import Dropdown from "../../UI-Elements/DropDownWrapper";
import { format, subWeeks, subMonths, subYears, startOfYear } from 'date-fns';
import { RetailTradingChart } from "../Chart/RetailTradingChart";
import SegmentedControl from "./SegmentedControl";
import Alert from "../../UI-Elements/Alert";


const PortfolioOverview: React.FC = () => {
    const customerInfo = AppStateManager.customerInfo;
    const options = customerInfo?.portfolios?.map(portfolio => {
        return { value: portfolio.id.toString(), title: portfolio.name }
    })
    const [timeFrame, setTimeFrame] = useState<string>("YTD");
    const [chartData, setChartData] = useState<EndOfDayPrice[]>();
    const [positions, setPositions] = useState<Position[] | undefined | Error>();
    const [selectedPortfolio, setSelectedPortfolio] = useState<string | undefined>(options?.[0]?.value);
    const displayCustomerInfo = () => {
        if (!customerInfo) return <div>NO DATA...</div>
        return <span>{customerInfo?.name} - {customerInfo?.ssn}</span>
    };
    const AccountColumns: ColumnInfo[] = [
        { name: "currency", defaultHidden: false, flex: 1, dataType: 'string' },
        { name: "name", defaultHidden: false, flex: 1, dataType: 'string' },
        { name: "number", defaultHidden: false, flex: 1, dataType: 'string' },
        { name: "is_default", defaultHidden: false, flex: 1, dataType: 'boolean' },
        { name: "id", defaultHidden: true, flex: 1, dataType: 'number' },
    ];
    const accounts = useMemo(() => {
        return customerInfo?.portfolios.find(portfolio => portfolio.id.toString() === selectedPortfolio)?.accounts;
    }, [customerInfo, selectedPortfolio]);
    const displayAccounts = () => {

        return <div>
            <span><strong>Accounts</strong></span>
            {
                ((accounts ?? []).length < 1)
                    ? <div>No accounts</div>
                    : <MUITable columns={AccountColumns} rows={accounts?.map(account => { return { ...account, id: account.id.toString() } }) || []} cell={(column, row) => formatPortfolioCell(column, row)} tableState={undefined} saveState={() => undefined} />
            }
        </div>
    }

    useEffect(() => {
        const fetchPortfolioOverview = async () => {
            if (!selectedPortfolio) return;
            let from_date: string | undefined = '';
            const today = new Date();
            switch (timeFrame) {
                case "week":
                    from_date = format(subWeeks(today, 1), 'yyyy-MM-dd');
                    break;
                case "1_month":
                    from_date = format(subMonths(today, 1), 'yyyy-MM-dd');
                    break;
                case "6_months":
                    from_date = format(subMonths(today, 6), 'yyyy-MM-dd');
                    break;
                case "1 ár":
                    from_date = format(subYears(today, 1), 'yyyy-MM-dd');
                    break;
                case "2024":
                    from_date = format(startOfYear(today), 'yyyy-MM-dd');
                    break;
                case "all":
                    from_date = undefined;
                    break;
                default:
                    from_date = '';
            }
            try {
                const response = await LMDInterface.getRetailPortfolioOverview(selectedPortfolio, from_date);
                if (isPortfolioOverviewSuccess(response)) {
                    setPositions(response.positions);
                    setChartData(response.end_of_day_prices);
                }
            } catch (e: unknown) {
                let errorObject: RetailCustomerError;
                try {
                    const errorMessage = (e as any).message || '{"input":"","reason":"Unknown reason","text":"Unknown error"}';
                    errorObject = JSON.parse(errorMessage);
                } catch (parseError) {
                    errorObject = {
                        input: (e as any).input || '',
                        reason: (e as any).reason || (e as any).message || 'Unknown reason',
                        text: (e as any).text || 'Unknown error'
                    };
                }
                setPositions(new Error(errorObject.reason));
                setChartData(undefined);
            }
        };
        fetchPortfolioOverview();
    }, [selectedPortfolio, timeFrame]);
    const segments = [
        { label: "Vika", value: "week" },
        { label: "1 mán", value: "1_month" },
        { label: "6 mán", value: "6_months" },
        { label: "1 ár", value: "1_year" },
        { label: "2024", value: "2024" },
        { label: "Allt", value: "all" },
    ];
    const displayPositions = () => {
        const positionBody = () => {
            if (positions === undefined) {
                return <div>Loading...</div>;
            } else if (positions instanceof Error) {
                return <Alert type="error" headText={positions.message} />;
            } else if (positions.length === 0) {
                return <div>No positions</div>;
            } else {
                return <MUITable
                    columns={positionColumns}
                    rows={positions.map(position => { return { ...position, id: JSON.stringify(position) } })}
                    cell={(column, row) => formatPortfolioCell(column, row)}
                    tableState={undefined}
                    saveState={() => undefined} />;
            }
        }
        return <>
            <div className="retail-section">
                <span ><strong>Positions</strong></span>
                <div className="timeFrameButtons-wrapper">
                </div>
            </div>
            <div>
                {positionBody()}

            </div>
        </>
    }
    const displayChart = () => {
        if (chartData === undefined) return
        return <div className="fill" style={{ maxHeight: '150px' }}>
            <RetailTradingChart data={chartData} />
        </div>
    }
    return <>
        <div className="retail-section" style={{
            gap: '8px',
            padding: '8px',
            fontWeight: 'bold',
            marginBottom: '8px',
            height: '40px'
        }}>
            {displayCustomerInfo()}
            {
                (options) && <Dropdown onChange={setSelectedPortfolio} width="150px" menuItems={options} />
            }
        </div>
        <div className="fill" style={{ overflow: 'hidden' }}>
            {!(positions instanceof Error) && <div className="segmentedControl-wrapper">
                <SegmentedControl segments={segments} onSegmentChange={setTimeFrame} />
            </div>
            }
            {displayChart()}
            <div>
                {displayAccounts()}
                {displayPositions()}
            </div>
        </div>

    </>
}

export default PortfolioOverview;

function formatPortfolioCell(column, row: any): JSX.Element {
    const value = row[column];
    const centerAlign = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    };
    if (column === "is_default") {
        return <div style={centerAlign} className="fill">
            <FontAwesomeIcon icon={value ? faCheck : faTimes} />
        </div>
    }

    return (
        <div style={centerAlign} className="fill">
            {value}
        </div>
    );
}