// Package imports:
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bigFooter">
      <div className="shell">
        <div className="footer__inner">
          <div className="footer-logo">
            <a href="/" aria-label="Keldan - forsíða">
              <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.2559 0.00842285H3C1.34315 0.00842285 0 1.35157 0 3.00842V17.2643L17.2559 0.00842285Z" fill="white" />
                <path d="M3 32.7216L17.0884 32.7216L5.57791 21.369L-2.56309e-07 26.8579L-1.31135e-07 29.7216C-5.8711e-08 31.3784 1.34315 32.7216 3 32.7216Z" fill="white" />
                <path d="M32.7148 29.7213L32.7148 3.00003C32.7148 1.34317 31.3717 2.68203e-05 29.7148 2.69651e-05L26.9617 2.72058e-05L10.4702 16.5079L26.7007 32.7213L29.7148 32.7213C31.3717 32.7213 32.7148 31.3782 32.7148 29.7213Z" fill="white" />
              </svg>
            </a>
          </div>

          <div className="footer-cols">
            <div className="footer-col footer-col--1of4 footer-col__address">
              <p>Borgartún 25, 105 Reykjavík</p>
              <p>Sími: 510 1050</p>
              <p>Netfang: <a href="mailto:info@keldan.is">info@keldan.is</a></p>
            </div>
            <div className="footer-col">
              <ul>
                <li>
                  <a href="https://keldan.is/Keldan-Markadir/Skilmalar">Skilmálar</a>
                </li>
                <li>
                  <a href="https://keldan.is/Hafa-Samband/keldan-markadir">Hafðu samband</a>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <ul>
                <li>
                  <a href="https://keldan.is/Keldan-Markadir">Áskriftarleiðir</a>
                </li>
              </ul>
            </div>
            <div className="footer-col social-media">
              <div className="link">
                <a
                  href="https://play.google.com/store/apps/details?id=is.kodi.keldanMobile"
                  target="_blank"
                  rel='noreferrer'
                  aria-label="Keldu appið á Google Play Store"
                >
                  <img
                    src='https://cdn.livemarketdata.com/Images/google-play.png'
                    alt="Google play store logo"
                    width="132"
                    height="39"
                  />
                </a>
              </div>
              <div className="link">
                <a
                  href="https://apps.apple.com/is/app/keldan-app/id1162095731"
                  target="_blank"
                  rel='noreferrer'
                  aria-label="Keldu appið á Apple App Store"
                >
                  <img
                    src='https://cdn.livemarketdata.com/Images/app-store.png'
                    alt="Apple app store logo"
                    width="132"
                    height="44"
                  />
                </a>
              </div>
            </div>

          </div>
        </div>
        <div className="footer__bottom">
          <p>Keldan ehf.  |  Kt. 491009-0200   |   VSK númer: 103750  |  Allur réttur áskilinn © {new Date().getFullYear()}</p>
          <a href="https://www.facebook.com/Keldan.ehf">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.625 0.75H1.875C0.820312 0.75 0 1.60938 0 2.625V16.375C0 17.4297 0.820312 18.25 1.875 18.25H7.22656V12.3125H4.76562V9.5H7.22656V7.39062C7.22656 4.96875 8.67188 3.60156 10.8594 3.60156C11.9531 3.60156 13.0469 3.79688 13.0469 3.79688V6.17969H11.8359C10.625 6.17969 10.2344 6.92188 10.2344 7.70312V9.5H12.9297L12.5 12.3125H10.2344V18.25H15.625C16.6406 18.25 17.5 17.4297 17.5 16.375V2.625C17.5 1.60938 16.6406 0.75 15.625 0.75Z" fill="currentColor" />
            </svg>
            Keldan á Facebook
          </a>
        </div>
      </div>
    </footer>
  )
}

export default Footer;