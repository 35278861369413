export enum MessageType {
  trade = "trade",
  price_info = "price_info",
  first_level = "first_level",
  second_level = "second_level",
  third_level = "third_level",
  market_by_level = "market_by_level",
  orderbook_state = "orderbook_state",
  market_state = "market_state",
  market_by_order = "market_by_order",
  index = "index",
  time = "time",
  sample = "sample",
  net_order_imbalance_indicator = "net_order_imbalance_indicator",
  // news = 'news',
  currency = "currency",
  //trading
  view_orders = "view_orders",
  view_trades = "view_trades",
}
export enum SubscriptionCommands {
  Subscribe = "subscribe",
  Unsubscribe = "unsubscribe",
}

export type PriceInfo = {
  ask_orders: number;
  ask_price: number;
  ask_price_diff: number;
  ask_price_diff_percent: number;
  ask_quantity: number;
  ask_time_exec: string;
  bid_orders: number;
  bid_price: number;
  bid_price_diff: number;
  bid_price_diff_percent: number;
  bid_quantity: number;
  bid_time_exec: string;
  currency_converted_closing_price: string[];
  currency_converted_market_cap: string[];
  datetimestamp: string;
  high_price_year: number;
  high_price_year_date: string;
  id_code: string;
  initial: boolean;
  intraday_accumulated_turnover: number;
  intraday_accumulated_volume: number;
  intraday_average_price: number;
  intraday_first_price: number;
  intraday_high_price: number;
  intraday_low_price: number;
  intraday_mid_price: number;
  intraday_mid_yield: null;
  intraday_net_change: number;
  intraday_no_of_trades: number;
  intraday_reported_turnover: number;
  intraday_reported_volume: number;
  intraday_vwap: number;
  is_official_closing_price: boolean;
  is_official_closing_turnover: boolean;
  last_bid_yield_diff_closing_basispoints: null;
  last_bid_yield_diff_closing_nominal: null;
  last_bid_yield_diff_closing_percent: null;
  last_dirty: null;
  last_duration: null;
  last_paid_date: string;
  last_paid_time: string;
  last_price: number;
  last_price_diff_closing_nominal: number;
  last_price_diff_closing_percent: number;
  last_price_per_point: null;
  last_trade_date: string;
  last_trade_time: string;
  last_volume: number;
  last_yield: null;
  low_price_year: number;
  low_price_year_date: string;
  market_capital: number;
  month_high_price: number;
  month_high_price_date: string;
  month_low_price: number;
  month_low_price_date: string;
  note_codes: [];
  notifications: [];
  official_currency_rates: boolean;
  orderbook_state: string;
  previous_business_day: string;
  previous_closing_price: number;
  previous_first_level_ask: number;
  previous_first_level_bid: number;
  stop_codes: [];
  symbol: string;
  timestamp: string;

  // Bonds
  ask_dirty: number;
  ask_duration: number;

  ask_price_per_point: number;

  ask_yield: number;
  bid_dirty: number;
  bid_duration: number;

  bid_price_per_point: number;

  bid_yield: number;

  high_yield_year: number;

  last_yield_diff_closing_basispoints: null;
  last_yield_diff_closing_nominal: null;
  last_yield_diff_closing_percent: null;

  low_yield_year: number;

  month_high_yield: number;

  month_low_yield: number;

  previous_closing_dirty: number;
  previous_closing_duration: number;

  previous_closing_price_per_point: number;
  previous_closing_yield: number;

  previous_first_level_ask_yield: number;

  previous_first_level_bid_yield: number;
};
export interface News {
  symbol: string;
  start: number;
  limit: number;
  totalCount: number;
  feedItems: (FeedItemsEntity)[];
}
export interface FeedItemsEntity {
  paywall: boolean;
  fullSource: string;
  link: string;
  id: string;
  source: string;
  title: string;
  body: string;
  publish_date: string;
  publish_timestamp: number;
}

export type Trade = {
  aggressive_party?: string;
  class: string;
  date: string;
  datetime_exec: string;
  diff_last_price: number;
  is_latest_trade: boolean;
  lmd_number: number;
  mmt: string;
  nano_second_fraction: number;
  outside_spread: boolean;
  price: number;
  symbol: string;
  time_executed: Date;
  timestamp_dissemination: string;
  to_be_cleared: boolean;
  top_of_book_ask: number;
  top_of_book_bid: number;
  trade_cancellation: boolean;
  trade_id: string;
  trade_nr: number;
  trade_number: number;
  trade_type_name: string;
  trade_updates_average: boolean;
  trade_updates_high_low: boolean;
  trade_updates_last_paid: boolean;
  trade_updates_turnover: boolean;
  trade_venue: string;
  value: number;
  volume: number;
};

export type WSStatus = "connected" | "connecting" | "disconnected" | "closed" | "multiple_session";


export type MarketDepthType = 'market_by_level' | 'market_by_order';

export type SymbolMessageType =
  | MessageType.market_by_level
  | MessageType.trade
  | MessageType.price_info
  | MessageType.market_by_order;
export type TradingSymbolMessageType = MessageType.view_orders | MessageType.view_trades;

export type MBOOrder = {
  price: number;
  volume: number;
  order_key: number;
  // Exclusive bond info?
  participant?: string;
  dirty?: number;
  yield?: number;
  time?: string;
};

export type OrderbookState =
  "closed" |
  "not_available" |
  "pre_trading" |
  "trading" |
  "terminating" |
  "post_trading" |
  "call_interaction" |
  "pre_close" |
  "extended_trading" |
  "mid_price_auction" |
  "opening_auction" |
  "closing_auction" |
  "turnover" |
  "end_of_trading" |
  "statistics" |
  "orders_are_cleared" |
  "scheduled_intraday_auction" |
  "suspended_or_halted" |
  "auction_period" |
  "pre_open" |
  "trading_pause" |
  "trading_at_closing_price" |
  "sold_out_buy_back";


export type Sources = {
  payWall: boolean;
  title: string;
  category: string;
  fullSource: string;
};

//PDS

export interface AuthenticationMessage {
  authenticated: boolean;
  system: string;
  token_expires_at: string;
  token_expires_in: number;
}
export interface UnAuthorizedMessage {
  reason: string;
  text?: string;
  authenticated?: boolean;
}

export interface OrderMessage {
  average_price: number;
  created_at: string;
  currency: string;
  filled_quantity: number;
  id: string;
  isin: string;
  last_price: number;
  last_updated: string;
  last_updated_by: string;
  order_type: string;
  price: number;
  quantity: number;
  remaining_quantity: number;
  secondary_order_id: string;
  side: string;
  status: string;
  symbol: string;
  time_in_force: string;
  visible_quantity: number;

  // this gets added by the client
  broker?: string;
}

export interface TradeMessage {
  allocated: number;
  cancelled: boolean;
  counterparty: string;
  currency: string;
  customer_id: null | string;
  customer_name: null | string;
  exchange_name: string;
  executed_price: number;
  executed_quantity: number;
  execution_id: string;
  instrument_long_name: string;
  isin: string;
  order_class: string;
  order_id: number;
  side: string;
  submarket_name: string;
  symbol: string;
  trade_id: string;
  trade_type: string;
  trader_username: string;
  transactionTime: string;
}
export interface OmsMessage {
  type: string;
  command: string;
  message: OrderMessage[] | TradeMessage[];
}

export type FatFingerCheck_message =
  | "ask_lower_than_last"
  | "ask_lower_than_last_warning"
  | "bid_higher_than_last"
  | "bid_higher_than_last_warning"
  | "ask_lower_than_best_bid"
  | "ask_lower_than_best_bid_warning"
  | "bid_higher_than_best_ask"
  | "bid_higher_than_best_ask_warning"
  | "ok";

export interface FatFingerCheck {
  symbol: string;
  timestamp: string;
  rounded_price: number;
  ticksize_from: number;
  ticksize_to: number;
  tick: number;
  best_bid: number;
  best_ask: number;
  last: number;
  bp_deviation_from_last: number;
  bp_deviation_from_Ask: number;
  pb_deviation_from_Bid: number;
  percentage_deviation_from_last: number;
  percentage_deviation_from_Ask: number;
  percentage_deviation_from_Bid: number;
  nominal_deviation_from_last: number;
  nominal_deviation_from_Ask: number;
  nominal_deviation_from_Bid: number;
  message: FatFingerCheck_message;
};

export class StreamerError {
  static readonly InvalidSymbol = 'invalid_symbol';
  static readonly NotLoggedOn = 'not_logged_on';
  static readonly Unauthorized = 'unauthorized';
  static readonly MarketFeedNotOnline = 'market_feed_not_online';
  static readonly AdminClosed = 'administratively_closed';
  // Authentication errors
  static readonly InvalidToken = 'invalid_token';
  static readonly MultipleSessions = 'multiple_sessions';
  static readonly TokenExpired = 'token_expired';
  static readonly PDSTokenExpired = 'validate_token - reason : expired_token';
  static readonly OMSTimeout = 'Could not log into OMS - reason : timeout';
  static readonly OMSUnavailable = 'Could not log into OMS - reason : 503 Service Unavailable';
  static readonly OMSUserNotFound = 'get_oms_info from OMS - reason : user not found';
  static readonly OMSOtherError =
    'get_oms_info from OMS - reason : Could not log into OMS - reason Other';
  static readonly SystemNotAllowed = 'validate_token - reason : system_not_allowed';
}