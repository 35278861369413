import { ColumnInfo } from "../../../Types/MUITable";


export const WatchListColumns: ColumnInfo[] = [
    {name: 'symbol', dataType: 'string', alignment: 'left'},
    // Non websocket data
    {name:
        'Name',
        defaultHidden: true,
        dataType: 'string'
    },
    {name: 'orderbook_state', dataType: 'string', alignment: 'left'},
    {name: 'bid_orders',defaultHidden: true, alignment: 'right'},
    {name: 'bid_quantity', alignment: 'right'},
    {name: 'bid_price', alignment: 'right'},
    {name: 'ask_price', alignment: 'right'},
    {name: 'ask_quantity', alignment: 'right'},
    {name: 'ask_orders',defaultHidden: true, alignment: 'right'},
    {name: 'change', dataType: 'actions', defaultHidden: true, alignment: 'center'},
    {name: 'last_price', alignment: 'right'},
    {name: 'last_price_diff_closing_percent', alignment: 'right'},
    {name: 'intraday_no_of_trades', alignment: 'right'},
    {name: 'intraday_first_price', alignment: 'right'},
    {name: 'intraday_high_price', alignment: 'right'},
    {name: 'intraday_low_price', alignment: 'right'},
    {name: 'intraday_vwap', alignment: 'right'},
    {name: 'intraday_average_price', alignment: 'right'},
    {name: 'intraday_accumulated_volume', alignment: 'right'},
    {name: 'intraday_accumulated_turnover', alignment: 'right'},
    {name: 'market_capital', alignment: 'right'},

    // Last price ask and bid
    {name:
        'bid_price_diff_percent', //"Δ Last Bid %"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'bid_price_diff', //"Δ Last Bid"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'ask_price_diff', //"Δ Last Ask"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'ask_price_diff_percent', //"Δ Last Ask %"
        defaultHidden: true,
        alignment: 'right'
    },


    // Bond ask and bid  
    {name:
        'bid_yield', //"B Yield"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'bid_dirty', //"B Dirty"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'bid_duration', //"B Duration"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'ask_duration', //"A Duration"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'ask_dirty', //"A Dirty"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'ask_yield', //"A Yield"
        defaultHidden: true,
        alignment: 'right'
    },



    // Last yield
    {name:
        'last_yield', //"Last Yield"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'last_yield_diff_closing_percent', //"Diff Last Yield"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'last_yield_diff_closing_basispoints', //"Δ Yield BP"
        defaultHidden: true,
        alignment: 'right'
    },

    // Last Duration og Dirty
    {name:
        'last_duration', //"Last Duration"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'last_dirty', //"Last Dirty"
        defaultHidden: true,
        alignment: 'right'
    },


    // Some stuff
    {name:
        'last_price_per_point', //"Last PPP"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'previous_closing_price', //"PCL Price"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'previous_closing_yield', //"PCL Yield"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'previous_closing_duration', //"PCL Duration"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'intraday_reported_volume', //"Reported Vol"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'intraday_reported_turnover', //"Reported Turnover"
        defaultHidden: true,
        alignment: 'right'
    },
    {name:
        'notifications', //"Notifications"
        defaultHidden: true,
        dataType: 'string',
        alignment: 'left'
    },

    // Non websocket data
    {name:
        'ExchangeSymbol',
        defaultHidden: true,
        dataType: 'string',
        alignment: 'left'
    },
    {name:
        'TradingCurrency',
        defaultHidden: true,
        dataType: 'string',
        alignment: 'left'
    },
    {name:
        'SecurityType', 
        defaultHidden: true,
        dataType: 'string',
        alignment: 'left'
    },

    /*
    Book.FullName, Name
    Book.Country, Country
    Book.ExchangeSymbol, Exchange Symbol
    Book.Currency, Currency
    Book.AssetClass, Asset Class
    */





    /*
    {name: 'ask_price_diff', defaultHidden: true},
    {name: 'ask_price_diff_percent', defaultHidden: true},
    {name: 'ask_time_exec', defaultHidden: true},
    {name: 'bid_price_diff', defaultHidden: true},
    {name: 'bid_price_diff_percent', defaultHidden: true},
    {name: 'bid_time_exec', defaultHidden: true},
    //{name: 'currency_converted_closing_price'},
    //{name: 'currency_converted_market_cap'},
    {name: 'datetimestamp', defaultHidden: true},
    {name: 'high_price_year', defaultHidden: true},
    {name: 'high_price_year_date', defaultHidden: true},
    {name: 'id_code', defaultHidden: true},
    {name: 'initial', defaultHidden: true},
    {name: 'intraday_mid_price', defaultHidden: true},
    {name: 'intraday_mid_yield', defaultHidden: true},
    {name: 'intraday_net_change', defaultHidden: true},
    {name: 'intraday_reported_turnover', defaultHidden: true},
    {name: 'intraday_reported_volume', defaultHidden: true},
    {name: 'is_official_closing_price', defaultHidden: true},
    {name: 'is_official_closing_turnover', defaultHidden: true},
    {name: 'last_bid_yield_diff_closing_basispoints', defaultHidden: true},
    {name: 'last_bid_yield_diff_closing_nominal', defaultHidden: true},
    {name: 'last_bid_yield_diff_closing_percent', defaultHidden: true},
    {name: 'last_dirty', defaultHidden: true},
    {name: 'last_duration', defaultHidden: true},
    {name: 'last_paid_date', defaultHidden: true},
    {name: 'last_paid_time', defaultHidden: true},
    {name: 'last_price_diff_closing_nominal', defaultHidden: true},
    {name: 'last_price_per_point', defaultHidden: true},
    {name: 'last_trade_date', defaultHidden: true},
    {name: 'last_trade_time', defaultHidden: true},
    {name: 'last_volume', defaultHidden: true},
    {name: 'last_yield', defaultHidden: true},
    {name: 'low_price_year', defaultHidden: true},
    {name: 'low_price_year_date', defaultHidden: true},
    {name: 'month_high_price', defaultHidden: true},
    {name: 'month_high_price_date', defaultHidden: true},
    {name: 'month_low_price', defaultHidden: true},
    {name: 'month_low_price_date', defaultHidden: true},
    //{name: 'note_codes'},
    //{name: 'notifications'},
    {name: 'official_currency_rates', defaultHidden: true},
    {name: 'previous_business_day', defaultHidden: true},
    {name: 'previous_closing_price', defaultHidden: true},
    {name: 'previous_first_level_ask', defaultHidden: true},
    {name: 'previous_first_level_bid', defaultHidden: true},
    //{name: 'stop_codes'},
    {name: 'timestamp', defaultHidden: true} 
    */
]