import { Fetched } from "../Types/Common";
import { INewsFeedSourceFilter } from "../Types/NewsType";

const DELIMITER = ';'

export const getSourceStringFromSourceList = (sourceFilters: Fetched<INewsFeedSourceFilter[]>, which: 'on' | 'off') => {
    if (sourceFilters === null || sourceFilters instanceof Error) return null;
    const filteredSources = sourceFilters.filter(filter => (which === 'on' ? filter.isOn : !filter.isOn));
    if (filteredSources.length === 0) return null;
    const filteredSourceTitles = filteredSources.map(sourceFilter => sourceFilter.newsFeedSource.title);
    filteredSourceTitles.sort();
    const sourceFilterString = filteredSourceTitles.join(DELIMITER);
    return sourceFilterString;
}

export const getSourceListFromSourceString = (sourceFilterString: string) => {
    return sourceFilterString.split(DELIMITER);
}

export function getNewsDateOrTime(date: Date): string {
    const now = new Date();
    const isCurrentYear = (date.getFullYear() === now.getFullYear());
    // If today, use time
    if (
        date.getDate() === now.getDate()
        && date.getMonth() === now.getMonth()
        && isCurrentYear
    ) {
        return (((date.getHours()).toString()).padStart(2, '0')
                + ':'
                + ((date.getMinutes()).toString()).padStart(2, '0')
        );
    }
    // If in past, use date
    else if (isCurrentYear) {
        return (
            ((date.getDate()).toString()).padStart(2, '0')
            + '.'
            + ((date.getMonth() + 1).toString()).padStart(2, '0')
            + '.'
        );
    } else {
        return (
            ((date.getDate()).toString()).padStart(2, '0')
            + '.'
            + ((date.getMonth() + 1).toString()).padStart(2, '0')
            + '.'
            + ((date.getFullYear()).toString()).slice(2)
        );
    }
}