import React from 'react';
import { Checkbox, TextField, Popover } from '@mui/material';

import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import './news.scss'
import { INewsFeedSourceFilter } from '../../../Types/NewsType';
import { TranslationManager } from '../../../Translation/Translation';
import FilterItem from './FilterItem';
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import { generateUUID } from '../../../Utils/Common';


interface DropdownProps {
  newsSources: INewsFeedSourceFilter[];
  setNewsSources: (item: INewsFeedSourceFilter[]) => void;
}

const DropdownSwitches: React.FC<DropdownProps> = ({ newsSources, setNewsSources }) => {

  const handleToggle = (fullSource: string, activationStatus: boolean) => {
    const newNewsSources: INewsFeedSourceFilter[] = newsSources.map(source =>
      source.newsFeedSource.fullSource === fullSource ? { ...source, isOn: !activationStatus } : source
    );
    setNewsSources(newNewsSources);
  };

  const allActive = (array: INewsFeedSourceFilter[]): boolean => {
    return array.every(item => item.isOn === true);
  };

  const handleToggleAll = () => {
    //if all are active, turn all off
    if (allActive(newsSources)) {
      const newNewsSources: INewsFeedSourceFilter[] = newsSources.map(source => ({ ...source, isOn: false }));
      setNewsSources(newNewsSources);
    } else {
      const newNewsSources: INewsFeedSourceFilter[] = newsSources.map(source => ({ ...source, isOn: true }));
      setNewsSources(newNewsSources);
    }
  };

  const [searchWord, setSearchWord] = React.useState<string>("")

  const filteredSources = newsSources.filter(source =>
    source.newsFeedSource.fullSource.toLowerCase().includes(searchWord.toLowerCase())
  );

  return (
    <>
      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <div
              className={cx("KM_filterButton", { active: popupState.isOpen })}
              {...bindTrigger(popupState)}
            >
              <FontAwesomeIcon
                className="fa-icon"
                style={{ fontSize: "13px" }}
                icon={faGear}
                flip={"vertical"}
              />
              {
                !allActive(newsSources) &&
                <span style={{
                  position: 'relative',
                }}>
                  <CircleIcon style={{
                    position: 'absolute',
                    width: '6px',
                    height: '6px',
                    fill: 'var(--red-400)',
                    right: '-4px',
                    top: '-1px',
                  }} />
                </span>
              }
            </div>
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="KM_filter FilterMenu">
                <div className='head'>
                  <h4>{TranslationManager.getTranslation().News.Sources.Header}</h4>
                  <div className="closeIcon" onClick={popupState.close}>
                    <ClearIcon />
                  </div>
                </div>
                <div className='search-wrapper'>
                  <>
                    <TextField
                      sx={{ width: '180px' }}
                      inputProps={{ style: { height: "15px" } }}
                      placeholder={TranslationManager.getTranslation().News.Sources.Source_search}
                      id="filled-hidden-label-smal-2"
                      defaultValue={searchWord}
                      size={"small"}
                      value={searchWord}
                      onChange={(e) => { setSearchWord(e.target.value) }}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                    <span style={{ position: 'relative' }} className='btn-wrapper'>
                      {searchWord.length > 0 &&
                        <ClearIcon style={{ position: 'absolute', right: '5px', top: '-6.5px', fontSize: '14px', cursor: 'pointer' }} onClick={() => setSearchWord("")} />
                      }
                    </span>
                    <div onClick={handleToggleAll} >
                      <Checkbox checked={allActive(newsSources)} />
                      <span className='select-all-text'>
                        {TranslationManager.getTranslation().News.Sources.Toggle_all}
                      </span>
                    </div>

                  </>
                </div>
                <div className='filter-wrapper'>
                  {
                    filteredSources.map((source) => (
                      <FilterItem
                        key={generateUUID()}
                        showCheck
                        size='sm'
                        selected={source.isOn}
                        text={source.newsFeedSource.fullSource}
                        toggleSelected={function (): void {
                          handleToggle(source.newsFeedSource.fullSource, source.isOn)
                        }} />
                    ))
                  }

                </div>
              </div>
            </Popover>
          </React.Fragment>
        )}
      </PopupState>
    </>
  );
};

export default DropdownSwitches;
