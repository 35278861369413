import { ContentItem } from "../../Types/GoldenLayout";
import { DefaultWorkspace } from "../../Config/PredefinedWorkspaces/Default";
import { IcelandicWorkspace } from "../../Config/PredefinedWorkspaces/Icelandic";
import { Nordic40 } from "../../Config/PredefinedWorkspaces/Nordic40";
import { Config } from "../../Config/Config";


const PredefinedWorkspaces: WorkSpaceSaveObj[] = [
    DefaultWorkspace,
    IcelandicWorkspace,
    Nordic40
];

// If any major changes are made to the layout savefile
// SaveFileVersion should be incremented
const SaveFileVersion = 1;

export type WorkSpaceSaveObj = {
    name: string;
    saveFileVersion: number;
    content: ContentItem[];
    deletable: boolean;
}


class WorkspaceSaveManager {
    private userWorkspaces: WorkSpaceSaveObj[];
    private workspaceSession: WorkSpaceSaveObj;

    constructor() {
        const sessionCookie = localStorage.getItem(Config.WORKSPACE_SESSION_COOKIE);
        this.workspaceSession = sessionCookie===null?this.defaultSession():JSON.parse(sessionCookie);

        const workspacesCookie = localStorage.getItem(Config.WORKSPACES_COOKIE);
        this.userWorkspaces = workspacesCookie===null?[]:JSON.parse(workspacesCookie);

        this.save();
    }
    private defaultSession(): WorkSpaceSaveObj {
        return {...DefaultWorkspace, name:'_session_'};
    }
    private save() {
        localStorage.setItem(Config.WORKSPACE_SESSION_COOKIE, JSON.stringify(this.workspaceSession));
        localStorage.setItem(Config.WORKSPACES_COOKIE, JSON.stringify(this.userWorkspaces));
    }



    // Session management
    getSession(): WorkSpaceSaveObj {
        return this.workspaceSession;
    }

    saveSession(content: ContentItem[]) {
        this.workspaceSession.content = content;
        this.save();

    }

    reset() {
        this.workspaceSession = this.defaultSession();
        this.save();
    }

    // Workspace management
    getWorkspaces(): WorkSpaceSaveObj[] {
        return [...PredefinedWorkspaces, ...this.userWorkspaces];
    }
    getWorkspace(name: string): WorkSpaceSaveObj | undefined {
        return this.getWorkspaces().find(workspace => workspace.name === name);
    }

    deleteWorkspace(name: string) {
        this.userWorkspaces = this.userWorkspaces.filter(workspace=>workspace.name!==name);
        this.save();
    }

    saveWorkspace(name: string, content: ContentItem[]) {
        if(this.userWorkspaces.some(workspace => workspace.name===name))
            this.userWorkspaces = this.userWorkspaces.filter(workspace=>workspace.name!==name);
        
        const fileObj: WorkSpaceSaveObj = {name,content,saveFileVersion: SaveFileVersion, deletable: true};
        this.userWorkspaces.push(fileObj);
        this.save();
    }
}


const GlobalWorkSpaceManager = new WorkspaceSaveManager();
export function getGlobalWSM(): WorkspaceSaveManager {
    return GlobalWorkSpaceManager
};