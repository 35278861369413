import React from 'react';
import Button from '@mui/material/Button';

interface IProps {
    text: string,
    handleClose: () => void,
    className: string,
}
const CustomButton: React.FC<IProps> = ({
    text,
    handleClose,
    className,
}) => {

    return (
        <Button
            className={`${className}`}
            size="small"
            variant="outlined"
            onClick={handleClose}>
                <span style={{textTransform: 'none'}}>{text}</span>
        </Button> 
    )
}

export default CustomButton