import { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Login } from './Components/Login/Login';
import { Main } from './Components/Main/Main';

import './Styles/Styles.scss';
import NewPassword from './Components/NewPassword/NewPassword';
import { config } from '@fortawesome/fontawesome-svg-core';

export function App() {
  config.autoAddCss = false;
  const userAgent = window.navigator.userAgent;
  const isAppleDevice = /iPhone|iPod/i.test(userAgent);
  const isAndroidDevice = /Android/i.test(userAgent);
  useEffect(() => {
    if (isAppleDevice) {
      window.location.href = 'https://apps.apple.com/app/id1162095731';
    } else if (isAndroidDevice) {
      window.location.href = 'https://play.google.com/store/apps/details?id=is.kodi.keldanMobile';
    }
  }, [isAppleDevice, isAndroidDevice]);

  if (isAppleDevice || isAndroidDevice) {
    return <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      background: "linear-gradient(96deg, #04182B 4.3%, #0C285F 37.13%, #29175B 76.87%)",
      color: 'var(--white)'
    }}>
      <h1>Vefurinn er hannaður fyrir stærri skjá.</h1>
      <h3 >Keldan Appið hentar vel í síma.</h3>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '20px',
        marginTop: '20px'
      }}>
        <a href="https://play.google.com/store/apps/details?id=is.kodi.keldanMobile" target='_blank' rel="noreferrer"><img alt="Google play store link" src="https://cdn.livemarketdata.com/Images/google-play.png" /></a>
        <a href="https://apps.apple.com/app/id1162095731" target='_blank' rel='noreferrer'><img alt="Apple app store link" src="https://cdn.livemarketdata.com/Images/app-store.png" /></a>
      </div>
    </div>
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='*' element={< Main />}></Route>
        <Route path='/login' element={< Login />}></Route>
        <Route path='/new-password' element={< NewPassword />}></Route>
      </Routes>
    </BrowserRouter>
  );
}