import { JSONObject } from "./Common";

export type ContentItem = (
    {
        type: 'stack',
        activeItemIndex: number,
        width?: number,
        height?: number,
    } |
    {
        type: 'component',
        title: string,
        componentName: string,
        componentState: JSONObject,
    } |
    {
        type: 'column' | 'row',
        width?: number,
        height?: number,
    } 
) & {
    content?: ContentItem[],
    isClosable: boolean,
    reorderEnabled: boolean,
}


export const GLCoreEmptyLayout = {
    settings: {
        hasHeaders: true,
        constrainDragToContainer: true,
        reorderEnabled: true,
        selectionEnabled: false,
        popoutWholeStack: false,
        blockedPopoutsThrowError: true,
        closePopoutsOnUnload: true,
    
        showPopoutIcon: false, // Can be true
        showMaximiseIcon: false,
        showCloseIcon: true,
        //resizeWithContainerAutomatically: true
    },
    dimensions: {
        borderWidth: 10,
        
        minItemHeight: 150,
        minItemWidth: 250,
    
        headerHeight: 30,
        dragProxyWidth: 320,
        dragProxyHeight: 200,
    },
    labels: {
        close: 'false',
    },
    content: [] as ContentItem[]
};

export type GLLayoutConfig = typeof GLCoreEmptyLayout;