import { Translation } from "./Translation";
import { Markadir } from "../svgs";

export const IcelandicTranslation: Translation = {
  Page: {
    Title: "Markaðir",
  },
  Basic: {
    Buy: "Kaupa",
    Sell: "Selja",
    Fill: "Fylla",
  },
  Buttons: {
    MarketData: "Markaðsgögn",
    Workspace: "Skjáborðsstillingar",
    Trading: "Viðskipti",
    Reset: "Endurstilla",
    Load: "Opna",
    SaveAs: "Vista sem",
    Settings: "Stillingar",
    Logout: "Útskrá",
    Language: "Tungumál",
    About: "Um",
    Save: "Vista",
    Import: "Hlaða inn",
    Export: "Vista sem skrá",
    Ok: "Ok",
    Cancel: "Hætta við",
    Confirm_order: "Staðfesta pöntun",
    Reset_form: "Hreinsa",
    Back: 'Til baka',
    Reconnect: 'Endurtengjast',
    Hotkeys: 'Flýtileiðir',
  },
  Hotkeys: {
    GlobalHeader: 'Almennar flýtileiðir',
    NavigateHeader: 'Stjórnaðu hvar gluggar opnast',
    NewOrderModal: 'Opnar ný-pöntun glugga',
    ResetWorkspace: 'Endurstilla vinnusvæði',
    MarketDepth: 'Opna markaðsdýpt',
    TradeTicker: 'Opna viðskipti',
    WatchList: 'Opna vaktlista',
    Chart: 'Opna graf',
    News: 'Opna fréttir',
    PriceLadder: 'Opna verðstiga',
    MyOrders: 'Opna mínar pantanir',
    MyTrades: 'Opna mín viðskipti',
    //navigational hotkeys
    Left: 'Til vinstri',
    Right: 'Til hægri',
    Up: 'Uppi',
    Down: 'Niðri',
  },
  Errors: {
    NoSubscriptionFound: "Engar áskriftir fundust",
  },
  PDSErrors: {
    ReplaceChangesNothing: "Engar breytingar áttu sér stað",
  },
  ToastMessage: {
    CancelOrder: {
      pending: 'Hætti við pöntun',
      success: 'Pöntun felld niður',
      error: 'Ekki tókst að fella niður pöntun',
    },
    NewOrder: {
      pending: 'Sendi pöntun',
      success: 'Pöntun send',
      error: 'Ekki tókst að senda pöntun',
    },
  },
  Header: {
    Market: Markadir,
    Menu: {
      DashboardMenu: {
        Header: "Bæta við skjáborð",
        MarketDepth: "Markaðsdýpt",
        TradeTicker: "Viðskiptavakt",
        WatchList: "Vaktlisti",
        Chart: "Graf",
        News: "Fréttir",
      },
      WorkspaceMenu: {
        Header: "Vinnusvæði",
        ResetWorkspace: "Endurstilla",
        OpenWorkspace: "Opna",
        LoadWorkspace: "Hlaða inn",
        SaveWorkspace: "Vista",
        ExportWorkspace: "Vista sem skrá",
      },
    },
    Settings: {
      Language: "Tungumál",
    },
  },
  Chart: {
    Volume: "Magn",
    Price: "Verð",

    Start: "Byrjun",
    End: "Endir",
  },
  WatchList: {
    RightClickTableMenu: {
      NewOrder: "Ný pöntun",
      OrderbookProperties: "Pantanabók",
      MarketDepth: "Markaðsdýpt",
      Chart: "Graf",
    },
    MyLists: {
      Header: "Mínir listar",
      NewList: "Nýr listi",
      DeleteList: "Eyða lista",
      RenameList: "Breyta nafni",
      ClearList: "Hreinsa",
      AddSymbol: "Bæta við",
      NewListLabel: "Nafn á lista",
      SaveList: "Vista",
      ListsText: "Listarnir þínir birtast hér fyrir neðan",
      NoListsText: "Enginn listi til að birta",
      ListsLabel: "Mínir listar",
      SecuritiesLabel: "Verðbréf",
      NewListTitle: "Nýr listi",
      RenameListTitle: "Breyta nafni",
    }
  },
  ViewOrders: {
    RightClickTableMenu: {
      Details: "Nánari upplýsingar",
      Cancel: "Fella niður",
      Modify: "Breyta",
    },
  },
  Titles: {
    SaveWorkspace: "Vista vinnusvæði",
    LoadWorkspace: "Opna vinnusvæði",
    ImportWorkspace: "Hlaða inn vinnusvæði",
    ExportWorkspace: "Vista vinnusvæði sem skrá",
    NewOrderTitle: "Ný pöntun",
    ModifyOrderTitle: "Breyta pöntun",
  },
  Login_Modal: {
    Title: "Innskráning",
    Sub_title: "Keldan Markaðir",
    Username: "Netfang",
    Password: "Lykilorð",
    Login: "Innskrá",
    Remember_me: "Muna eftir mér",
    New_password: "Nýtt lykilorð",
  },
  New_Password: {
    Send_new_password: "Fá sent nýtt lykilorð",
    Affected_systems: "Athugið að nýtt lykilorð tekur gildi á eftirfarandi kerfum:"
  },
  About_Modal: {
    Title: "Um Kelduna markaði",
    CloseButton: "Loka",
    Version: "Útgáfa",
  },
  OrderBook_Modal: {
    Orderbook_title: "Pantanabók",
    Name: "Nafn",
    Short_Name: "Nafn, Stutt",
    ISIN: "ISIN",
    Currency: "Gjaldmiðill",
    Country: "Land",
    Security_Type: "Verðbréfategund",
    SecurityType_share: "Hlutabréf",
    SecurityType_bond: "Skuldabréf",
    ToggleButtons: {
      General: "Almennt",
      Share_Info: "Hlutabréfaupplýsingar",
      Market_Info: "Markaðsupplýsingar",
      Tick_Sizes: "Verðbil",
      Trading_Scheme: "Viðskiptaaðferð",
      Supplementary: "Annað",
    },
  },
  Trading: {
    NewOrder: "Ný pöntun",
    ViewOrders: "Mínar pantanir",
    ViewTrades: "Mín viðskipti",
    BulkOrderShift: "Fjölda pöntun (Shift)",
  },
  Import_Modal: {
    DropZoneText: "Smelltu hér eða dragðu vinnuskjal á þetta svæði.",
    InputPlaceHolder: "Nafn skjals",
  },
  Save_Modal: {
    InputPlaceHolder: "Nafn vinnusvæðis",
  },
  New_Order_Modal: {
    Success_Message: "Pöntun hefur verið send",
    Update_Message: "Pöntun hefur verið breytt",
    Error_Message: "Villa við að senda pöntun",
    Dropdown_options: {
      Buy: "Kaupa",
      Sell: "Selja",
    },
    Labels: {
      Buy_sell: "Kaupa/Selja",
      Quantity: "Magn",
      Autocomplete_symbol: "Auðkenni",
      Price: "Verð",
      Order_type: "Pantanargerð",
      Visible_quantity: "Sýnilegt magn",
      Hidden: "Falið",
      Time_in_force: "TIF",
      Min_quantity: "Lágmarks magn",
      Broker: "Broker",
      Portfolio: "Vörslusafn",
      Account: "Reikningur",
    },
    Total: "Heildarverð",
    Symbol_info: {
      Last: "Síðasta verð",
      Ask: "Söluverð",
      Bid: "Kaupverð",
    },
  },
  Settings_Modal: {
    Title: "Stillingar",
    CloseButton: "Loka",
    Username: "Notandanafn",
    Auth_url: "Auðkenningarslóð",
    Market_data_connections: "Tengingar við markaðsgögn",
    PDS_connections: "Viðskiptatengingar",
    Api_url: "API slóð",
    Kodiak_web: "Kodiak web",
    Subscriptions: "Gagnaáskriftir",
    Display_time_zone: "Tímabelti",
    Delete_cache: "Eyða cache",
    Active: "Virk",
    Url: "Slóð",
    Name: "Nafn",
    Description: "Lýsing",
  },
  Search: "Leita",
  News: {
    Sources: {
      Header: "Veitur",
      Source_search: "Leita að veitu",
      Toggle_all: "Velja allt",
    },
    Category_DropDown: {
      Business: "Viðskipti",
      General: "Almennt",
      Sports: "Íþróttir",
      Fishing_industry: "Sjávarútvegur",
      Podcast: "Hlaðvarp",
    },
  },
  MarketDepth: {
    total: "Fjöldi",
  },
  ComponentName: {
    WatchList: "Vaktlisti",
    Chart: "Graf",
    TradeTicker: "Viðskipti",
    MarketDepth: "Markaðsdýpt",
    News: "Fréttir",
    PriceLadder: "Verðstigi",

    //trading components
    ViewOrders: "Mínar pantanir",
    ViewTrades: "Mín viðskipti",
    BulkOrderShift: "Fjölda pöntun (Shift)",

    // retail trading
    RetailTrading: "Retail Trading!!!",
  },
  OrderbookState: {
    not_available: "Not available",
    closed: "Closed",
    pre_trading: "PreTrading",
    trading: "Trading",
    terminating: "Terminating",
    post_trading: "PostTrading",
    call_interaction: "CallInteraction",
    pre_close: "PreClose",
    extended_trading: "ExtendedTrading",
    mid_price_auction: "MidPriceAuction",
    opening_auction: "OpeningAuction",
    closing_auction: "ClosingAuction",
    turnover: "Turnover",
    end_of_trading: "EndOfTrading",
    statistics: "Statistics",
    orders_are_cleared: "OrdersAreCleared",
    scheduled_intraday_auction: "ScheduledIntradayAuction",
    suspended_or_halted: "SuspendedOrHalted",
    auction_period: "AuctionPeriod",
    pre_open: "PreOpen",
    trading_pause: "TradingPause",
    trading_at_closing_price: "TradingAtClosingPrice",
    sold_out_buy_back: "SoldOutBuyBack",
  },
  Columns: {
    symbol: "Auðkenni",
    time_executed: "Tími",
    datetime_exec: "Tími UTC",
    buyer: "Kaupandi",
    aggressive_party: "Ág.aðili",
    seller: "Seljandi",
    price: "Verð",
    yield: "Áv.Krafa",
    price_dirty: "Óhreint v.",
    duration: "Meðallíftími",
    price_per_point: "PPP",
    volume: "Magn",
    value: "Virði",
    standard: "Staðall",
    trade_updates_last_paid: "Uppf. síðast",
    trade_updates_high_low: "Uppf. H/L",
    trade_updates_average: "Uppf. meðal",
    trade_updates_turnover: "Uppf. velta",
    is_latest_trade: "Nýjustu viðsk.",
    outside_spread: "Utan gengismunar",
    trade_cancellation: "Felld niður",
    trade_cancellation_time: "Tími niðurfell.",
    settlement_date: "Uppgjörsd.",
    timestamp_agreement: "Tími samþykkis",
    timestamp_dissemination: "Tími dr.",
    trade_id: "Viðsk. ID",
    trade_number: "Viðsk. Nr.",
    class: "Flokkur",
    trade_type_name: "Viðsk. Teg.",
    diff_last_price: "Δ Síðasta",

    // WatchList
    name: "Nafn",
    orderbook_state: "Staða",
    bid_orders: "K #",
    bid_quantity: "K magn",
    bid_price: "K verð",
    last_bid_yield_diff_closing_basispoints: "Δ B Y BP",
    bid_price_per_point: "K PPP",
    ask_price_per_point: "S PPP",
    ask_price: "S verð",
    ask_quantity: "S magn",
    ask_orders: "S #",
    change: "Breyting",
    last_price: "Síðasta",
    last_price_diff_closing_nominal: "Δ Síðasta",
    last_price_diff_closing_percent: "Δ Síðasta %",
    last_yield_diff_closing_nominal: "Δ Síðasta krafa",
    intraday_no_of_trades: "# Viðskipta",
    intraday_first_price: "Opnunarv.",
    intraday_high_price: "Hæsta",
    intraday_low_price: "Lægsta",
    intraday_vwap: "VWAP",
    intraday_average_price: "Meðalverð",
    intraday_accumulated_volume: "Heildarmagn",
    intraday_accumulated_turnover: "Heildarvelta",
    market_capital: "Markaðsvirði",
    currency: "Mynt",
    country: "Land",
    exchange_symbol: "Markaður",
    asset_class: "Eignaflokkur",

    // Formated from Kodiak PRO
    bid_price_diff_percent: "Δ Síðasta kaupv. %",
    bid_price_diff: "Δ Síðasta kaupv.",
    ask_price_diff: "Δ Síðasta söluv.",
    ask_price_diff_percent: "Δ Síðasta söluv. %",
    bid_yield: "K krafa",
    bid_dirty: "K óhreint",
    bid_duration: "K meðallíftími",
    ask_duration: "S meðallíftími",
    ask_dirty: "S óhreint",
    ask_yield: "S krafa",
    last_yield: "Síðasta krafa",
    last_yield_diff_closing_percent: "Diff síðasta krafa",
    last_yield_diff_closing_basispoints: "Δ Krafa BP",
    last_duration: "Síðasti meðallíftími",
    last_dirty: "Síðasta óhreina",
    last_price_per_point: "Síðasta PPP",
    previous_closing_price: "PCL verð",
    previous_closing_yield: "PCL krafa",
    previous_closing_duration: "PCL meðallíftími",
    intraday_reported_volume: "Tilkynnt magn",
    intraday_reported_turnover: "Tilkynnt velta",
    notifications: "Tilkynningar",

    // Static data columns
    Name: "Nafn",
    ExchangeSymbol: "Kauphallarauðkenni",
    TradingCurrency: "Mynt",
    SecurityType: " Eignaflokkur",

    // Market Depth
    b_time: "K tími",
    b_trader: "K aðili",
    b_orders: "K pantanir",
    b_volume: "K magn",
    b_yield: "K krafa",
    b_dirty: "K óhreint",
    b_price: "K verð",
    a_price: "S verð",
    a_dirty: "S óhreint",
    a_yield: "S krafa",
    a_volume: "S magn",
    a_orders: "S pantanir",
    a_trader: "S aðili",
    a_time: "S tími",
    a_participant: "S þátttakandi",
    a_order_key: "S pöntunarauðkenni",
    b_participant: "K þátttakandi",
    b_order_key: "K pöntunarauðkenni",

    intraday_mid_price: "Miðverð",
    change_precent: "Breyting %",

    //****************//
    // PDS COMPONENTS //
    //****************//

    //View order
    created_at: "Skráð",
    // symbol: "Symbol",
    status: "Staða",
    side: "K/S",
    order_type: "Tegund",
    quantity: "Magn",
    last_updated_by: "Notandi",
    remaining_quantity: "Eftirstöðvar",
    filled_quantity: "Parað magn",
    last_updated: "Síðast uppfært",
    average_price: "Meðalverð",
    time_in_force: "TIF",
    isin: "isin",
    broker: "Verðbréfafyrirtæki",

    secondary_order_id: "Secondary order ID",
    visible_quantity: "Sýnilegt magn",

    //VIEW TRADES
    transactionTime: "Tími",
    allocated: "Úthlutað",
    cancelled: "Felld niður",
    trader_username: "Notandi",
    counterparty: "Mótaðili",
    customer_id: "Customer ID",
    customer_name: "Customer Name",
    exchange_name: "Markaður",
    executed_price: "Verð",
    executed_quantity: "Magn",
    execution_id: "Execution ID",
    instrument_long_name: "Verðbréf",
    order_class: "Order Class",
    order_id: "Order ID",
    submarket_name: "Undirmarkaður",
    trade_type: "Viðskiptategund",
  },
  NewOrderModal_DoubleCheck: {
    symbol: 'Auðkenni',
    side: 'Hlið',
    price: 'Verð',
    quantity: 'Fjöldi hluta',
    visible_quantity: 'Sjáanlegur fjöldi hluta',
    order_type: 'Tegund pöntunar',
    time_in_force: 'TIF',
    broker: 'Verðbréfafyrirtæki',
    portfolio_id: 'Vörslusafn',
    account_id: 'Reikningur',
    // reference_id: 'Reference ID',
  }
};
