


export class Config {
    static HTTP_Type: "https" | "http" = 'https';

    // Backend urls
    static User_System_Domain = 'users.livemarketdata.com';
    static LMD_Domain = 'api.livemarketdata.com';
    static News_Domain = 'news-search.livemarketdata.com';
    static Keldan_User_Domain = 'users.keldan.is';
    
    static changeBackendType(type: 'production'|'staging') {
        const ending = {'production': '', 'staging':'-staging'}[type];
        Config.User_System_Domain = `users${ending}.livemarketdata.com`;
        Config.LMD_Domain = `api${ending}.livemarketdata.com`;
        Config.Keldan_User_Domain = `users${ending}.keldan.is`;
    }


    static SYSTEM_ID = "keldan_pro_web";

    static UIUpdatesPerSecond = 2;


    // These are then used for local storage not cookies
    static API_TOKEN_COOKIE = "keldan_pro_token";
    static WORKSPACES_COOKIE = "keldan_pro_workspaces";
    static WORKSPACE_SESSION_COOKIE = "keldan_pro_workspace_session";

    static LANGUAGE_COOKIE = 'keldan_pro_language';
}