import { useEffect, useRef, useState } from 'react';

export const usePageCounter = () => {
    const [ totalPages, setTotalPagesState] = useState<number | null>(null);
    const [ currentPageIndex, setCurrentPageIndex ] = useState(0);
    const [ previousIndex, setPreviousIndex ] = useState<number | null>(null);

    const goToNextPage = () => {
        if (totalPages === null) return;
        const nextPage = Math.min((currentPageIndex + 1), totalPages - 1);
        setPreviousIndex(currentPageIndex);
        setCurrentPageIndex(nextPage);
    }
    const goToPreviousPage = () => {
        const previousPage = Math.max((currentPageIndex - 1), 0);
        setPreviousIndex(currentPageIndex);
        setCurrentPageIndex(previousPage);
    }
    const setTotalPages = (totalCountOfItems: number | null, itemsPerPage: number) => {
        if (totalCountOfItems === null) {
            setTotalPagesState(null);
        } else {
            const newTotalPages = getNumberOfPages(totalCountOfItems, itemsPerPage);
            setTotalPagesState(newTotalPages);
        }
    }

    const resetPageCounter = () => setCurrentPageIndex(0);

    return {
        currentPageIndex,
        previousIndex,
        totalPages,
        goToNextPage,
        goToPreviousPage,
        setTotalPages,
        resetPageCounter
    }
}

export const useStateRef = <T extends unknown>(stateVariable: T) => {
    const ref = useRef(stateVariable);
    useEffect(() => { ref.current = stateVariable }, [ stateVariable ]);
    return ref;
}
export const getNumberOfPages = (totalCountOfItems: number, itemsPerPage: number) => Math.ceil(totalCountOfItems / itemsPerPage);
