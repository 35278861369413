import { ColumnInfo } from "../../../Types/MUITable";

export const TradeColumnsInfo: ColumnInfo[] = [
  { name: "transactionTime", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "symbol", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "broker", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "side", defaultHidden: false, dataType: "string", alignment: 'center' },
  { name: "executed_quantity", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "executed_price", defaultHidden: false, dataType: "number", alignment: 'right' },

  { name: "currency", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "instrument_long_name", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "trader_username", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "allocated", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "cancelled", defaultHidden: false, dataType: "boolean", alignment: 'center' },
  { name: "counterparty", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "customer_id", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "customer_name", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "exchange_name", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "execution_id", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "isin", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "order_class", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "order_id", defaultHidden: false, dataType: "number", alignment: 'left' },
  { name: "submarket_name", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "trade_id", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "trade_type", defaultHidden: false, dataType: "string", alignment: 'left' },
];