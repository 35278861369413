import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import * as React from "react";
import Button from "@mui/material/Button";
import { AppStateManager } from "../../StateManager";
import { TranslationManager } from "../../Translation/Translation";
import SpringModal from "../UI-Elements/Modal";
import SettingsModal from "../Modals/SettingsModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faArrowRightFromBracket,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import cx from "classnames";
import Popover from "@mui/material/Popover";
import LanguageSelector from "../UI-Elements/LanguageSelector";
import { Environment } from "../../Config/Environment";
import { Config } from "../../Config/Config";
import { GLWindowManager } from "../Windows/GLWindowManager";
import { openDefaultModal } from "../Main/Main";
import HotkeysModal from "../Modals/HotkeysModal";

export function SettingsDropDown() {
  return (
    <PopupState variant="popover">
      {(popupState) => (
        <React.Fragment>
          <Button
            className="noHoverButton"
            disableRipple
            size="small"
            variant="text"
            id="settingsButton"
            {...bindTrigger(popupState)}
          >
            <div
              className={cx("headerButton settingsButton", {
                active: popupState.isOpen,
              })}
            >
              <FontAwesomeIcon
                className="fa-icon"
                style={{ marginRight: "6px" }}
                icon={faUser}
              />
              {AppStateManager.userProfile.username}
              <FontAwesomeIcon
                className={cx("fa-icon rotate", {
                  "rotate-180": popupState.isOpen,
                })}
                style={{ marginLeft: "8px" }}
                icon={faAngleDown}
              />
            </div>
          </Button>
          <Popover
            className="settingsPopover"
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div className="settingsMenu">
              {/* LANGUAGE OPTION */}
              <LanguageSelector />

              <SpringModal
                className="menu-item"
                modal={<SettingsModal closeModal={() => popupState.close()} />}
              >
                {TranslationManager.getTranslation().Buttons.Settings}
              </SpringModal>

              {/* HOTKEYS BUTTON */}
              <div
                onClick={async () => {
                  openDefaultModal(<HotkeysModal />);
                }}
              >
                <div className="menu-item">
                  {TranslationManager.getTranslation().Buttons.Hotkeys}
                </div>
              </div>
              {/* LOG OUT BUTTON */}
              <div
                onClick={async () => {
                  await AppStateManager.logout();
                }}
              >
                <div className="menu-item">
                  <FontAwesomeIcon
                    className="fa-icon"
                    style={{ marginRight: "6px" }}
                    icon={faArrowRightFromBracket}
                  />
                  {TranslationManager.getTranslation().Buttons.Logout}
                </div>
              </div>


              {Environment === "development" && (
                <div
                  className="menu-item"
                  onClick={() =>
                    console.log(JSON.stringify(GLWindowManager.getContent()))
                  }
                >
                  [development] Log Content
                </div>
              )}

              {Environment === "development" && (
                <div
                  className="menu-item"
                  onClick={() => {
                    localStorage.removeItem(Config.WORKSPACES_COOKIE);
                    localStorage.removeItem(Config.WORKSPACE_SESSION_COOKIE);
                  }}
                >
                  [development] Delete Storage
                </div>
              )}
              {Environment === "development" && (
                <div
                  className="menu-item"
                  onClick={() => {
                    AppStateManager.MF.currentSocket?.closeSocket()
                    Object.values(AppStateManager.PDS.PDSsockets).forEach((waitableSocket) => {
                      waitableSocket.closeSocket();
                    });
                  }}
                >
                  [development] Disconnect Socket
                </div>
              )}
            </div>
          </Popover>
        </React.Fragment>
      )}
    </PopupState>
  );
}
