import {
  AuthenticationMessage,
  MessageType,
  OmsMessage,
  StreamerError,
  SubscriptionCommands,
  UnAuthorizedMessage,
  WSStatus,
} from "../../Types/Websocket";
import { BehaviorSubject } from "rxjs";
import { filter, first } from "rxjs/operators";
import {
  PDSWebSocketConsoleLogging,
  WebSocketSubscriptionLogging,
} from "../../Config/Logging";
import { AppStateManager } from "../../StateManager";
import Bugsnag from "@bugsnag/js";
import { ViewOrdersHandler } from "./Handlers/ViewOrdersHandler";
import { ViewTradesHandler } from "./Handlers/ViewTradesHandler";
import { LMDInterface } from "../LMD";
import Button from "../../Components/UI-Elements/Button";
import { toast } from "react-toastify";
import { ICONS } from "../../Utils/Definitions";

const WEBSOCKET_AUTHENTICATION_TIMEOUT = 10000;
class WaitableVariable<T> {
  behaviorSubject: BehaviorSubject<T | null>;
  constructor() {
    this.behaviorSubject = new BehaviorSubject(null as T | null);
  }

  // Only returns non null values
  waitForValue(): Promise<T> {
    return new Promise((resolve) =>
      this.behaviorSubject
        .pipe(
          filter((value) => value !== null),
          first()
        )
        .subscribe((value) => resolve(value as T))
    );
  }

  value(): T | null {
    return this.behaviorSubject.value;
  }

  set(value: T) {
    this.behaviorSubject.next(value);
  }

  clear() {
    this.behaviorSubject.next(null);
  }
}

export type ManageSubscriptionMessage = {
  type: MessageType;
  command: SubscriptionCommands;
  message?: any;
};

export type PDSManagerHandlers = {
  view_orders: ViewOrdersHandler;
  view_trades: ViewTradesHandler;
};

/**
 * PDSManagerRecord class is responsible for managing multiple PDSSockets and their handlers.
 */
export class PDSManagerRecord {
  // A record of PDSSockets, keyed by their URL.
  public PDSsockets: Record<string, PDSSocket>

  // Handlers for different types of messages.
  private handlers: PDSManagerHandlers;

  /**
   * Returns the handler for a given type.
   * @param type - The type of the handler.
   * @returns The handler for the given type.
   */
  public getHandler<T extends keyof PDSManagerHandlers>(
    type: T
  ): PDSManagerHandlers[T] {
    return this.handlers[type];
  }

  /**
   * Constructs a new PDSManagerRecord.
   */
  constructor() {
    this.PDSsockets = {};
    this.handlers = {
      view_orders: new ViewOrdersHandler(),
      view_trades: new ViewTradesHandler(),
    };
  }

  /**
   * Initializes the PDSManagerRecord with a given token.
   * @param token - The token to use for authentication.
   */
  public initial(token: string) {
    AppStateManager.userProfile.access.forEach((connection) => {
      if (connection.type === "private_data") {
        // Test the PDS version.
        const testPDSVersion = LMDInterface.testPDSVersion(connection)

        // If the test returns an error, skip this connection.
        if (testPDSVersion instanceof Error) {
          return;
        }

        // Create a new PDSSocket for this connection and store it in the record.
        const cleanedURl = 'wss://' + new URL(connection.url).host + '/dma/streamer/api';
        this.PDSsockets[cleanedURl] = new PDSSocket(cleanedURl, connection.name, token, this.handlers, this.getHandler.bind(this));
      }
    })
  }
}

export class PDSSocket {
  public socket: WaitableVariable<WebSocket>;
  public url: string;
  public name: string | undefined;
  public lastHeartBeat: BehaviorSubject<Date> = new BehaviorSubject(new Date());
  status: BehaviorSubject<WSStatus>;
  error: string | null;
  handlers: PDSManagerHandlers;
  getHandler: (type: keyof PDSManagerHandlers) => PDSManagerHandlers[keyof PDSManagerHandlers];
  private token: string | null;
  public retryTimer: {
    active: boolean,
    intervalSeconds: number,
    timer: number | null,
    networkLost: boolean,
  }
  constructor(url: string, name: string, token: string | null, handlers: PDSManagerHandlers, getHandler: (type: keyof PDSManagerHandlers) => PDSManagerHandlers[keyof PDSManagerHandlers]) {
    this.url = url;
    this.token = token;
    this.socket = new WaitableVariable<WebSocket>();
    this.status = new BehaviorSubject<WSStatus>("disconnected");
    this.error = null;
    this.handlers = handlers;
    this.getHandler = getHandler;
    this.name = name;
    this.retryTimer = {
      active: true,
      intervalSeconds: 0,
      timer: null,
      networkLost: false,
    }

    this.initializeSocket();
  }
  private toastErrorHandler(status: string) {
    const active = toast.isActive(this.name ?? 1);
    //status closed 
    if (status === 'closed') {
      if (!active) {
        //create new toast if not active
        toast.error(<span>{`Streamer ${this.name} has been closed`}<Button buttonType="secondary" size="sm" onClick={() => this.disconnect()}> Reconnect</Button></span>,
          {
            toastId: this.name,
            closeButton: true,
            autoClose: false,
            closeOnClick: false,
            icon: ICONS.error
          })
      } else {
        //update existing toast
        toast.update(this.name as string, {
          render: <span>{`Streamer ${this.name} has been closed`}<Button buttonType="secondary" size="sm" onClick={() => this.disconnect()}> Reconnect</Button></span>,
          toastId: this.name,
          closeButton: true,
          autoClose: false,
          closeOnClick: false,
          type: "error",
          icon: ICONS.error

        })
      }
    } else if (status === 'connecting') {
      toast.update(this.name as string, {
        icon: ICONS.reconnecting,
        render: `Reconnecting to ${this.name}`,
        autoClose: false,
        toastId: this.name,
        type: "warning",
      })
    }
    //Connected status
    else if (status === 'connected') {
      if (active) {
        toast.update(this.name as string, {
          render: `Connected to ${this.name}`,
          autoClose: 2000,
          hideProgressBar: true,
          icon: ICONS.success,
          toastId: this.name,
          type: "success",
        })
      }
    }
    //Offline status
    else if (status === 'offline') {
      toast.error(`Server is offline for ${this.name}`, {
        toastId: this.name,
      });
    }
  }
  private initializeSocket() {
    //subscribe to the status of the socket
    this.status.subscribe((status) => {
      if (WebSocketSubscriptionLogging) console.log("Socket status changed:", this.name, status);
      this.toastErrorHandler(status);
      if (status === 'disconnected') {
        if (!this.retryTimer.active || this.retryTimer.timer) return;
        if (this.retryTimer.intervalSeconds > 60) {
          this.status.next('closed');
        }
        this.retryTimer.timer = window.setTimeout(() => {
          this.retryTimer.intervalSeconds += 5;
          //IF RETRY TIMER ER MEIRA EN 5/10 sek að þá viljum við sýna að við erum að reyna tengjast ennþá? 
          this.retryTimer.active = true;
          this.retryTimer.timer = null;
          //clear socket
          this.socket.clear();
          this.socket.set(this.createSocket());
        }, this.retryTimer.intervalSeconds * 1000);
      } else if (status === 'connected' || status === 'connecting') {
        this.retryTimer.timer = null;
        this.retryTimer.networkLost = false;
      }
    });
  }
  private openSocket(socket: WebSocket | null) {
    if (!socket) return;
    socket.send(
      JSON.stringify({
        type: 'authentication',
        command: "logon",
        message: { token: this.token },
      })
    );
  }
  public createSocket(): WebSocket {
    this.status.next("connecting");
    const newURL = this.url;
    const socket = new WebSocket(newURL);

    socket.addEventListener("open", (event) => this.openSocket(socket));

    socket.addEventListener('close', () => {
      this.closeSocket()
    });
    socket.addEventListener('error', this.handleError.bind(this));
    socket.addEventListener('message', (event) => { this.handleMessage(JSON.parse(event.data)) });

    window.setTimeout(() => {
      if (this.status.value === 'connecting') {
        this.retryTimer.active = false;
        this.status.next("closed");
        this.error = "Websocket connection timeout";
      }
    }, WEBSOCKET_AUTHENTICATION_TIMEOUT);
    return socket;
  }
  public closeSocket(): void {
    const socket = this.socket.value()
    if (socket) {
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = true;
      this.retryTimer.intervalSeconds = 0;
      this.retryTimer.timer = null;
      this.status.next('closed');
    }
  }

  public disconnect() {
    const socket = this.socket.value()
    if (socket) {
      //start by closing the socket
      socket.onclose = () => { };
      socket.close();
      this.retryTimer.active = true;
      this.status.next('disconnected');
    }
  }
  private handleMessage(messageEvent: {
    message: AuthenticationMessage | OmsMessage[] | OmsMessage | any;
    type: MessageType | "status" | "authentication" | "oms" | "error";
    command: "order" | "trade";
  }) {
    this.lastHeartBeat.next(new Date());
    const broker = this.name;
    const { message, type } = messageEvent;

    if (PDSWebSocketConsoleLogging) console.log("Message from server:", type, message);
    if (type === "error") {
      this.handleErrorMessages(message);
    }
    else if (type === "status") {
      const { status } = message as { status: any };
      this.handleStatus(status);
    } else if (type === "authentication") {
      this.handleAuthentication(message);

      // Check if message is an array
    } else if (Array.isArray(messageEvent)) {
      //if it's array than check if it' has type OMS
      if (messageEvent[0].type === "oms") {
        messageEvent.forEach((message: OmsMessage) => {
          if (message.command === "order") {
            this.getHandler("view_orders").handleMessage(
              message.message.map(item => ({ ...item, broker }))
            );
          }
          else if (message.command === "trade") {
            this.getHandler("view_trades").handleMessage(
              message.message.map(item => ({ ...item, broker }))
            );
          }
        });
      }
    }
    //check if it's an object of type OmsMessage
    else if (messageEvent.type === "oms") {
      if (messageEvent.command === "order") {
        this.getHandler("view_orders").handleMessage(
          [{ ...messageEvent.message, broker }]
        );
      } else if (messageEvent.command === "trade") {
        this.getHandler("view_trades").handleMessage([{ ...message, broker }]);
      }
    } else {
      console.error("Unhandled message from server:");
      console.log({ type });
      console.log(message);
      //TO:DO
      // SHOW ERROR MESSAGE AS TOAST FOR USER 
    }
  }


  private handleAuthentication(message: AuthenticationMessage | UnAuthorizedMessage) {
    if (message.authenticated) {
      this.retryTimer.intervalSeconds = 0;
      this.error = null;
      this.status.next("connected");
    } else {
      const unAuthMessage = message as UnAuthorizedMessage
      const unAuthMsg = unAuthMessage.text ?? unAuthMessage.reason
      if (unAuthMsg === StreamerError.MultipleSessions) {
        //DISPLAY ALERT FOR USER TO EITHER LOG OUT OR CLOSE OTHER SESSIONS
        //RECCONNECT WITH THE SAME TOKEN
        console.log('Multiple sessions detected')
      } else if (unAuthMsg === StreamerError.TokenExpired || unAuthMsg === StreamerError.PDSTokenExpired) {
        //FORCE FETCH NEW LOGIN STATE
        // LOG THE USER OUT
        console.log('Token expired')
        AppStateManager.logout();
      } else if (unAuthMsg === StreamerError.OMSTimeout
        || unAuthMsg === StreamerError.OMSUnavailable
        || unAuthMsg === StreamerError.OMSUserNotFound
        || unAuthMsg === StreamerError.OMSOtherError) {
        Bugsnag.notify(new Error(unAuthMsg));
      } else if (unAuthMsg === StreamerError.SystemNotAllowed) {
        this.closeSocket();
      }

      if (this.error !== null) {
        //TO DO :::: 
        // DISPLAY ERROR MESSAGE TO USER as a toast message
        console.log('error:', this.error)
      }
      this.status.next("disconnected");
    }
  }

  private handleStatus(status: "offline" | "online" | "catchup") {
    if (status === "online") {
      this.error = null;
      this.status.next("connected");
    } else if (status === "offline") {
      this.error = "Server is offline";
      this.closeSocket();
    } else if (status === "catchup") {
      this.error = "Server is in catchup";
      this.status.next("connecting");
    }
  }

  private handleError(event) {
    console.log('event', event, this);
    // IDK what to do with this information yet...
    console.error("Error on socket:");
    console.error(event);
  }

  private handleErrorMessages(error: { reason: string, status: string }) {
    if (error.reason === StreamerError.MarketFeedNotOnline || error.status === "offline")
      this.closeSocket();
    else if (error.reason === StreamerError.InvalidSymbol) { }
    // do nothing
    else if (error.reason === StreamerError.Unauthorized) { }
    // do nothing
    else if (error.reason === StreamerError.AdminClosed) { }
    // do nothing
    else {
      this.disconnect();
    }
  }
}