import { BehaviorSubject, Subscription } from "rxjs";
import { MessageHandler } from "./AbstractHandler";
import {
  MessageType,
  SubscriptionCommands,
  TradeMessage,
} from "../../../Types/Websocket";
import { ManageSubscriptionMessage } from "../MarketFeedWebsocketManager";

export class ViewTradesHandler extends MessageHandler<TradeMessage[]> {
  private trades: BehaviorSubject<TradeMessage[]>;

  constructor() {
    super();
    this.trades = new BehaviorSubject<TradeMessage[]>([]);
  }

  handleMessage(message: TradeMessage[]): void {
    // Get the current orders
    const currentTrades = this.trades.value;

    // Iterate over the new orders
    message.forEach((trade) => {
      // Check if the order already exists
      const exists = currentTrades.find(
        (currentTrade) => currentTrade.execution_id === trade.execution_id // replace 'id' with the actual property to compare
          && currentTrade.symbol === trade.symbol
          && currentTrade.side === trade.side

      );

      // If the trade doesn't exist, add it to the trades
      if (!exists) {
        currentTrades.push(trade);
      }
    });

    // Update the orders
    this.trades.next(currentTrades);
  }

  //counter sem telur hvað eru margir bunir að subscribe-a

  subscribe(callback: (trade: TradeMessage[]) => void): Subscription {

    const subscription = this.trades.subscribe(callback);
    return subscription;
  }

  regenerateSubscriptionMessage(): ManageSubscriptionMessage {
    return { type: MessageType.time, command: SubscriptionCommands.Subscribe };

  }
}
