
import IconButton from "@mui/material/IconButton/IconButton";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import ClearIcon from '@mui/icons-material/Clear';
import cx from 'classnames';

interface ClearInputIconProps {
    isFocused?: boolean;
    style?: React.CSSProperties;
    onClick: () => void;
    size?: string;
}

const ClearInputIcon: React.FC<ClearInputIconProps> = ({ style, onClick, isFocused, size = "14px" }) => {
    const numericSize = parseInt(size, 10);
    return (
        <InputAdornment position="end" className={cx("KM_clear-input-icon")} style={style}>
            <IconButton
                style={{ width: numericSize, height: numericSize }}
                className="KM_clear-input-icon-button"
                aria-label="clear input"
                onClick={onClick}
                edge="start"
            >
                <ClearIcon style={{ fontSize: numericSize - 2 + 'px' }} className={cx("clear-icon", { 'isFocused': isFocused })} fontSize="small" />
            </IconButton>
        </InputAdornment>
    );
};

export default ClearInputIcon;