import { TranslationManager } from "../../Translation/Translation";

const TableBar: React.FC<{
  value: string;
}> = ({ value }) => {
  const barStyle =
    value === "sell"
      ? { left: 0, background: "var(--red-200)" }
      : { right: 0, background: "var(--green-200)" };
  const fontStyle = { zIndex: 1 };
  fontStyle["color"] = value === "buy" ? "var(--green-600)" : "var(--red-600)";

  const getTranslation = () => {
    if (value === "buy") {
      const lang = TranslationManager.getActiveLanguage();
      return lang === "IS" ? "K" : "B";
    } else return "S";
  };

  return (
    <div
      className="fill"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "absolute",
          ...barStyle,
          top: 0,
          width: `100%`,
          height: "100%",
        }}
      ></div>
      <div style={fontStyle}>{getTranslation()}</div>
    </div>
  );
};

export default TableBar;
