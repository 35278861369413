// Package imports:
import React from 'react';
import cx from 'classnames';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faAngleRight } from '@fortawesome/pro-regular-svg-icons'

type AnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;
type LinkSize = '12' | '13' | '14' | '15' | '16' | '18' | '20';
interface IProps {
    url?: string,
    disabled?: boolean,
    targetBlank?: boolean,
    wrapTextForMobile?: boolean,
    splitLines?: boolean,
    linkSize?: LinkSize,
    arrow?: boolean
}

type Props = AnchorProps & IProps;

const Link: React.FC<Props>= ({
    url,
    children,
    disabled,
    targetBlank,
    wrapTextForMobile,
    splitLines,
    linkSize = '15',
    arrow,
    className,
    ...props
}) => {
    return (
        <a
            className={cx(
                "KCL_link",
                className,
                `font-size--${linkSize}`,
                {'is-disabled': disabled},
                {'wrap-text-for-mobile': wrapTextForMobile},
                {'split-lines': splitLines}
            )}
            href={url}
            target={targetBlank ? '_blank' : '_self'}
            rel='noreferrer'
            {...props}
        >
            {/* arrow && <FontAwesomeIcon icon={faAngleRight} className="link-arrow" /> */}
            {children}
        </a >
    );
}

export default Link;