import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import cx from 'classnames';
import CustomButton from '../UI-Elements/CustomButton';
import { TranslationManager } from '../../Translation/Translation';

import './FileUpload.scss';
import { ContentItem } from '../../Types/GoldenLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import Input from '../UI-Elements/Input';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'row-reverse',
  alignItems: 'center',
  padding: '35px 78px 35px 30px',
  borderWidth: 1,
  borderRadius: 10,
  gap: '20px',
  borderColor: 'var(--blue-400)',
  borderStyle: 'dashed',
  backgroundColor: 'var(--blue-100)',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  marginBottom: '25px',
};

const focusedStyle = {
  borderColor: 'var(--blue-400)'
};

const acceptStyle = {
  borderColor: 'var(--green-600)',
  backgroundColor: "var(--green-200)",
};

const rejectStyle = {
  backgroundColor: "var(--red-200)",
  borderColor: 'var(--red-600)'
};

function FileUpload(props) {
  const { onFileUpload } = props;
  const [fileName, setFileName] = React.useState<string>('')
  const [fileContent, setFileContent] = React.useState<string | ArrayBuffer | null | undefined>(null)
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    accept: { 'application/json': [] },
    validator: (e) => validFile(e),
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        setFileName(file.name.replaceAll('.json', ''));
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target?.result;
          setFileContent(content)
        };
        reader.readAsText(file);
      }
    },
    onDropRejected: (rejectedFile) => {
      if (rejectedFile[0].errors) alert(rejectedFile[0].errors[0].message)
    }
  });

  function validFile(file: File) {
    if (file.name !== undefined) {
      if (file.name.replace('.json', '').length > 20)
        return {
          code: "name-too-large",
          message: `Name is larger than ${20} characters`
        };
      else if (/^[\wþæöðíóáéúý]+$/.exec(file.name.toLocaleLowerCase())) {
        return {
          code: "name-includes-forbidden-characters",
          message: `Name includes symbols or forbidden characters`
        };
      }
      else return null
    }
    else return null
  }
  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  return (
    <div className="KP_FileUpload">
      {/* @ts-ignore */}
      <div className={cx('dropZone', { 'accepted': isDragAccept, 'rejected': isDragReject })} {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>{TranslationManager.getTranslation().Import_Modal.DropZoneText}</p>
        <FontAwesomeIcon icon={faFileImport} className='importIcon' />
      </div>
      <div className='inputContainer'>
        <Input
          withClearIcon
          placeholder={TranslationManager.getTranslation().Import_Modal.InputPlaceHolder}
          value={fileName}
          onChange={(e) => setFileName(e.target.value)}
          required
          fullWidth
          disabled={fileContent === null}
        />
        <CustomButton
          text={TranslationManager.getTranslation().Buttons.Save}
          handleClose={() => onFileUpload(fileName, fileContent)}
          className={'KM_modalButton'} />
      </div>
    </div>
  );
}

export default FileUpload

export function parseContentItems(content: string | ArrayBuffer | null | undefined): ContentItem[] | null {
  if (content === null || content === undefined) {
    return null;
  }

  let jsonString: string;

  if (content instanceof ArrayBuffer) {
    jsonString = new TextDecoder().decode(content);
  } else {
    jsonString = content;
  }

  let parsed: any;

  try {
    parsed = JSON.parse(jsonString);
  } catch (e) {
    console.error('Failed to parse JSON:', e);
    return null;
  }

  // Check if parsed is an array
  if (!Array.isArray(parsed)) {
    console.error('Parsed JSON is not an array');
    return null;
  }

  // Check each item in the array
  for (const item of parsed) {
    if (typeof item.type !== 'string' || typeof item.isClosable !== 'boolean' || typeof item.reorderEnabled !== 'boolean') {
      console.error('Item in parsed array does not match ContentItem type');
      return null;
    }
  }

  return parsed as ContentItem[];
}