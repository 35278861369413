import * as React from "react";
import { TranslationManager } from "../../Translation/Translation"; // Update the path based on your project structure
import "./SettingsModal.scss";
import { Autocomplete, CircularProgress, Dialog, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import cx from 'classnames';
import { LMDInterface } from '../../KodiInterface/LMD';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faChevronDown, faTrash, faCirclePlus } from "@fortawesome/pro-solid-svg-icons";
import { ListInfo, TradeableInfo } from "../../Types/LMDTypes";
import { useRef, useState } from "react";
import { MUITable } from "../Tables/MUIProTable";
import { ColumnInfo } from "../../Types/MUITable";
import Button from "../UI-Elements/Button";
import { GridEventListener, GridRowOrderChangeParams, GridRowSelectionModel } from "@mui/x-data-grid-premium";
import InputModal from "./InputModal";
import ClearAllButton from "../UI-Elements/ClearAllButton";


const WatchListModal: React.FC<{ closeModal: () => void, open: boolean }> = ({
    closeModal, open
}) => {

    const [lists, setLists] = useState<ListInfo[]>([]);
    const [selectedList, setSelectedList] = useState<ListInfo>({ symbol: '', name: '', country: '', count: 0 });
    const [list, setList] = useState<TradeableInfo[]>([]);
    const [allTradeablesList, setallTradeablesList] = React.useState<TradeableInfo[]>([]);
    const [selectedTradable, setSelectedTradable] = React.useState<TradeableInfo | undefined>({ Symbol: '', Name: '', ExchangeSymbol: '', SecurityType: '' } as TradeableInfo);
    const [upperRowSelectionModel, setUpperRowSelectionModel] = React.useState<GridRowSelectionModel>([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isNewListModalOpen, setIsNewListModalOpen] = useState(false);
    const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
    const [isAddDisabled, setIsAddDisabled] = useState(true);

    const prevSelectedRef = useRef<string | undefined>();
    const selectedTradableRef = useRef<TradeableInfo | undefined>(selectedTradable);
    const listRef = useRef<TradeableInfo[]>(list);
    const allTradeablesListRef = useRef<TradeableInfo[]>(allTradeablesList);

    const WatchlistColumnInfo: ColumnInfo[] = [
        { name: "__reorder__", width: 25, minWidth: 20 },
        { name: "Symbol", defaultHidden: false, dataType: 'string', alignment: 'left', resizing: false, notHidable: true, columnMenu: false, width: 80 },
        { name: "Name", defaultHidden: false, dataType: 'string', alignment: 'left', resizing: false, notHidable: true, columnMenu: false, width: 205 },
        { name: "ExchangeSymbol", defaultHidden: false, dataType: 'string', alignment: 'left', resizing: false, notHidable: true, columnMenu: false, width: 140 },
        { name: "SecurityType", defaultHidden: false, dataType: 'string', alignment: 'left', resizing: false, notHidable: true, columnMenu: false, width: 130 },
        {
            name: "", defaultHidden: false, alignment: 'center', resizing: false, notHidable: true, columnMenu: false, width: 25, minWidth: 20, renderCell: (params) => {
                return (
                    <div style={{ fontSize: '12px', color: '#2235df', cursor: 'pointer' }} onClick={(e) => { removeTradable(params.row) }}>{<FontAwesomeIcon icon={faTrash} />}</div>
                );
            }
        },
    ];

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            addTradable();
        }
    };

    React.useEffect(() => {
        const fetchInitialData = async () => {
            const fetchedLists = await LMDInterface.getMyLists();
            const fetchedallTradeablesList = await LMDInterface.getAllTradeableInfo();
            setLists(fetchedLists.exchanges[0].lists as ListInfo[]);
            setallTradeablesList(fetchedallTradeablesList);
        };
        if (open) {
            fetchInitialData().then(() => setIsLoading(false));
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
            saveList();
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open]);

    React.useEffect(() => {
        if (lists.length === 0) {
            setSelectedList({ symbol: '', name: '', country: '', count: 0 });
            setList([]);
        } else {
            saveList();
            setSelectedList(lists[0]);
        }
    }, [lists]);

    React.useEffect(() => {
        const fetchListSymbols = async () => {
            if (selectedList && selectedList.symbol !== '') {
                const fetchedList = await LMDInterface.getMyListSymbols(selectedList.symbol);
                if (prevSelectedRef.current !== selectedList.symbol || fetchedList.length > 0) {
                    setList(fetchedList);
                }
                prevSelectedRef.current = selectedList.symbol;
            }
        };

        fetchListSymbols();
    }, [selectedList]);

    React.useEffect(() => {
        if (selectedTradable) {
            selectedTradableRef.current = selectedTradable;
        }
        const foundTradable = list.find((tradable) => selectedTradable && selectedTradable.Symbol === tradable.Symbol);
        if (selectedTradable && selectedTradable.Symbol !== '' && !foundTradable) {
            setIsAddDisabled(false);
        } else if (!selectedTradable || selectedTradable.Symbol === '' || foundTradable) {
            setIsAddDisabled(true);
        }
    }, [list, selectedTradable]);

    React.useEffect(() => {
        listRef.current = list;
    }, [list]);

    React.useEffect(() => {
        allTradeablesListRef.current = allTradeablesList;
    }, [allTradeablesList]);

    const handleOpenNewListModal = () => {
        setIsNewListModalOpen(true);
    };

    const handleCloseNewListModal = () => {
        setIsNewListModalOpen(false);
    };

    const handleOpenRenameModal = () => {
        setIsRenameModalOpen(true);
    };

    const handleCloseRenameModal = () => {
        setIsRenameModalOpen(false);
    };

    const handleNewList = (value: string) => {
        setLists([{ symbol: value, name: value, country: '', count: 0 }, ...lists]);
    };

    const handleRenameList = async (value: string) => {
        const newLists = lists.filter(list => list.symbol !== selectedList.symbol);
        await LMDInterface.saveMyList(value, list.map(tradable => tradable.IdCode));
        await LMDInterface.deleteMyList(selectedList.symbol);
        setList([]);
        setLists([{ symbol: value, name: value, country: '', count: list.length }, ...newLists]);
    };

    const onRowDoubleClick: GridEventListener<'rowDoubleClick'> = (params) => {
        const row = params.row;
        const symbol = row.Symbol;
        setList(list.filter(tradable => tradable.Symbol !== symbol));
        setSelectedTradable({ Symbol: '', Name: '', ExchangeSymbol: '', SecurityType: '' } as TradeableInfo);
        setUpperRowSelectionModel([]);
    };

    const onRowOrderChange = (params: GridRowOrderChangeParams) => {
        const newList = [...list];
        const toBeMoved = newList.splice(params.oldIndex, 1)[0];
        newList.splice(params.targetIndex, 0, toBeMoved);
        setList(newList);
    }

    const onUpperRowSelectionChange = (params: GridRowSelectionModel) => {
        setUpperRowSelectionModel(params);
    }

    const addTradable = () => {
        if (!selectedTradableRef.current && !selectedTradable) return;
        if ((selectedTradable && selectedTradable.Symbol !== '') || (selectedTradableRef.current && selectedTradableRef.current.Symbol !== '')) {
            if (listRef.current.find(tradable => tradable.Symbol === selectedTradableRef.current?.Symbol)) return;
            setList(listRef.current.concat([selectedTradableRef.current as TradeableInfo]));
            setSelectedTradable({ Symbol: '', Name: '', ExchangeSymbol: '', SecurityType: '' } as TradeableInfo);
        }
        setUpperRowSelectionModel([]);
    }

    const removeTradable = (row?: any) => {
        if (!row) return;
        setList(list.filter(tradable => tradable.Symbol !== row.Symbol));
        setSelectedTradable({ Symbol: '', Name: '', ExchangeSymbol: '', SecurityType: '' } as TradeableInfo);
        setUpperRowSelectionModel([]);
    }

    const removeList = async () => {
        if (!selectedList) return;
        await LMDInterface.deleteMyList(selectedList.symbol);
        setList([]);
        setLists(lists.filter(list => list.symbol !== selectedList.symbol));
    }

    const clearList = () => {
        if (!selectedList) return;
        setList([]);
    }

    const saveList = async () => {
        if (!selectedList) return;
        LMDInterface.saveMyList(selectedList.symbol, list.map(tradable => tradable.IdCode));
    }

    return (
        <Dialog
            onClose={() => { closeModal() }}
            open={open}
        >
            <div
                className="KM_modal"
                style={{ height: lists.length > 0 ? '480px' : undefined, width: '695px', padding: '40px' }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start"
                    }}
                >
                    <h1 style={{ marginBottom: 0 }} className="modal_title">
                        {TranslationManager.getTranslation().WatchList.MyLists.Header}
                    </h1>
                    {/* close ICone */}
                    <FontAwesomeIcon
                        className="modalCloseButton"
                        onClick={() => closeModal()}
                        icon={faXmark}
                    />
                </div>
                {
                    isLoading
                        ? <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '290px'
                        }}><CircularProgress size={80} /></div>
                        : <>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: '12px'
                            }}>
                                <div style={{ display: 'flex', alignItems: 'center', fontStyle: 'italic' }}>
                                    <span>{lists.length > 0 ? TranslationManager.getTranslation().WatchList.MyLists.ListsText : TranslationManager.getTranslation().WatchList.MyLists.NoListsText}</span>
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button size='sm' onClick={handleOpenNewListModal}>{TranslationManager.getTranslation().WatchList.MyLists.NewList}</Button>
                                    <InputModal open={isNewListModalOpen} onClose={handleCloseNewListModal} onSave={handleNewList} headerTitle={TranslationManager.getTranslation().WatchList.MyLists.NewListTitle} />
                                </div>
                            </div>
                            {lists.length > 0 && <div>
                                <div style={{ display: 'grid', gridTemplateColumns: '15fr 1fr' }}>
                                    <div style={{ display: 'grid', gridTemplateRows: '7fr 10fr' }}>
                                        <div style={{ display: 'grid', gridTemplateColumns: '5fr 1fr', alignItems: 'center' }}>
                                            <ListAutocomplete
                                                lists={lists}
                                                value={selectedList}
                                                onChange={(listInfo) => {
                                                    saveList();
                                                    setSelectedList(listInfo);
                                                }}
                                            />
                                            <div style={{ display: 'flex', flexFlow: 'row' }}>
                                                <Button buttonType='secondary' style={{ lineHeight: '15px', marginLeft: '10px', marginRight: '5px' }} size='sm' disabled={selectedList.symbol === ''} onClick={handleOpenRenameModal}>{TranslationManager.getTranslation().WatchList.MyLists.RenameList}</Button>
                                                <ClearAllButton onHeld={clearList} style={{ display: 'flex', justifyContent: 'center', lineHeight: '15px', marginLeft: '5px', marginRight: '6px', color: 'var(--blue-600)' }}><span style={{ zIndex: 1 }}>{TranslationManager.getTranslation().WatchList.MyLists.ClearList}</span></ClearAllButton>
                                                <InputModal open={isRenameModalOpen} onClose={handleCloseRenameModal} onSave={handleRenameList} headerTitle={TranslationManager.getTranslation().WatchList.MyLists.RenameListTitle} />
                                            </div>
                                        </div>
                                        <div>
                                            <TradeableInfoAutocomplete
                                                tradeables={allTradeablesList}
                                                value={selectedTradable}
                                                onChange={(tradeableInfo) => {
                                                    setSelectedTradable(tradeableInfo);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: 'grid', gridTemplateRows: '7fr 10fr', justifyContent: 'right' }}>
                                        <ClearAllButton onHeld={removeList} style={{ display: 'flex', justifyContent: 'center', width: '30px', height: '28.58px', lineHeight: '15px', fontSize: '14px', marginLeft: '4px' }}><FontAwesomeIcon style={{ zIndex: 1, color: 'var(--blue-600)' }} icon={faTrash} /></ClearAllButton>
                                        <Button buttonType='secondary' style={{ display: 'flex', justifyContent: 'center', width: '30px', lineHeight: '15px', fontSize: '14px', marginTop: '9px' }} size='sm' disabled={isAddDisabled || selectedList.symbol === ''} onClick={() => addTradable()}><FontAwesomeIcon icon={faCirclePlus} /></Button>
                                    </div>
                                </div>
                                <div
                                    style={{ height: '200px', marginTop: '12px' }}>
                                    <MUITable
                                        rows={list?.map(tradable => { return { ...tradable, id: tradable.Symbol } })}
                                        columns={WatchlistColumnInfo}
                                        cell={(column, row) => formatCell(column, row)}
                                        newLineFlicker={false}
                                        tableState={undefined}
                                        saveState={() => { }}
                                        loading={false}
                                        onRowDoubleClick={onRowDoubleClick}
                                        rowReordering={true}
                                        onRowOrderChange={onRowOrderChange}
                                        rowsSelectable={true}
                                        onRowSelection={onUpperRowSelectionChange}
                                        rowSelectionModel={upperRowSelectionModel}
                                    />
                                </div>
                            </div>}
                        </>
                }
            </div>
        </Dialog >
    );
};

function formatCell(column, row: TradeableInfo): React.JSX.Element {
    let value = row[column];
    const style = {};
    if (typeof value === 'string') style['justifyContent'] = 'flex-start';
    return <div className="center-container fill" style={style}>{value}</div>;
}

function TradeableInfoAutocomplete({ tradeables, value, onChange }: { tradeables: TradeableInfo[], value: TradeableInfo | undefined, onChange: (tradable: TradeableInfo) => void }) {
    function tradeableToItem(tradeable: TradeableInfo): TradeableInfo & { label: string } {
        return { ...tradeable, label: tradeable.Symbol || '' }
    }
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <Autocomplete
            autoHighlight
            popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
            options={tradeables.map(tradeableToItem)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                padding: 0,
                paddingRight: '6px',
                paddingTop: '16px',
                height: '30px',
                '& input': {
                    height: 10,
                    fontSize: 14
                },
            }}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            getOptionLabel={(option) => option.Symbol !== '' ? option.Name + ', ' + option.Symbol + ', ' + option.ExchangeSymbol : ''}
            renderOption={(props, option) => {
                if (typeof option === 'string')
                    return <Box component="li" {...props}
                        sx={{
                            color: 'var(--dark-900, #232530)',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >{option}</Box>
                else return (
                    <Box component="li" {...props} sx={{
                        color: 'var(--dark-900, #232530)',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }} >
                        <div style={{
                            maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                        }}>{option.Name}, {option.Symbol}, {option.ExchangeSymbol}
                        </div>
                    </Box >);
            }}
            renderInput={(params) => <TextField
                label={TranslationManager.getTranslation().WatchList.MyLists.SecuritiesLabel}
                {...params}
                size="medium"
                className={cx('autocomplete-custom', { 'isOpen': isOpen })}
                sx={{
                    height: '28.58px',
                }}
            />
            }
            disableClearable
            value={value}
            size='small'
            isOptionEqualToValue={(a, b) => a.Symbol === b.Symbol}

            onChange={(event: any, newValue: any) => onChange(newValue as TradeableInfo)}
        />
    );
}

function ListAutocomplete({ lists, value, onChange }: { lists: ListInfo[], value: ListInfo | undefined, onChange: (list: ListInfo) => void }) {
    function listInfoToItem(listInfo: ListInfo): ListInfo & { label: string } {
        return { ...listInfo, label: listInfo.name || '' }
    }
    const [isOpen, setIsOpen] = React.useState(false);

    return (
        <Autocomplete
            popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
            options={lists.map(listInfoToItem)}
            sx={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '6px',
                width: '100%', padding: 0,
                height: '30px',
                '& input': {
                    width: 200,
                    fontSize: '14px'
                }
            }}
            renderInput={(params) => <TextField {...params}
                label={TranslationManager.getTranslation().WatchList.MyLists.ListsLabel}
                size='medium'
                className={cx('autocomplete-custom', { 'isOpen': isOpen })}
                sx={{
                    padding: '0px',
                    // height: '28.58px',
                }}
                inputProps={{
                    ...params.inputProps,
                }}
            />
            }
            renderOption={(props, option) => (
                <Box component="li" {...props} sx={{
                    color: 'var(--dark-900, #232530)',
                    fontSize: '11px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: 'normal',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }} >
                    <div style={{
                        maxWidth: '85%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'
                    }}>{option.name}
                    </div>
                    <div style={{
                        paddingLeft: 0,

                    }}>&nbsp;({option.count})</div>
                </Box >
            )}
            onOpen={() => {
                setIsOpen(true);
            }}
            onClose={() => {
                setIsOpen(false);
            }}
            getOptionLabel={(option) => typeof option !== 'string' ? option.name : option}
            disableClearable
            value={value}
            size='small'
            isOptionEqualToValue={(a, b) => a.symbol === b.symbol}
            onChange={(event: any, newValue: any) => onChange(newValue as ListInfo)}
        />);
}

export default WatchListModal;