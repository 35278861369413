import { TickSize } from "../KodiInterface/LMD";
import { TranslationManager } from "../Translation/Translation";
import { isNullOrUndefined } from "./Common";

export function getClosestTick(price: number, tickSizeTable: TickSize[]) {
  const defaultTick = 0.0001;
  // In case we don't have a table to work with, we just assign a 1 as the tick
  if (isNullOrUndefined(tickSizeTable) || tickSizeTable.length === 0) {
    return defaultTick;
  }
  // sort tradableTickSizeTable by PriceTo
  tickSizeTable.sort((a, b) => a.PriceTo - b.PriceTo);
  // Find tick sizes ranges that are either smaller than price or correct and then take the last one
  const filteredTickSizes = tickSizeTable.filter(
    t => t.PriceTo <= price || (t.PriceFrom <= price && t.PriceTo >= price)
  );
  if (filteredTickSizes.length > 0) {
    return filteredTickSizes[filteredTickSizes.length - 1].TickSize;
  } else {
    return defaultTick;
  }
}


export function roundPriceToTick(price: number, tick: number) {
  // Check to see if the current price is valid
  if (isValidPrice(price, tick)) {
    return price;
  }
  // Check the next valid price number according to tick
  const next = incrementByTick(price, tick);
  // Check the previous valid price number according to tick
  const prev = decrementByTick(price, tick);

  // Check to see if the next number is closer to input number
  if (next - price < price - prev) {
    // Return next number
    return next;
  } else {
    // Otherwise the previous number was closer and we return that one
    return prev;
  }
}
function incrementByTick(primary: number, increments: number) {
  // Calculate how much we need to scale our numbers in order to prevent working with floating numbers
  const scalar = getScalar(primary, increments);
  // Scale our numbers up to avoid working with floating numbers since they give inaccurate results
  // We also add an extra increments to see the next valid primary
  const sData = Math.round((primary + increments) * scalar);
  const sIncrement = Math.round(increments * scalar);
  // Calculate if there is any difference (if we need to lower the number in order to allow it according to increments rules)
  const difference = Math.round(sData % sIncrement);
  // Calculate the correct number to show and then scale the number back down
  return (sData - difference) / scalar;
}
function decrementByTick(primary: number, decrements: number) {
  // Calculate how much we need to scale our numbers in order to prevent working with floating numbers
  const scalar = getScalar(primary, decrements);
  // Scale our numbers up to avoid working with floating numbers since they give inaccurate results
  const sData = Math.round(primary * scalar);
  const sDecrements = Math.round(decrements * scalar);
  // Calculate if there is any difference (if we need to lower the number in order to allow it according to decrements rules)
  const difference = Math.round(sData % sDecrements);
  // Calculate the correct number to show and then scale the number back down
  return (sData - difference) / scalar;
}
function isValidPrice(price: number, tick: number) {
  // Calculate how much we need to scale our numbers in order to prevent working with floating numbers
  const scalar = getScalar(price, tick);
  // Scale our numbers up to avoid working with floating numbers since they give inaccurate results
  const sData = Math.round(price * scalar);
  const sTickSize = Math.round(tick * scalar);
  // Calculate if there is any difference
  const difference = Math.round(sData % sTickSize);
  // console.log(`Data: `, sData);
  // console.log(`sTick: `, sTickSize);
  // console.log(`Difference: `, difference);
  // If there is no difference the price is valid
  if (difference === 0) {
    return true;
  }

  return false;
}
function getScalar(primary: number, secondary?: number) {
  let precision = getPrecision(primary);
  if (secondary) {
    const secondaryPrecision = getPrecision(secondary);
    // If secondaryPrecision is larger than primary precision we use that one
    if (secondaryPrecision > precision) {
      precision = secondaryPrecision;
    }
  }
  // Calculate how much we need to scale our numbers up by
  let result = Math.pow(0.1, precision);
  // If precision is 0 it means we don't have a decimal number and then we just return that
  if (precision === 0) {
    return result;
  } else {
    // We need to add toPrecision here in order to shave extra numbers
    // JavaScript sometimes adds when calculating with decimal numbers
    // since JavaScript isn't proficient in it.
    // But we know what our precision should be since we calculated it above
    result = Number(result.toPrecision(precision));
    const scalar = Math.round(1 / result);
    return scalar;
  }
}
function getPrecision(input: number) {
  // Convert number to string
  const numberInput = input.toString();
  // Get Decimal position of number
  const position = numberInput.indexOf('.');
  // Calculate precision of number
  let precision = position === -1 ? 0 : numberInput.length - position - 1;
  // Check if number is interpreted as scientific notation
  if (precision === 0 && numberInput.indexOf('e') !== -1) {
    const numberScientificNotation = numberInput.split('e')[1];
    const newPrecision = Math.abs(Number(numberScientificNotation));
    // Update precision if scientific notation is present
    if (Number.isFinite(newPrecision)) {
      precision = newPrecision;
    }
  }

  return precision;
}

export const PDSErrorHandler = (error: string) => {
  if (error === "ERROR, rejected: Replace changes nothing") {
    return TranslationManager.getTranslation().PDSErrors.ReplaceChangesNothing;
  } else {
    return error;
  }
}
