import { MessageType, PriceInfo, SymbolMessageType } from "../../../Types/Websocket";
import { SymbolMessageHandler } from "./AbstractHandler";


export class PriceInfoHandler extends SymbolMessageHandler<PriceInfo, PriceInfo | undefined> {
    messageType: SymbolMessageType = MessageType.price_info;
    initialValue(symbol: string): PriceInfo | undefined {
        return undefined;
    }

    messageSymbol(message: PriceInfo): string {
        return message.symbol;
    }

    handleSymbolsMessage(symbol: string, message: PriceInfo): void {
        /*
        Cannot set properties of undefined (setting 'symbol')
        TypeError: Cannot set properties of undefined (setting 'symbol')
        */
        if (message.initial) this.symbolHandlerDetails[symbol].symbolInfo = message;
        else Object.keys(message).forEach(key => (this.symbolHandlerDetails[symbol].symbolInfo as PriceInfo)[key] = message[key]);

        this.pushUpdate(symbol);
    }
}