import { Config } from "../Config/Config";
import { DoubleCheckOrderKeysToDisplay } from "../Types/private-data/NewOrderSubmitRequest";
import { OrderbookState } from "../Types/Websocket";
import { ComponentName } from "../Types/Windows";
import { EnglishTranslation } from "./English";
import { IcelandicTranslation } from "./Icelandic";

export type SupportedLanguage = "IS" | "EN";

export type Translation = {
  Columns: Record<string, string>;
  OrderbookState: Record<OrderbookState, string>;
  ComponentName: Record<ComponentName, string>;
  NewOrderModal_DoubleCheck: Record<DoubleCheckOrderKeysToDisplay, string>;
  Page: {
    Title: string;
  };
  Basic: {
    Buy: string;
    Sell: string;
    Fill: string;
  },
  Buttons: {
    MarketData: string;
    Workspace: string;
    Trading: string;
    Reset: string;
    Load: string;
    Save: string;
    SaveAs: string;
    Settings: string;
    Logout: string;
    Language: string;
    About: string;
    Import: string;
    Export: string;
    Ok: string;
    Cancel: string;
    Confirm_order: string;
    Reset_form: string;
    Back: string,
    Reconnect: string,
    Hotkeys: string,
  };
  Hotkeys: {
    GlobalHeader: string,
    NavigateHeader: string,
    NewOrderModal: string,
    ResetWorkspace: string,
    MarketDepth: string,
    TradeTicker: string,
    WatchList: string,
    Chart: string,
    News: string,
    PriceLadder: string,
    MyOrders: string,
    MyTrades: string,

    //navigational hotkeys
    Left: string,
    Right: string,
    Up: string,
    Down: string,
  }
  Errors: {
    NoSubscriptionFound: string;
  };
  PDSErrors: {
    ReplaceChangesNothing: string;
  },
  ToastMessage: {
    CancelOrder: {
      pending: string;
      success: string;
      error: string;
    },
    NewOrder: {
      pending: string;
      success: string;
      error: string;
    },
  }
  Header: {
    Market: React.JSX.Element;
    Menu: {
      DashboardMenu: {
        Header: string;
        MarketDepth: string;
        TradeTicker: string;
        WatchList: string;
        Chart: string;
        News: string;
      };
      WorkspaceMenu: {
        Header: string;
        ResetWorkspace: string;
        OpenWorkspace: string;
        LoadWorkspace: string;
        SaveWorkspace: string;
        ExportWorkspace: string;
      };
    };
    Settings: {
      Language: string;
    };
  };
  WatchList: {
    RightClickTableMenu: {
      NewOrder: string;
      OrderbookProperties: string;
      MarketDepth: string;
      Chart: string;
    };
    MyLists: {
      Header: string;
      NewList: string;
      DeleteList: string;
      RenameList: string;
      ClearList: string;
      AddSymbol: string;
      NewListLabel: string;
      SaveList: string;
      ListsText: string;
      NoListsText: string;
      ListsLabel: string;
      SecuritiesLabel: string;
      NewListTitle: string;
      RenameListTitle: string;
    };
  };
  MarketDepth: {
    total: string;
  };
  ViewOrders: {
    RightClickTableMenu: {
      Details: string;
      Cancel: string;
      Modify: string;
    };
  };
  Titles: {
    SaveWorkspace: string;
    LoadWorkspace: string;
    ImportWorkspace: string;
    ExportWorkspace: string;
    NewOrderTitle: string;
    ModifyOrderTitle: string;
  };
  Login_Modal: {
    Title: string;
    Sub_title: string;
    Username: string;
    Password: string;
    Login: string;
    Remember_me: string;
    New_password: string;
  };
  New_Password: {
    Send_new_password: string,
    Affected_systems: string
  };
  About_Modal: {
    Title: string;
    CloseButton: string;
    Version: string;
  };
  OrderBook_Modal: {
    Orderbook_title: string;
    Name: string;
    Short_Name: string;
    ISIN: string;
    Currency: string;
    Country: string;
    Security_Type: string;
    SecurityType_share: string;
    SecurityType_bond: string;

    ToggleButtons: {
      General: string;
      Share_Info: string;
      Market_Info: string;
      Tick_Sizes: string;
      Trading_Scheme: string;
      Supplementary: string;
    };
  };
  Import_Modal: {
    DropZoneText: string;
    InputPlaceHolder: string;
  };
  Save_Modal: {
    InputPlaceHolder: string;
  };
  Search: string;
  Trading: {
    NewOrder: string;
    ViewOrders: string;
    ViewTrades: string;
    BulkOrderShift: string;
  };
  Settings_Modal: {
    Title: string;
    CloseButton: string;
    Username: string;
    Auth_url: string;
    Market_data_connections: string;
    PDS_connections: string;
    Api_url: string;
    Kodiak_web: string;
    Subscriptions: string;
    Display_time_zone: string;
    Delete_cache: string;

    //table
    Active: string;
    Url: string;
    //subscriptiontable
    Name: string;
    Description: string;
  };
  New_Order_Modal: {
    Success_Message: string;
    Update_Message: string;
    Error_Message: string;
    Dropdown_options: {
      Buy: string;
      Sell: string;

    },
    Labels: {
      Buy_sell: string;
      Quantity: string;
      Autocomplete_symbol: string;
      Price: string;
      Order_type: string;
      Visible_quantity: string;
      Hidden: string;
      Time_in_force: string;
      Min_quantity: string;
      Broker: string;
      Portfolio: string;
      Account: string;
    },
    Total: string;
    Symbol_info: {
      Last: string;
      Bid: string;
      Ask: string;
    }


  };
  News: {
    Sources: {
      Source_search: string;
      Toggle_all: string;
      Header: string;
    };
    Category_DropDown: {
      Business: string;
      General: string;
      Sports: string;
      Fishing_industry: string;
      Podcast: string;
    };
  };
  Chart: {
    Volume: string;
    Price: string;

    Start: string;
    End: string;
  };
};

export class TranslationManager {
  private static translations: Record<SupportedLanguage, Translation> = {
    EN: EnglishTranslation,
    IS: IcelandicTranslation,
  };

  private static activeLanguage: SupportedLanguage = (() => {
    let cookieStr = localStorage.getItem(Config.LANGUAGE_COOKIE);
    if (cookieStr === null) localStorage.setItem(Config.LANGUAGE_COOKIE, "IS");
    return localStorage.getItem(Config.LANGUAGE_COOKIE) as SupportedLanguage;
  })();

  // Public
  static getActiveLanguage(): SupportedLanguage {
    return TranslationManager.activeLanguage;
  }
  static setActiveLanguage(lanugage: SupportedLanguage) {
    localStorage.setItem(Config.LANGUAGE_COOKIE, lanugage);
    TranslationManager.activeLanguage = lanugage;
    window.location.reload();
  }

  static getTranslation(): Translation {
    return TranslationManager.translations[TranslationManager.activeLanguage];
  }
}
