// Package imports:
import React, { useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';
// Component imports:
import NewsItem from './NewsItem';
// Service imports:
import { usePageCounter, useStateRef, getNumberOfPages } from '../../../Utils/Hooks';
import { sortIcelandic, updateDoubleMapValue } from '../../../Utils/Common';
import { getSourceListFromSourceString, getSourceStringFromSourceList, getNewsDateOrTime } from '../../../Utils/NewsUtils';
// Type imports:
import { INewsLmdNewsItem, INewsLmdResponse, INewsFeedCategoryDetails, INewsFeedSourceFilter, INewsFeedSourceDetails, NewsItemFeedIdMapToBoolean, SearchInputToSourceFilterToResultsDoubleMap, SearchResultsInfo, SourceFilterToResultsMap } from '../../../Types/NewsType';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import Filter from './Filter';
import { Fetched } from '../../../Types/Common';
import { CategoryKeys } from './News';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

const INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH = 20;
const NEWS_ITEMS_FETCH_ON_INDEX_CHANGE = 100;

const MINIMUM_LETTER_SEARCH = 3;

type UsingSourceFilter = 'all' | 'some' | 'none'

interface IOwnProps {
    // setHasNew(hasNewNewsItems: boolean): void,
    newsFeedCategoryDetails: INewsFeedCategoryDetails,

}
export type SaveSourceState = {
    [K in CategoryKeys]: INewsFeedSourceFilter[];
};
type Props = IOwnProps & {
    refreshRateMs: number,
    searchInput: string,
    saveSourceState: (state: INewsFeedSourceFilter[]) => void,
    initialSources: SaveSourceState | undefined,
};

/**
 * HINT: Use ctrl+shift+p: > "Fold Level 2", if you're starting out here.
 *
 * At first the initial news batch is fetched.
 * The newsitems that are displayed for the user are stored in the defaultNewsItems state variable.
 * Then, every [refreshRateMs] milliseconds, More news are fetched.
 * - Since we don't know how many new newsItems have been added, we start by fetching [INTIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH].
 * - Then we double this number until the new news batch includes the first newsitem of the already-fetched news.
 * The new news should only be added to the defaultNewsItems if the user is on the first page.
 * - If the user is scrolling through old news, we don't want the list to shift around due to the new arrivals.
 * - If the user is on an old page, the news news items are added to the state variable newNewsWaitingQueue.
 * When the user is going through old news, we requested by searching from the timestamp of the oldest fetched news (last in the defaultNewsItems array)
 * - We fetch [NEWS_ITEMS_FETCH_ON_INDEX_CHANGE] of news and append it to the end of defaultNewsItems.
 *
 *
 * Additionally we have maps mapping the newsId to boolean, for if any given news item is "new" or "read".
 * - Default is: not new, and not read.
 *
 * Since the state can be confusing, all state variables have been commented with their purpose.
 */
const NewsContainer: React.FC<Props> = ({
    // setHasNew,
    newsFeedCategoryDetails,
    refreshRateMs,
    searchInput = "",
    saveSourceState,
    initialSources
}) => {
    /* News Storage States: */
    // The list of "default" news items that the user can see.
    // "Default" meaning non-filtered, non-searched news. Simply the most recent news.
    const [defaultNewsItems, setDefaultNewsItems] = useState<INewsLmdNewsItem[] | null>(null);
    // When the user is:
    // - not filtering by source AND
    // - not searching by word AND
    // - on page 1.
    // Then new news should automatically be added to defaultNewsItems.
    // HOWEVER,
    // When the user does NOT meet those conditions, we must store the new news items in the newNewsWaitingQueue.
    // This list gets added to the front of defaultNewsItems when the user meets ALL coniditions again.
    const [defaultNewsItemsWaitingQueue, setDefaultNewsItemsWaitingQueue] = useState<INewsLmdNewsItem[]>([]);
    // Search word results map:
    // When user filters by source only, and not by word:
    // - then the first key (the word) is "" (the empty string).
    // When the user filters by word only, and not by source:
    // -> then the second key (sourceFilterString) is "" (the empty string).
    // When the user filters by both, then use both keys: searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
    const [searchResultsDoubleMap, setSearchResultsDoubleMap] = useState<SearchInputToSourceFilterToResultsDoubleMap>({});

    /* News Filtering States: */
    // Sources used to filter by.
    const [sourceFilters, setSourceFilters] = useState<Fetched<INewsFeedSourceFilter[]>>(null);
    // Search word
    // const [searchInput, setSearchInput] = useState('');

    /* News to Boolean maps: */
    // IsSeen news = news that has been clicked on/opened
    const [newsItemFeedIdToIsSeenMap, setNewsItemFeedIdToIsSeenMap] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsNew refers to the yellow "bolla" next to the news item.
    const [newsItemFeedIdToIsNewMap, setNewsItemFeedIdToIsNewMap] = useState<NewsItemFeedIdMapToBoolean>({});
    // IsHighlighted refers to the yellow highlighted hue that very new news has.
    const [newsItemFeedIdToIsHighlightedMap, setNewsItemFeedIdToIsHighlightedMap] = useState<NewsItemFeedIdMapToBoolean>({});

    /* Display helper states: */
    const [lastDisplayableData, setLastDisplayableData] = useState<INewsLmdNewsItem[] | null>(null);

    const [ITEMS_TO_DISPLAY_PER_PAGE, setQuantityPerPage] = React.useState<number>(50)

    const getSliceOfDataGivenPageIndex = (index: number, dataArray: Fetched<INewsLmdNewsItem[]> = defaultNewsItems) => {
        if (dataArray === null || dataArray instanceof Error) return {
            dataSlice: [],
            isSliceFullLength: false,
        }
        const dataSlice = dataArray.slice(index * ITEMS_TO_DISPLAY_PER_PAGE, (index + 1) * ITEMS_TO_DISPLAY_PER_PAGE);
        return {
            dataSlice,
            isSliceFullLength: (dataSlice.length === ITEMS_TO_DISPLAY_PER_PAGE)
        }
    }

    /* Overhead News States: */
    // Page index variables.
    const {
        currentPageIndex,
        totalPages: totalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        goToNextPage,
        goToPreviousPage,
        setTotalPages: setTotalPagesForDefault, // Renamed. When searching, the total pages are fetched from the Double Map.
        resetPageCounter
    } = usePageCounter();
    // Any error if it would occur
    const [newsError, setNewsError] = useState<Error | null>(null);

    /* MEMO */
    const usingSourceFilter: UsingSourceFilter = useMemo(() => {
        if (sourceFilters === null || sourceFilters instanceof Error) return 'all';
        if (sourceFilters.every(sourceFilter => !sourceFilter.isOn)) return 'none';
        if (sourceFilters.every(sourceFilter => sourceFilter.isOn)) return 'all';
        return 'some';
    }, [sourceFilters]);

    const usingSearchWord = useMemo(() => {
        return searchInput.length > MINIMUM_LETTER_SEARCH
    }, [searchInput]);

    const usingDefaultNews = useMemo(() => {
        return (!usingSearchWord && usingSourceFilter === 'all');
    }, [usingSearchWord, usingSourceFilter]);

    const { dataToDisplay, isCompleteSlice } = useMemo(() => {
        const loadingState = {
            dataToDisplay: null,
            isCompleteSlice: false
        }
        if (usingSourceFilter === 'none') return loadingState;
        // No Search.
        if (!usingSearchWord) {
            // No filter.
            if (usingSourceFilter === 'all') {
                if (defaultNewsItems === null) return loadingState;
                const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex);
                const isOnLastPage = ((currentPageIndex + 1) === totalPagesForDefault)
                return {
                    dataToDisplay: dataSlice,
                    isCompleteSlice: isSliceFullLength || isOnLastPage || totalPagesForDefault === 0
                }
            }
            // Filtering by source.
            else {
                const sourceFilterString = `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
                if (sourceFilterString === null) return loadingState;
                const searchResults = searchResultsDoubleMap?.['']?.[sourceFilterString];
                if (searchResults === undefined) return loadingState;

                const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex, searchResults.results);
                const numberOfPages = getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                const isOnLastPage = ((currentPageIndex + 1) === numberOfPages);
                return {
                    dataToDisplay: dataSlice,
                    isCompleteSlice: isSliceFullLength || isOnLastPage || (numberOfPages === 0)
                }
            }
        }
        // Searching.
        else {
            // No filter.
            if (usingSourceFilter === 'all') {
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[newsFeedCategoryDetails.category];
                if (searchResults === undefined) return loadingState;

                const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex, searchResults.results);
                const numberOfPages = getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                const isOnLastPage = ((currentPageIndex + 1) === numberOfPages);
                return {
                    dataToDisplay: dataSlice,
                    isCompleteSlice: isSliceFullLength || isOnLastPage || (numberOfPages === 0)
                }
            }
            // Filtering by source.
            else {
                const sourceFilterString = `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
                if (sourceFilterString === null) return loadingState;
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
                if (searchResults === undefined) return loadingState;

                const { dataSlice, isSliceFullLength } = getSliceOfDataGivenPageIndex(currentPageIndex, searchResults.results);
                const numberOfPages = getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                const isOnLastPage = ((currentPageIndex + 1) === numberOfPages);
                return {
                    dataToDisplay: dataSlice,
                    isCompleteSlice: isSliceFullLength || isOnLastPage || (numberOfPages === 0)
                }
            }
        }
    }, [sourceFilters, searchInput, searchResultsDoubleMap, defaultNewsItems, currentPageIndex, totalPagesForDefault, usingSearchWord]);

    const totalPages = useMemo(() => {
        const loadingState = '...';
        if (usingSourceFilter === 'none') return loadingState;

        if (usingDefaultNews) return totalPagesForDefault ?? loadingState;

        const sourceFilterString = (usingSourceFilter === 'all')
            ? `${newsFeedCategoryDetails.category}`
            : `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
        if (sourceFilterString === null) return loadingState;
        const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];
        if (searchResults === undefined) return loadingState;
        return getNumberOfPages(searchResults.totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
    }, [usingDefaultNews, searchResultsDoubleMap, searchInput, sourceFilters, totalPagesForDefault, usingSourceFilter, ITEMS_TO_DISPLAY_PER_PAGE]);

    /* REFS */
    // Interval functions can't read state correctly. Needs ref's instead.
    const defaultNewsItemsRef = useStateRef(defaultNewsItems)
    const defaultNewsItemsWaitingQueueRef = useStateRef(defaultNewsItemsWaitingQueue);
    const currentPageIndexRef = useStateRef(currentPageIndex);
    const newsItemFeedIdToIsNewMapRef = useStateRef(newsItemFeedIdToIsNewMap);
    const newsItemFeedIdToIsHighlightedMapRef = useStateRef(newsItemFeedIdToIsHighlightedMap);
    const usingDefaultNewsRef = useStateRef(usingDefaultNews);
    const searchResultsDoubleMapRef = useStateRef(searchResultsDoubleMap);
    const sourceFiltersRef = useStateRef(sourceFilters);
    const usingSearchWordRef = useStateRef(usingSearchWord);

    // Ref for timeout id.
    const timeoutFunctionIdRef = useRef<number | null>(null);

    /* EFFECTS AND FUNCTIONS */
    // Update lastDisplayableData to last viable data.
    useEffect(() => { if (dataToDisplay !== null) setLastDisplayableData(dataToDisplay) }, [dataToDisplay]);

    // Reset page counter on search input change
    useEffect(() => {
        if (currentPageIndex !== 0) resetPageCounter();
    }, [searchInput, sourceFilters]);

    // Update defaultNewsItems and newNewsWaitingQueue when the user returns to the default state.
    useEffect(() => {
        if (defaultNewsItemsWaitingQueue.length > 0 && usingDefaultNews && currentPageIndex === 0 && Array.isArray(defaultNewsItems)) {
            setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
            setDefaultNewsItemsWaitingQueue([]);
        }
    }, [usingDefaultNews, currentPageIndex])

    // Update boolean maps when opening news item.
    const newsItemOnOpen = (newsItem: INewsLmdNewsItem) => {
        const { id } = newsItem;
        if (id === null) return;
        // Set to "not new" if new
        setNewsItemFeedIdToIsNewMap({
            ...newsItemFeedIdToIsNewMap,
            [id]: false
        })
        setNewsItemFeedIdToIsHighlightedMap({
            ...newsItemFeedIdToIsHighlightedMap,
            [id]: false
        })
        // Set to seen if not seen
        setNewsItemFeedIdToIsSeenMap({
            ...newsItemFeedIdToIsSeenMap,
            [id]: true
        })
    }

    // Fetch helper function
    const fetchNews = async (
        numberOfNewsItemsToFetch: number,
        onSuccess: (newsItems: INewsLmdNewsItem[], totalCount: number) => void,
        onFail: (err: Error) => void,
        startPublishDate?: number,
        searchQuery?: string,
        sourceFilterString?: string
    ) => {
        // Fetch items
        let baseUrl = 'https://news-search.livemarketdata.com';
        const urlSearchParams = new URLSearchParams({
            start: '0',
            limit: numberOfNewsItemsToFetch.toString()
        });

        // Search query has special URL ending.
        if (searchQuery || sourceFilterString) {
            baseUrl += '/search/query';
            urlSearchParams.set('f', 'title;body');
            urlSearchParams.set('q', searchQuery?.toLowerCase() ?? '*');
            if (sourceFilterString) {
                urlSearchParams.set('filter', `symbol.ticker=${sourceFilterString}`);
            } else {
                urlSearchParams.set('filter', `topics=${newsFeedCategoryDetails.category}`);
            }
        }
        // If not search query, get the default url
        else {
            baseUrl += '/search/keldan';
            urlSearchParams.set('category', newsFeedCategoryDetails.category)
            if (startPublishDate) {
                urlSearchParams.set('startPublishDate', startPublishDate.toString())
            }
        }

        const url = `${baseUrl}?${urlSearchParams.toString()}`;

        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                if (res.ok) return res.json();
                else throw new Error('RequestFailed');
            })
            .then((resBody: INewsLmdResponse) => {
                if (resBody?.feedItems === null) throw new Error('Server did not return any news.');
                let removeFutureNews = resBody.feedItems.filter(x => x.publish_timestamp === null || x.publish_timestamp <= Date.now());
                onSuccess(removeFutureNews, resBody.totalCount);
            })
            .catch(err => {
                if (err instanceof Error) {
                    onFail(err);
                } else {
                    onFail(new Error('NetworkError'));
                }
            });
    }

    // Sources fetch.
    useEffect(() => {
        const sourcesFetch = async () => {
            try {
                // Fetch items
                const url = `${'https://news-search.livemarketdata.com'}/search/sources`;

                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    // ALL SOURCES AVAILABLE
                    const body: INewsFeedSourceDetails[] = await response.json();
                    // Filter body by category.
                    let sourcesForCategory = body.filter(r => {
                        return newsFeedCategoryDetails.category === r.category;
                    });

                    // If sourcesForCategory is empty, use all items from body with isOn as true
                    if (sourcesForCategory.length === 0) {
                        sourcesForCategory = body.map(item => ({ ...item, isOn: true }));
                    }

                    // Sort by name
                    sourcesForCategory.sort((source1, source2) => sortIcelandic(source1.fullSource, source2.fullSource));
                    // Retrieve the sources for the specific category from state
                    const categorySources = initialSources && initialSources[newsFeedCategoryDetails.category] || [];

                    const sourceFiltersForCategory: INewsFeedSourceFilter[] = sourcesForCategory.map(newsFeedSource => {
                        const existingSource = categorySources.find(
                            existingSource => existingSource.newsFeedSource.fullSource === newsFeedSource.fullSource
                        );

                        return {
                            newsFeedSource,
                            isOn: existingSource ? existingSource.isOn : true
                        };
                    });

                    // Set default settings from local storage.
                    saveSourceState(sourceFiltersForCategory)
                    setSourceFilters(sourceFiltersForCategory);

                } else {
                    setSourceFilters(new Error('RequestFailed'));
                }
            } catch (e) {
                if (e instanceof Error) {
                    setSourceFilters(e);
                } else {
                    setSourceFilters(new Error('NetworkError'));
                }
            }
        };

        sourcesFetch();
    }, [newsFeedCategoryDetails]);

    // Initial data fetch:
    useEffect(() => {
        fetchNews(
            NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
            (newsItems, totalCount) => {
                if (totalPagesForDefault === null) setTotalPagesForDefault(totalCount, ITEMS_TO_DISPLAY_PER_PAGE);
                setDefaultNewsItems(newsItems);
            },
            (err) => {
                setNewsError(err);
            }
        )
    }, [newsFeedCategoryDetails, ITEMS_TO_DISPLAY_PER_PAGE]);

    // DefaultNewsItems: Index based data fetch.
    useEffect(() => {
        if (defaultNewsItems === null || !usingDefaultNews) return;
        // If it is not complete, fetch more.
        if (!isCompleteSlice) {
            const lastVisibleNewsitem = defaultNewsItems.slice(-1).pop();
            fetchNews(
                NEWS_ITEMS_FETCH_ON_INDEX_CHANGE,
                (newNewsItems) => {
                    // Append new news items onto visible news.
                    const joinedListOfOldAndNewNews = defaultNewsItems.concat(newNewsItems);
                    // There could be some overlap between the end of visiblenews and the start of newnews items.
                    // So we remove duplicates.
                    const joinedNewsWithoutDuplicates: INewsLmdNewsItem[] = [];
                    // Map newsitem.id to boolean. Faster timecomplexity than "visibleNewsWithoutDuplicates.includes()";
                    const hasNewsItemBeenAdded: {
                        [feedId in string]?: boolean
                    } = {};
                    for (const newsItem of joinedListOfOldAndNewNews) {
                        if (newsItem.id === null) continue;
                        if (hasNewsItemBeenAdded[newsItem.id]) continue;
                        joinedNewsWithoutDuplicates.push(newsItem);
                        hasNewsItemBeenAdded[newsItem.id] = true;
                    }
                    setDefaultNewsItems(joinedNewsWithoutDuplicates);
                },
                (err) => {
                    setNewsError(err);
                },
                lastVisibleNewsitem?.publish_timestamp ?? undefined
            )
        }
    }, [isCompleteSlice, usingDefaultNews, defaultNewsItems])
    useEffect(() => {
        resetPageCounter();
    }, [ITEMS_TO_DISPLAY_PER_PAGE])
    // Refresh rate based fetch
    useEffect(() => {
        const newNewsRefreshFunction = async () => {
            const defaultNewsItems = defaultNewsItemsRef.current;
            const defaultNewsItemsWaitingQueue = defaultNewsItemsWaitingQueueRef.current;
            const currentPageIndex = currentPageIndexRef.current;
            const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
            const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
            const usingDefaultNews = usingDefaultNewsRef.current;
            const usingSearchWord = usingSearchWordRef.current;
            const searchResultsDoubleMap = searchResultsDoubleMapRef.current;
            const sourceFilters = sourceFiltersRef.current;

            const combineOldAndNewNews = (newNewsItems: INewsLmdNewsItem[], oldNews: INewsLmdNewsItem[], sources: string[] | null) => {
                const newNewsItemsForSources = (sources === null)
                    ? newNewsItems
                    : newNewsItems.filter(newsItem => sources.includes(newsItem.source ?? ''));

                const firstOldNews = oldNews[0];
                let indexWhereOldNewsBegins = newNewsItemsForSources.findIndex(newsItem => (
                    (
                        newsItem.id !== null
                        && newsItem.id === firstOldNews.id
                    )
                    || (
                        newsItem.publish_timestamp !== null
                        && firstOldNews.publish_timestamp !== null
                        && newsItem.publish_timestamp < firstOldNews.publish_timestamp
                    )
                ));

                const addedNews = newNewsItems.slice(0, indexWhereOldNewsBegins);
                const combinedNews = [...addedNews, ...oldNews];

                // There could be some overlap between the end of new news and old news.
                // So we remove duplicates.
                const combinedNewsWithoutDuplicates: INewsLmdNewsItem[] = [];
                // Map newsitem.id to boolean. Faster timecomplexity than ".includes()";
                const hasNewsItemBeenAdded: {
                    [feedId in string]?: boolean
                } = {};
                for (const newsItem of combinedNews) {
                    if (newsItem.id === null) continue;
                    if (hasNewsItemBeenAdded[newsItem.id]) continue;
                    combinedNewsWithoutDuplicates.push(newsItem);
                    hasNewsItemBeenAdded[newsItem.id] = true;
                }

                return {
                    combinedNews: combinedNewsWithoutDuplicates,
                    addedNews
                };
            }

            // Always update default news and non-searchword,yes-sourcefilter news.
            if (defaultNewsItems !== null) {
                const recursionStoppingNewsItem = (defaultNewsItems[0] ?? null) as INewsLmdNewsItem | null;
                if (recursionStoppingNewsItem === null || recursionStoppingNewsItem.id === null) return;
                const fetchNewNews = (numberToFetch: number, recursionTime = 0) => {
                    fetchNews(
                        numberToFetch,
                        (newNewsItems) => {
                            // Check if it has retreived all new news.
                            // Do this by finding index of the first old news item. (old as in already fetched).
                            let indexWhereOldNewsBegins = newNewsItems.findIndex(newsItem => (
                                (
                                    newsItem.id !== null
                                    && newsItem.id === recursionStoppingNewsItem.id
                                )
                                || (
                                    newsItem.publish_timestamp !== null
                                    && recursionStoppingNewsItem.publish_timestamp !== null
                                    && newsItem.publish_timestamp < recursionStoppingNewsItem.publish_timestamp
                                )
                            ));
                            // If not try again with higher limit.
                            if (indexWhereOldNewsBegins === -1) {
                                // RECURSION ALERT:
                                // If for some reason something goes wrong, this will break us out of recursion after 3 loops:
                                if (recursionTime < 3) fetchNewNews(numberToFetch * 2, recursionTime + 1);
                                return;
                            }

                            let newsThatGetNotification: INewsLmdNewsItem[] = [];

                            // Handle default news:
                            const defaultNewsToAddTo = (usingDefaultNews && currentPageIndex !== 0)
                                ? defaultNewsItemsWaitingQueue
                                : defaultNewsItems
                            const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, defaultNewsToAddTo, null);
                            if (usingDefaultNews) {
                                newsThatGetNotification = addedNews;
                                if (currentPageIndex === 0) setDefaultNewsItems(combinedNews)
                                else setDefaultNewsItemsWaitingQueue(combinedNews);
                            } else {
                                setDefaultNewsItems(combinedNews);
                            }

                            // Handle each source filter case:
                            const sourceFilterToResultsMap = searchResultsDoubleMap?.[newsFeedCategoryDetails.category];
                            const newSourceFilterToResultsMap: SourceFilterToResultsMap = { ...sourceFilterToResultsMap };
                            const currentSourceFilterString = `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
                            if (sourceFilterToResultsMap !== undefined) {
                                for (const sourceFilterString in sourceFilterToResultsMap) {
                                    const searchResultInfo = sourceFilterToResultsMap?.[sourceFilterString];
                                    if (searchResultInfo === undefined) continue;
                                    const { results, totalCount, waitingQueue } = searchResultInfo;
                                    const newsToAddTo = (!usingSearchWord && currentSourceFilterString === sourceFilterString && currentPageIndex !== 0)
                                        ? waitingQueue
                                        : results
                                    if (newsToAddTo instanceof Error || newsToAddTo === undefined) continue;
                                    const { combinedNews, addedNews } = combineOldAndNewNews(newNewsItems, newsToAddTo, getSourceListFromSourceString(sourceFilterString));

                                    if (!usingSearchWord && currentSourceFilterString === sourceFilterString) {
                                        newsThatGetNotification = addedNews;
                                        if (currentPageIndex === 0) {
                                            newSourceFilterToResultsMap[sourceFilterString] = {
                                                results: combinedNews,
                                                waitingQueue: [],
                                                totalCount: totalCount + addedNews.length
                                            }
                                        } else {
                                            newSourceFilterToResultsMap[sourceFilterString] = {
                                                results,
                                                waitingQueue: combinedNews,
                                                totalCount: totalCount + addedNews.length
                                            }
                                        }
                                    } else {
                                        newSourceFilterToResultsMap[sourceFilterString] = {
                                            results: combinedNews,
                                            waitingQueue: [],
                                            totalCount: totalCount + addedNews.length
                                        }
                                    }
                                }
                            }
                            // Initialize new states here, that will be updated
                            const newSearchResultsDoubleMap = {
                                ...searchResultsDoubleMap,
                                '': newSourceFilterToResultsMap
                            };

                            // Update isNew map.
                            const newNewsItemsToTrueMap: NewsItemFeedIdMapToBoolean = {};
                            const newNewsItemsToFalseMap: NewsItemFeedIdMapToBoolean = {};
                            newsThatGetNotification.forEach(newsItem => {
                                const { id } = newsItem;
                                if (id) {
                                    newNewsItemsToTrueMap[id] = true;
                                    newNewsItemsToFalseMap[id] = false;
                                }
                            });

                            const newNewsItemFeedIdToIsNewMap = {
                                ...newsItemFeedIdToIsNewMap,
                                ...newNewsItemsToTrueMap
                            };
                            const newNewsItemFeedIdToIsHighlightedMap = {
                                ...newsItemFeedIdToIsHighlightedMap,
                                ...newNewsItemsToTrueMap
                            };

                            // Finish by setting all new states and time outs
                            setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                            // Set timeouts for resetting isNew notifier + highlight.
                            setNewsItemFeedIdToIsHighlightedMap(newNewsItemFeedIdToIsHighlightedMap);
                            setNewsItemFeedIdToIsNewMap(newNewsItemFeedIdToIsNewMap);
                            window.setTimeout(() => {
                                const newsItemFeedIdToIsHighlightedMap = newsItemFeedIdToIsHighlightedMapRef.current;
                                setNewsItemFeedIdToIsHighlightedMap({
                                    ...newsItemFeedIdToIsHighlightedMap,
                                    ...newNewsItemsToFalseMap
                                });
                            }, 15 * 1000 /*15 sek.*/);
                            window.setTimeout(() => {
                                const newsItemFeedIdToIsNewMap = newsItemFeedIdToIsNewMapRef.current;
                                setNewsItemFeedIdToIsNewMap({
                                    ...newsItemFeedIdToIsNewMap,
                                    ...newNewsItemsToFalseMap
                                });
                            }, 15 * 60 * 1000 /* 15 min. */);
                        },
                        (err) => {
                            setNewsError(err);
                        }
                    )
                }
                fetchNewNews(INITIAL_REFRESH_RATE_NEWS_ITEMS_TO_FETCH);
            }
        }
        if (refreshRateMs) {
            // If refresh rate, set fetchData on an interval
            const intervalId = window.setInterval(() => {
                newNewsRefreshFunction();
            }, refreshRateMs);
            // Clean up: clear interval to avoid memory leaks
            return () => window.clearInterval(intervalId);
        }
    }, [refreshRateMs, usingSearchWord, usingSourceFilter]);

    // Reset waiting queues on page index change.
    useEffect(() => {
        if (currentPageIndex === 0) {
            // Reset default queue.
            if (defaultNewsItems !== null) {
                setDefaultNewsItems([...defaultNewsItemsWaitingQueue, ...defaultNewsItems]);
                setDefaultNewsItemsWaitingQueue([]);
            }
            // Reset search/source based queue.
            let newSearchResultsDoubleMap = {
                ...searchResultsDoubleMap
            };
            for (const searchInputKey in searchResultsDoubleMap) {
                const sourceFilterToResultsMap = searchResultsDoubleMap[searchInputKey];
                if (sourceFilterToResultsMap === undefined) continue;
                for (const sourceFilterKey in sourceFilterToResultsMap) {
                    const searchResults = sourceFilterToResultsMap[sourceFilterKey];
                    if (searchResults === undefined) continue;
                    const { results, waitingQueue } = searchResults;
                    if (results instanceof Error || waitingQueue === undefined) continue;
                    const newSearchResult: SearchResultsInfo = {
                        ...searchResults,
                        results: [...results, ...waitingQueue],
                        waitingQueue: []
                    }
                    newSearchResultsDoubleMap = updateDoubleMapValue(newSearchResultsDoubleMap, searchInputKey, sourceFilterKey, newSearchResult);
                }
            }
            setSearchResultsDoubleMap(newSearchResultsDoubleMap);
        }
    }, [currentPageIndex]);

    // Search based fetch.
    useEffect(() => {
        if (usingDefaultNews || isCompleteSlice || usingSourceFilter === 'none') return;

        // Helper delay function.
        const withDelay = (func: () => void) => {
            const timeoutFunctionId = timeoutFunctionIdRef.current;
            if (timeoutFunctionId) window.clearTimeout(timeoutFunctionId);

            timeoutFunctionIdRef.current = window.setTimeout(func, 400);
        }

        // no search word. only source filter.
        if (!usingSearchWord) {
            const sourceFilterString = `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
            if (sourceFilterString === null) return;
            const searchResults = searchResultsDoubleMap?.['']?.[sourceFilterString];

            let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                : (searchResults.results.length * 2);
            if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch);
            fetchNews(
                numberOfNewsItemsToFetch,
                (results, totalCount) => {
                    const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                        searchResultsDoubleMap,
                        '',
                        sourceFilterString,
                        {
                            results,
                            totalCount,
                            waitingQueue: []
                        }
                    );
                    setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                },
                (err) => {
                    const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                        searchResultsDoubleMap,
                        '',
                        sourceFilterString,
                        {
                            results: err,
                            totalCount: 0,
                            waitingQueue: []
                        }
                    );
                    setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                },
                undefined,
                undefined,
                sourceFilterString
            )
        }
        // Search word.
        else {
            // No Source filter.
            if (usingSourceFilter === 'all') {
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[newsFeedCategoryDetails.category];

                let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                    ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                    : (searchResults.results.length * 2);
                if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch);
                withDelay(() => fetchNews(
                    numberOfNewsItemsToFetch,
                    (results, totalCount) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            newsFeedCategoryDetails.category,
                            {
                                results,
                                totalCount,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    (err) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            newsFeedCategoryDetails.category,
                            {
                                results: err,
                                totalCount: 0,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    undefined,
                    searchInput
                ));
            }
            // Filtering by source filter.
            else {
                const sourceFilterString = `${newsFeedCategoryDetails.category} - ` + getSourceStringFromSourceList(sourceFilters, 'on');
                if (sourceFilterString === null) return;
                const searchResults = searchResultsDoubleMap?.[searchInput]?.[sourceFilterString];

                let numberOfNewsItemsToFetch = (searchResults === undefined || searchResults.results instanceof Error)
                    ? NEWS_ITEMS_FETCH_ON_INDEX_CHANGE
                    : (searchResults.results.length * 2);
                if (searchResults?.totalCount) numberOfNewsItemsToFetch = Math.min(searchResults.totalCount, numberOfNewsItemsToFetch);
                withDelay(() => fetchNews(
                    numberOfNewsItemsToFetch,
                    (results, totalCount) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            sourceFilterString,
                            {
                                results,
                                totalCount,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    (err) => {
                        const newSearchResultsDoubleMap = updateDoubleMapValue<SearchResultsInfo>(
                            searchResultsDoubleMap,
                            searchInput,
                            sourceFilterString,
                            {
                                results: err,
                                totalCount: 0,
                                waitingQueue: []
                            }
                        );
                        setSearchResultsDoubleMap(newSearchResultsDoubleMap);
                    },
                    undefined,
                    searchInput,
                    sourceFilterString
                ));
            }
        }
    }, [usingDefaultNews, searchInput, sourceFilters, searchResultsDoubleMap, isCompleteSlice, usingSourceFilter]);

    const displayNewsItems = useMemo(() => {
        if (usingSourceFilter === 'none') {
            return <div className='content no_data'><span>Pick more sources</span></div>
        }
        const dataOrLastData = dataToDisplay ?? lastDisplayableData ?? null;
        if (dataOrLastData === null) {
            if (newsError === null) return <div className='fill center-container'><CircularProgress /></div>
            else return <div className='content no_data'><span>Error</span></div>
        }

        const newsItems = dataOrLastData
        if (newsItems.length === 0) return <div className='content no_data'><span>No data</span></div>
        return (
            <div className='content'>
                {newsItems.map((feedItem, index) => (
                    <NewsItem
                        key={feedItem.id}
                        title={feedItem.title}
                        description={feedItem.body ?? 'Ekki fundust frekari upplýsingar um frétt'}
                        date={(feedItem.publish_date)
                            ? getNewsDateOrTime(new Date(feedItem.publish_date))
                            : '-'}
                        source={feedItem.source?.split('_')[0]}
                        fullSource={feedItem.fullSource}
                        link={feedItem.link}
                        isNew={feedItem.id !== null && (newsItemFeedIdToIsNewMap[feedItem.id] ?? false)}
                        isHighlighted={feedItem.id !== null && (newsItemFeedIdToIsHighlightedMap[feedItem.id] ?? false)}
                        isSeen={feedItem.id !== null && (newsItemFeedIdToIsSeenMap[feedItem.id] ?? false)}
                        onOpen={() => newsItemOnOpen(feedItem)}
                    />
                ))}
            </div>
        );
    }, [ITEMS_TO_DISPLAY_PER_PAGE, dataToDisplay, lastDisplayableData, usingSourceFilter, newsItemFeedIdToIsSeenMap, newsItemFeedIdToIsNewMap, newsItemFeedIdToIsHighlightedMap, newsError])

    const isNextPageDisabled = useMemo(() => {
        if (typeof totalPages === 'string') return true
        return ((currentPageIndex + 1) >= totalPages)
    }, [currentPageIndex, totalPages]);

    const isPrevPageDisabled = useMemo(() => {
        return (currentPageIndex === 0)
    }, [currentPageIndex]);

    const cleanSourceFilters = () => {
        if (sourceFilters instanceof Error || sourceFilters === null) return []
        return sourceFilters
    }

    return (
        <div className="KCL_news">
            {displayNewsItems}
            <div className="news-list__footer">
                <Filter
                    newsSources={cleanSourceFilters()}
                    setNewsSources={(e): void => { setSourceFilters(e); saveSourceState(e) }
                    } />
                <div className="news-list__pagination">
                    <Select
                        size='small'
                        sx={{
                            color: ' var(--dark-900, #232530)',
                            fontFamily: 'Roboto',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            minHeight: 'unset'
                        }}
                        MenuProps={{ className: "perview-select" }}
                        style={{ height: '20px', marginRight: '7px', lineHeight: 2 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={ITEMS_TO_DISPLAY_PER_PAGE}
                        onChange={(e) => setQuantityPerPage(Number(e.target.value))}
                    >
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                    <div className="news-list__indicator">
                        {(totalPages === 0) ? 0 : (currentPageIndex + 1)} af {totalPages}
                    </div>
                    <div className="pagination">
                        <span
                            style={{ cursor: 'pointer' }}
                            className="news-list__arrows"
                            onClick={isNextPageDisabled ? undefined : goToNextPage}
                        >
                            <FontAwesomeIcon icon={faChevronDown}
                                className={cx("icon-fa", { "disabled": isNextPageDisabled })}
                            />
                        </span>
                        <span
                            style={{ cursor: 'pointer', marginLeft: '10px' }}
                            className="news-list__arrows"
                            onClick={isPrevPageDisabled ? undefined : goToPreviousPage}
                        >
                            <FontAwesomeIcon icon={faChevronUp}
                                className={cx("icon-fa", { "disabled": isPrevPageDisabled })}
                            />
                        </span>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default NewsContainer;