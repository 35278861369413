import * as React from "react";
import { TranslationManager } from "../../Translation/Translation"; // Update the path based on your project structure
import { AppStateManager } from "../../StateManager";
import "./SettingsModal.scss";
import { Config } from "../../Config/Config";
import { Light } from "../UI-Elements/StatusLights";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { generateUUID } from "../../Utils/Common";

interface SocketConnections {
  Active: boolean;
  Url: string;
}
const SettingsModal: React.FC<{ closeModal: () => void }> = ({
  closeModal,
}) => {
  const private_data: SocketConnections[] = Object.entries(AppStateManager.PDS.PDSsockets).map((item) => {
    return {
      Active: item[1].status.getValue() === "connected",
      Url: item[1].name ?? item[1].url,
    }
  }).sort((a, b) => (a.Active === b.Active) ? 0 : a.Active ? -1 : 1)
  const userAccess: SocketConnections[] = AppStateManager.MF.availableSockets.map((item) => {
    return {
      Active: AppStateManager.MF.currentSocket?.name === item.name,
      Url: item.name ?? item.url,
    }
  }).sort((a, b) => (a.Active === b.Active) ? 0 : a.Active ? -1 : 1)

  type Subscription =
    | "omx-eq-1"
    | "omx-eq-2"
    | "omx-eq-3"
    | "omx-fi-1"
    | "omx-fi-2"
    | "omx-fi-3";
  interface Clearance {
    name: string;
    description: string;
  }

  const subscriptionData: Record<Subscription, Clearance> = {
    "omx-eq-1": { name: "OMXEqL1", description: "Equities first level" },
    "omx-eq-2": { name: "OMXEqL2", description: "Equities first five levels" },
    "omx-eq-3": { name: "OMXEqL2+", description: "Equities All Levels" },
    "omx-fi-1": { name: "OMXFiL1", description: "Bonds First Level" },
    "omx-fi-2": { name: "OMXFiL2", description: "Bonds First Five Levels" },
    "omx-fi-3": { name: "OMXFiL2+", description: "Bonds All Levels" },
  };

  function getHighestClearance(
    subscriptions: string[]
  ): Clearance[] | undefined {
    const clearance: Record<string, Clearance> = {};
    subscriptions.forEach((subscription: string) => {
      const [, category, level] = subscription.split("-");
      const levelNumber = parseInt(level, 10);

      if (
        !clearance[category] ||
        parseInt(clearance[category].name.split("L")[1]) < levelNumber
      ) {
        //if subscriptionData[subscription] is undefined, it will skip this subscription
        if (subscriptionData[subscription] === undefined) return;
        clearance[category] = subscriptionData[subscription];
      }
    });
    return Object.values(clearance);
  }

  // Subscriptions table
  const displaySubTable = React.useMemo(() => {
    const rows = getHighestClearance(
      AppStateManager.subscriptionLevel.subscriptions
    );
    return rows === undefined || rows.length === 0 ? (
      <span>
        {TranslationManager.getTranslation().Errors.NoSubscriptionFound}
      </span>
    ) : (
      <div className="subscription_section">
        {rows?.filter(Boolean).map((row, index) => (
          <div
            style={{
              marginBottom: rows.length - 1 !== index ? "8px" : "unset",
            }}
            key={generateUUID()}
          >
            {row.name} / {row.description}
          </div>
        ))}
      </div>
    );
  }, [AppStateManager.subscriptionLevel.subscriptions, getHighestClearance]);

  const displayItem = (title: string, value: string | React.JSX.Element) => {
    return (
      <div className="settings_modal_item">
        <span className="title">{title}</span>
        <span className="value">{value}</span>
      </div>
    );
  };


  return (
    <div className="keldan_pro_SettingsModal">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1 className="modal_title">
          {TranslationManager.getTranslation().Settings_Modal.Title}
        </h1>
        {/* close ICone */}
        <FontAwesomeIcon
          className="modalCloseButton"
          onClick={() => closeModal()}
          icon={faXmark}
        />
      </div>
      {displayItem(
        TranslationManager.getTranslation().Settings_Modal.Username,
        AppStateManager.userProfile.username
      )}
      <div className="settings_modal_item-wrapper">
        {displayItem(
          TranslationManager.getTranslation().Settings_Modal.Auth_url,
          Config.User_System_Domain
        )}
        {displayItem(
          TranslationManager.getTranslation().Settings_Modal.Api_url,
          Config.LMD_Domain
        )}
      </div>
      {displayItem(
        TranslationManager.getTranslation().Settings_Modal
          .Market_data_connections,
        <>
          {userAccess.map((item, index) => (
            <div
              style={{
                display: "flex",
                gap: "8px",
                marginBottom: userAccess.length - 1 !== index ? "8px" : "unset",
              }}
              key={generateUUID()}
            >
              {item.Active ? (
                <Light withIcon status={item.Active} />
              ) : (
                <div style={{ width: "12px" }}></div>
              )}
              <div style={{ flex: "1", wordBreak: "break-all" }}>
                {item.Url}
              </div>
            </div>
          ))}
        </>
      )}
      {AppStateManager.hasAccessToPDS && displayItem(
        TranslationManager.getTranslation().Settings_Modal
          .PDS_connections,
        <>
          {private_data.map((item, index) => (
            <div
              style={{
                display: "flex",
                gap: "8px",
                marginBottom:
                  private_data.length - 1 !== index ? "8px" : "unset",
              }}
              key={generateUUID()}
            >
              <Light withIcon status={item.Active} />
              <div style={{ flex: "1", wordBreak: "break-all" }}>
                {item.Url}
              </div>
            </div>
          ))}
        </>
      )}
      {displayItem(
        TranslationManager.getTranslation().Settings_Modal.Subscriptions,
        displaySubTable
      )}

    </div>
  );
};

export default SettingsModal;
