import { AutocompleteBonds, ChartData, ListInfo, MoreTradeableInfo, TradeableInfo, WebsocketConnectionData } from "../Types/LMDTypes";
import { flatten, getPDSURLFromName, randomString16Char } from "../Utils/Common";
import { FatFingerCheck, News, Sources, Trade } from "../Types/Websocket";
import { Config } from "../Config/Config";
import { AppStateManager } from "../StateManager";
import Bugsnag from "@bugsnag/js";
import { PortfolioOverviewResponse, PortfolioResponse, RetailCustomerResponse } from "../Types/RetailTradingTypes";

export interface SubscriptionLevel {
    username: string;
    iss: string;
    exp: number;
    iat: number;
    system: {
        scopes: string[];
        id: string;
        timeout: number;
        name: string;
        delayed: boolean;
    };
    subscriptions: string[];
}

interface List {
    count: number;
    name: string;
    symbol: string;
}

interface Exchange {
    lists: List[];
    name: string;
    symbol: string;
}

interface MyLists {
    country: string;
    name: string;
    exchanges: Exchange[];
}

export class LMDInterface {
    // --- Helper and config functions ----
    static createURL(system: 'User' | 'LMD' | 'News' | 'KeldanUser', path: string) {
        const domain = {
            'User': Config.User_System_Domain,
            'LMD': Config.LMD_Domain,
            'News': Config.News_Domain,
            'KeldanUser': Config.Keldan_User_Domain,
        }[system];
        return `${Config.HTTP_Type}://${domain}${path}`;
    }

    static async getRequest(url: string): Promise<any> {
        const token = await AppStateManager.getToken();
        const resp = await fetch(url, { method: 'GET', headers: { Authorization: `Bearer ${token}` } });
        if (resp.status > 299) {
            const error = new Error(await resp.text());
            Bugsnag.notify(error);
            throw error;
        }
        return JSON.parse(await resp.text());
    }
    static async deleteRequest(url: string, body?: any): Promise<any> {
        const token = await AppStateManager.getToken();
        let resp;
        if (body) {
            resp = await fetch(url, { method: 'DELETE', headers: { Authorization: `Bearer ${token}` }, body: JSON.stringify(body) });
        } else {
            resp = await fetch(url, { method: 'DELETE', headers: { Authorization: `Bearer ${token}` } });
        }
        if (resp.status > 299) {
            const error = new Error(await resp.text());
            Bugsnag.notify(error);
            throw error;
        }
        const respText = await resp.text();
        try {
            return JSON.parse(respText);
        } catch (e) {
            return respText;
        }
    }

    static async putRequest(url: string, body?: any): Promise<any> {
        const token = await AppStateManager.getToken();
        const resp = await fetch(url, { method: 'PUT', headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" }, body: JSON.stringify(body) });
        if (resp.status > 299) {
            const error = new Error(await resp.text());
            Bugsnag.notify(error);
            throw error;
        }
        const respText = await resp.text();
        try {
            return JSON.parse(respText);
        } catch (e) {
            return respText;
        }
    }

    // Stores response for later calls
    static memo: Record<string, Promise<any>> = {};
    static async cachedGetRequest(url: string) {
        if (!(url in LMDInterface.memo)) LMDInterface.memo[url] = LMDInterface.getRequest(url);
        return LMDInterface.memo[url];
    }


    // --- Core call functions ----
    static async createToken(username: string, password: string): Promise<string> {
        const bodyString = JSON.stringify({
            username, password,
            system_id: Config.SYSTEM_ID
        });

        const url = LMDInterface.createURL('User', '/v3/token/create');

        const resp = await fetch(url, {
            method: 'POST',
            body: bodyString,
            headers: { 'content-type': 'application/json' }
        });
        if (resp.status > 299) {
            let error;
            if (resp.status < 500) {
                error = new Error(JSON.parse(await resp.text())['error']);
                Bugsnag.notify(error);
                throw error;
            }
            else {
                error = new Error(await resp.text());
                Bugsnag.notify(error);
                throw error;
            }
        }
        const token = JSON.parse(await resp.text())['access_token'];
        return token;
    }

    /** @description Attempt to retrieve the system profile with provided token */
    /* returns { access: {name, system, system_id, type, url}[], system, username} */
    static async getSystemProfile(): Promise<{
        access: WebsocketConnectionData[];
        system: string;
        username: string;
    }> {
        try {
            const token = await AppStateManager.getToken();
            const url = LMDInterface.createURL('User', '/v3/token/system_profile');
            const resp = await fetch(url, {
                method: 'POST',
                body: token,
                headers: { 'content-type': 'application/json' }
            });
            return JSON.parse(await resp.text());
        } catch (error) {
            Bugsnag.notify(error as Error);
            throw error;
        }
    }

    static async getSystemSubscription(): Promise<SubscriptionLevel> {
        try {
            const token = await AppStateManager.getToken();
            const url = LMDInterface.createURL('User', '/v3/token/validate');
            const resp = await fetch(url, {
                method: 'POST',
                body: token,
                headers: { 'content-type': 'application/json' }
            });
            return JSON.parse(await resp.text());
        } catch (error) {
            Bugsnag.notify(error as Error);
            throw error;
        }
    }

    static async saveMyList(listname: string, symbols: number[]): Promise<any> {
        const url = LMDInterface.createURL('LMD', `/v1/workspace/v1/user_list/kodiak_pro/${listname}`);
        return LMDInterface.putRequest(url, symbols);
    }

    static async getMyLists(): Promise<MyLists> {
        const url = LMDInterface.createURL('LMD', '/v1/workspace/v1/my_lists/kodiak_pro');
        return LMDInterface.getRequest(url);
    }

    static async getMyListSymbols(index: string): Promise<TradeableInfo[]> {
        if (index === undefined) return [];
        const url = LMDInterface.createURL('LMD', `/v1/workspace/v1/user_list/kodiak_pro/${index}`);
        return LMDInterface.getRequest(url);
    }

    /** @description Attempt to retrieve the country tradeable lists
    * @param {string} token The API token */
    static async getCountryTradeableLists(): Promise<MyLists[]> {
        const url = LMDInterface.createURL('LMD', '/v1/static_data/v1/tradable_lists_by_country');
        return LMDInterface.cachedGetRequest(url);
    }

    /** @description Attempt to retrieve the symbols for a specific tradeable list
    * @param {string} index The list name */
    static async getTradeableListSymbols(list: ListInfo | string): Promise<string[]> {
        if (typeof list === 'string') {
            const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/tradable_list/${list}`);
            return (await LMDInterface.cachedGetRequest(url)).map(entry => entry.Symbol);
        }
        if (list.symbol === '') return [];
        if (list.country === 'My Lists') return (await LMDInterface.getMyListSymbols(list.symbol)).map(entry => entry.Symbol);
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/tradable_list/${list.symbol}`);
        return (await LMDInterface.cachedGetRequest(url)).map(entry => entry.Symbol);
    }

    static async getTradeableListInfo(list: string): Promise<TradeableInfo[]> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/tradable_list/${list}`);
        return LMDInterface.cachedGetRequest(url);
    }

    /** @description Attempt to retrieve info about a specific symbol
    * @param {string} symbol The symbol */
    static async getTradeableInfo(symbol: string): Promise<TradeableInfo> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/tradables_short/${symbol}`);
        return (await LMDInterface.cachedGetRequest(url))[0];
    }

    /** @description Attempt to retrieve info about a specific symbol
    * @param {string} symbol The symbol */
    static async getMoreTradeableInfo(symbol: string): Promise<MoreTradeableInfo> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/exchanges/*/tradables/${symbol}`);
        return (await LMDInterface.cachedGetRequest(url))[0];
    }
    // /** @description retrieves info about all bonds
    static async getAutocompleteBonds(): Promise<AutocompleteBonds[]> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/autocomplete/bonds/`);
        const resp = (await LMDInterface.cachedGetRequest(url));
        return resp;
    }

    static async getAllLists(): Promise<ListInfo[]> {
        const myLists = await LMDInterface.getMyLists();
        const index = await LMDInterface.getCountryTradeableLists();
        const listSymbols = flatten(index.map(country => country.exchanges.map(exhange => exhange.lists.map(
            list => { return { ...list, country: country.name }; }
        ))));
        const myListSymbols = flatten(myLists.exchanges.map(exchange => exchange.lists.map(list => { return { ...list, country: 'My Lists' }; })));
        return myListSymbols.concat(listSymbols);
    }

    static async getAllSymbols(): Promise<string[]> {
        const lists = await LMDInterface.getAllLists();
        const symbols: string[] = flatten(await Promise.all(lists.map(async ({ symbol }) => LMDInterface.getTradeableListSymbols(symbol))));
        for (let i = 0; i < symbols.length; i++) {
            if (typeof symbols[i] !== 'string') {
                symbols[i] = symbols[i].toString();
            }
        }
        //sort this list
        symbols.sort();
        return [...new Set(symbols)]
    }

    static async getAllTradeableInfo(): Promise<TradeableInfo[]> {
        const index = await LMDInterface.getCountryTradeableLists();
        const lists = flatten(index.map(country => country.exchanges.map(exhange => exhange.lists.map(
            list => { return { ...list, country: country.name }; }
        ))));
        let tradables: TradeableInfo[] = flatten(await Promise.all(lists.map(async ({ symbol }) => LMDInterface.getTradeableListInfo(symbol))));
        tradables = tradables.sort((a, b) => {
            if (typeof a.Symbol === 'string' && typeof b.Symbol === 'string') {
                return a.Symbol.localeCompare(b.Symbol);
            } else {
                return a.Symbol.toString().localeCompare(b.Symbol.toString());
            }
        });
        let i = 0;
        while (i + 1 < tradables.length) {
            if (typeof tradables[i].Symbol !== 'string') {
                tradables[i].Symbol = tradables[i].Symbol.toString();
            }
            if (tradables[i].Symbol === tradables[i + 1].Symbol) {
                tradables.splice(i + 1, 1);
            } else {
                i++;
            }
        }
        return tradables;
    }

    static async getHistoricalGraphData(symbol: string): Promise<ChartData> {
        const url = LMDInterface.createURL('LMD', `/v1/market_data/v1/tradables/${symbol}/history_timeseries_short/?from_date=1970-01-01`);
        const resp: any = await LMDInterface.getRequest(url);
        return {
            ...resp, points: resp.data.map(
                ([trading_date, open, open_yield, high, high_yield, low, low_yield, close, close_yield, volume, open_corrected, high_corrected, low_corrected, close_corrected, volume_corrected]) => { return { date: trading_date, price: close_corrected ?? close, volume } }
            )
        };
    }

    static async getTickSizes(tickTableID: number): Promise<TickSize[]> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/ticksizetables/id/${tickTableID}`);
        return (await LMDInterface.cachedGetRequest(url));
    }


    static async getTradingScheme(marketSymbol: string): Promise<MarketStatusInfo[]> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/trading_scheme/${marketSymbol}`);
        return (await LMDInterface.cachedGetRequest(url));
    }

    static async getNews(category: string = "is_bus", start: string = "0", limit: string = "100"): Promise<News> {
        const url = LMDInterface.createURL('News', `/search/keldan?start=${start}&limit=${limit}&category=${category}`);
        return (await LMDInterface.getRequest(url));
    }

    static async getNewsSources(): Promise<Sources[]> {
        const url = LMDInterface.createURL('News', `/search/sources`);
        return (await LMDInterface.cachedGetRequest(url));
    }

    static async getNewsSearch(ticker: string, searchWord: string = "*", start: string = "0", limit: string = "100"): Promise<News> {
        const url = LMDInterface.createURL('News', `/search/query?start=${start}&limit=${limit}&f=title;body&q=${searchWord}&filter=symbol.ticker=${ticker}`);
        return (await LMDInterface.cachedGetRequest(url));
    }

    static async getSupplementaryData(symbol: string): Promise<SupplementaryData> {
        const url = LMDInterface.createURL('LMD', `/v1/static_data/v1/tradable_supplementary/${symbol}`);
        return (await LMDInterface.cachedGetRequest(url))[0]
    }

    static async getTrades(): Promise<Trade[]> {
        const url = LMDInterface.createURL('LMD', `/v1/market_data/v1/keldan_markets/icelandic/market/*/tradables/*/latest_trades`);
        const incomingTrades: GetTradesTrade[] = await LMDInterface.getRequest(url);
        // This type can be better converted TODO
        return incomingTrades.map(incomingTrade => {
            return {
                ...incomingTrade,
                time_executed: incomingTrade.time_executed !== null ? new Date(incomingTrade.time_executed) : null
            } as unknown as Trade
        });
    }

    //THIS ONLY EXISTS FOR THE FAT FINGER CHECK
    static async getRequestNoToken(url: string): Promise<any> {
        const resp = await fetch(url, { method: 'GET', });
        if (resp.status > 299) {
            const error = new Error(await resp.text());
            Bugsnag.notify(error);
            throw error;
        }
        return JSON.parse(await resp.text());
    }
    //WE ALWAYS WANT TO USE PROD FOR THIS
    static async fatFingerCheck(symbol: string, price: number, buySell: string): Promise<FatFingerCheck> {
        const url = `https://api.livemarketdata.com/v1/risk/v1/${symbol}/fat_fingers_check/?price=${price}&deviation=0.1&buy_sell=${buySell}`;
        return (await LMDInterface.getRequestNoToken(url));
    }

    static async deleteOrder(broker?: string, orderId?: string, reason?: string): Promise<any> {
        const isRetail = AppStateManager.hasAccessToRetail;
        const cancelReq = {
            reference_id: randomString16Char(),
            cancel_reason: reason
        };


        let url = isRetail ? `https://${new URL(AppStateManager.Retail.currentSocket?.url ?? '').host}/kodi/pds/retail/1.0.0/api/orders/${orderId}` : getPDSURLFromName(broker)?.replace('wss://', 'https://') + '/kodi/pds/dma/1.0.0/api/orders/' + orderId
        return (await LMDInterface.deleteRequest(url, cancelReq));
    }

    static async deleteMyList(listname: string) {
        const url = LMDInterface.createURL('LMD', `/v1/workspace/v1/user_list/kodiak_pro/${listname}`);
        return LMDInterface.deleteRequest(url);
    }

    static async updateOrder(orderType: 'dma' | 'retail', broker?: string, orderId?: string, price?: number, quantity?: number): Promise<any> {
        const updateReq = {
            reference_id: randomString16Char(),
            price: price,
            quantity: quantity
        };
        let url = orderType === 'dma' ? getPDSURLFromName(broker)?.replace('wss://', 'https://') + `/kodi/pds/${orderType}/1.0.0/api/orders/` + orderId
            : `https://${broker}/kodi/pds/${orderType}/1.0.0/api/orders/` + orderId;
        return (await LMDInterface.putRequest(url, updateReq));
    }

    static async testPDSVersion(streamer: WebsocketConnectionData): Promise<WebsocketConnectionData | Error> {
        const streamerurl = new URL(streamer.url).host;
        const url = `https://${streamerurl}/kodi/pds/dma/1.0.0/api/orders`;
        try {
            await LMDInterface.getRequest(url);
            return streamer;
        } catch (e) {
            return new Error('Failed to get orders from PDS streamer');
        }
    }

    static async getRetailCustomer(broker: string): Promise<RetailCustomerResponse> {
        const url = `https://${broker}/kodi/pds/retail/1.0.0/api/customer`
        return (await LMDInterface.getRequest(url));
    }

    static async getRetailPortfolioOverview(id: string, from_date?: string, to_date?: string): Promise<PortfolioOverviewResponse> {
        const broker = new URL(AppStateManager.Retail.currentSocket?.url ?? '').host;
        let url = `https://${broker}/kodi/pds/retail/1.0.0/api/portfolios/${id}/overview`;
        if (from_date && from_date.trim() !== "") url = url.concat(`?from_date=${from_date}`);
        if (to_date && to_date.trim() !== "") url = url.concat(`&to_date=${to_date}`);
        return (await LMDInterface.cachedGetRequest(url));
    }
    static async getRetailPortfolios(broker: string): Promise<PortfolioResponse> {
        const url = `https://${broker}/kodi/pds/retail/1.0.0/api/portfolios`
        return (await LMDInterface.getRequest(url));
    }
}


type GetTradesTrade = {
    aggressive_party: string;
    buyer: null;
    class: string;
    duration: null;
    is_latest_trade: boolean;
    outside_spread: boolean;
    price: number;
    price_dirty: null;
    price_per_point: null;
    seller: null;
    symbol: string;
    time_executed: string;
    time_executed_utc: string;
    timestamp_agreement: null;
    timestamp_dissemination: string;
    timestamp_trade_cancel: null;
    top_of_book_ask: number;
    top_of_book_bid: number;
    trade_cancellation: boolean;
    trade_id: string;
    trade_number: number;
    trade_type: null;
    trade_updates_average: boolean;
    trade_updates_high_low: boolean;
    trade_updates_last_paid: boolean;
    trade_updates_turnover: boolean;
    updated_average: boolean;
    updated_high_low: boolean;
    updated_last_paid: boolean;
    updated_turnover: boolean;
    value: number;
    volume: number;
    yield: null;
}


type SupplementaryData = {
    Symbol: string; //"SIMINN"
    IdCode: number; //1907478
    SourceId: number; //113958
    SourceSystem: number; //8
    InstrumentIdentificationCodeType: string; //"OTHR"
    Fisn: string; //"SIMINN HF/SH"
    MifirId: string; //"SHRS"
    ContractType: any; //null
    Liquid: boolean; //false
    ToBeCleared: boolean; //false
    LisPreTrade: number; //2.926e7
    LisPostTrade: number; //2.926e7
    SstiPostTrade: any; //null
    BaseProductCode: any; //null
    SubProductCode: any; //null
    FurtherSubProductCode: any; //null
    CommoditiesOrEmissionAllowanceDerivativeIndicator: boolean; //false
    VesselSize: any; //null
    DeliveryCashSettlementLocation: any; //null
    TransactionType: any; //null
    FinalPriceType: any; //null
    UnderlyingTypeCode: any; //null
    QuantityUnit: any; //null
    QuantityNotation: any; //null
    AssetClassOfTheUnderlying: any; //null
    MifidBondType: any; //null
    BasePointSpread: any; //null
    BondSeniority: any; //null
    PriceMultiplier: any; //null
    ContractSubType: any; //null
    Adnt: number; //9.4
    ReserveOrderThreshold: number; //1463000
    MaxOtRatio: number; //3.0e5
    MaxOtVolume: number; //5.0e8
    OrderPriceCollar: number; //45.0
    MaxOrderValue: number; //1.3875e9
    MaxOrderVolume: number; //1.3875e8
    ManualTradePriceCollar: number; //5.0
    MifidTickSize: boolean; //true
    OtherTickSizeTable: boolean; //false
    Term: any; //null
    InsertTimestamp: string; //"2018-01-30 17:14:32.074"
    UpdateTimestamp: string; //"2023-07-25 06:48:25.312"
    MostRelevantMarket: string; //"XICE"
}

type MarketStatusInfo = {
    Date: string;//"2023-7-25";
    MarketStateCode: string;//"PreTrading";
    Name: string;//"OMX ICE Equities"
    StartTime: string;//"10:00:00"
    Symbol: string;//"ISEQ SHR"
}

export type TickSize = {
    IdCode: number;
    Name: string;
    PriceFrom: number;
    PriceTo: number;
    TickSize: number;
}