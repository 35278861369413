import { ColumnInfo } from "../../../Types/MUITable";

export const MarketByOrderColumnsInfo: ColumnInfo[] = [
    { name: "b_orders", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "b_yield", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "b_dirty", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "b_participant", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "b_volume", defaultHidden: false, flex: 1, dataType: 'number' },
    { name: "b_price", defaultHidden: false, flex: 1, dataType: 'number', notHidable: true },

    { name: "a_price", defaultHidden: false, flex: 1, dataType: 'number', notHidable: true },
    { name: "a_volume", defaultHidden: false, flex: 1, dataType: 'number' },
    { name: "a_participant", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "a_dirty", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "a_yield", defaultHidden: true, flex: 1, dataType: 'number' },
    { name: "a_orders", defaultHidden: true, flex: 1, dataType: 'number' }
];