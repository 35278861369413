/**
 * Private Data Streamer API
 * Private Data Streamer Swagger API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: dagur@kodi.is
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * The order/trade report side
 */
export type SideTypes = 'buy' | 'sell';

export const Side = {
    buy: 'buy' as SideTypes,
    sell: 'sell' as SideTypes
};
