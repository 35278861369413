import React from 'react';
import cx from 'classnames';
import { SupportedLanguage, TranslationManager } from '../../Translation/Translation';
import Flag, { FlagNames } from './Flag';

interface FlagProps {
    name: string;
    language: SupportedLanguage;
}

const FlagWrapper: React.FC<FlagProps> = ({ name, language }) => (
    <div onClick={() => changeLanguage(language)} className={cx("flag-wrapper",{'selected': TranslationManager.getActiveLanguage() === language})}>
        <Flag name={name as FlagNames} /> <span className="language" >{language}</span>
    </div>
);

const changeLanguage = (language: SupportedLanguage) => {
    //check if language is already active
    if (TranslationManager.getActiveLanguage() === language) return;
    //change language
    TranslationManager.setActiveLanguage(language);
}

const LanguageSelector: React.FC = () => (
    <div className='KM_languageSelector'>
        <h6 className='title'>{TranslationManager.getTranslation().Header.Settings.Language}</h6>
        <div className='selectors'>
            <FlagWrapper name="ISK" language="IS" />
            <FlagWrapper name="GBP" language="EN" />
        </div>
    </div>
);

export default LanguageSelector;