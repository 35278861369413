import { Component, ReactNode, useEffect, useMemo, useState } from 'react';
import './Footer.css';
import { Subscription } from 'rxjs';
import { WSStatus } from '../../Types/Websocket';
import { AppStateManager } from '../../StateManager';
import { ConnectionStatus } from '../UI-Elements/StatusLights';


function formatTime(date: Date): string {
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${hours}:${minutes}:${seconds}`;
}


class ExchangeClock extends Component {
    state: {
        now: Date
    }
    subscription: Subscription | undefined;

    constructor(props) {
        super(props);
        this.state = { now: new Date() };
        this.subscription = undefined;
    }

    componentDidMount(): void {
        this.subscription = AppStateManager.MF.getHandler('time').subscribe(time => this.setState({ now: time }));
    }

    componentWillUnmount(): void {
        if (this.subscription !== undefined) this.subscription.unsubscribe();
    }

    render(): ReactNode {
        return <>{formatTime(this.state.now)}</>
    }
}

class LocalClock extends Component {
    state: { now: Date }
    interval: any;

    constructor(props) {
        super(props);
        this.state = { now: new Date() };
    }

    componentDidMount(): void {
        setTimeout(() => {
            this.interval = setInterval(() => this.setState({ now: new Date() }), 1000);
        }, 1000 - (new Date().getMilliseconds()));
    }
    componentWillUnmount(): void {
        clearInterval(this.interval);
    }

    render() {
        return <>{formatTime(this.state.now)}</>;
    }
}

// Define the Footer component
export const Footer = () => {
    // Define the type for the status light
    type lightStatus = 'var(--Market-status-closed-light)' | 'var(--Market-status-trading-light)' | 'var(--Market-status-other-light)';

    // Initialize state for MFstatus and PDSstatus
    const [MFstatus, setMFStatus] = useState<WSStatus>('disconnected');
    const [PDSstatus, setPDSStatus] = useState<Record<string, WSStatus>>({});

    // Use the useEffect hook to subscribe to status updates when the component mounts
    useEffect(() => {
        const subscriptions: Subscription[] = [];

        // If the currentSocket is not null, subscribe to its status updates
        if (AppStateManager.MF.currentSocket !== null) {
            subscriptions.push(AppStateManager.MF.currentSocket.status.subscribe(setMFStatus));

            // For each PDSsocket, subscribe to its status updates
            Object.values(AppStateManager.PDS.PDSsockets).forEach(element => {
                subscriptions.push(element.status.subscribe(
                    // Update the PDSstatus state with the new status
                    status => setPDSStatus(prev => ({ ...prev, [element.url]: status })))
                );
            });
        }

        // When the component unmounts, unsubscribe from all subscriptions
        return () => {
            subscriptions.forEach(subscription => subscription.unsubscribe());
        };
    }, []);

    // Compute the status light based on the MFstatus and PDSstatus
    const statusLight: lightStatus = useMemo(() => {
        if (MFstatus === 'connected' && Object.values(PDSstatus).every(status => status === 'connected')) {
            return ('var(--Market-status-trading-light)');
        } else if (MFstatus === 'disconnected' && Object.values(PDSstatus).every(status => status === 'disconnected')) {
            return ('var(--Market-status-closed-light)');
        } else {
            return ('var(--Market-status-other-light)');
        }
    }, [MFstatus, PDSstatus]) // Recompute when MFstatus or PDSstatus changes

    // Render the Footer component
    return (
        <footer>
            <div>Exchange time (W. Europe Standard Time) <ExchangeClock /></div>
            <div>Local time <LocalClock /></div>

            <ConnectionStatus status={statusLight} /> {/* Pass the computed status light to the ConnectionStatus component */}
        </footer>
    );
}