import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LMDInterface } from "../KodiInterface/LMD";
import { ALERTICONS } from "../Components/UI-Elements/Alert";

export async function isIcelandic(symbol: string): Promise<boolean> {
    // Only allow market by order on Icelandic Symbols
    return (await LMDInterface.getTradeableListSymbols('[all_icelandic_tradables;all_icelandic_shares]')).includes(symbol);
}

export const ICONS = {
    success: <FontAwesomeIcon icon={ALERTICONS["success"]} style={{ color: 'var(--green-600)' }} />,
    error: <FontAwesomeIcon icon={ALERTICONS["error"]} style={{ color: 'var(--red-600)' }} />,
    reconnecting: <FontAwesomeIcon icon={ALERTICONS["reconnecting"]} spin style={{ color: 'var(--yellow-900)' }} />,
}