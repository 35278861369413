import { ErrorMessage, FieldInputProps, useField, useFormikContext } from "formik";
import { SymbolSelector } from "../../Windows/MarketDepth/Components";
import { TranslationManager } from "../../../Translation/Translation";
interface IProps extends FieldInputProps<string> {
    label?: string;
    labelId?: string;
    autoFocus?: boolean;
    disabled?: boolean;
}
const SymbolSelectorWrapper: React.FC<IProps & { menuItems: { value: string, title: string }[] }> = ({ menuItems, label, disabled, ...props }) => {
    const [field, meta] = useField({ ...props, name: props.name });
    const { setFieldValue } = useFormikContext();
    return (
        <>
            <SymbolSelector
                size="md"
                placeHolder={TranslationManager.getTranslation().New_Order_Modal.Labels.Autocomplete_symbol}
                disableClearable={false}
                selectedSymbol={field.value}
                onSelect={(symbol) => setFieldValue("symbol", symbol)}
                autoFocus={props.autoFocus}
                selectWithTab={true}
                disabled={disabled}
            />
            {meta.touched && meta.error && (
                <ErrorMessage className="formik-error-message" name={props.name} component="div" />
            )}
        </>
    );
};
export default SymbolSelectorWrapper;