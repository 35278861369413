import { Component } from "react";
import "./Login.scss";
import { Header } from "../Header/Header";
import Checkbox from "../UI-Elements/Checkbox";
import { LMDInterface } from "../../KodiInterface/LMD";

import { Navigate } from "react-router-dom";
import { AppStateManager } from "../../StateManager";

import * as React from "react";
import { Environment } from "../../Config/Environment";
import Footer from "../Footer/BigFooter";
import Input from "../UI-Elements/Input";
import Button from "../UI-Elements/Button";
import { TranslationManager } from "../../Translation/Translation";
import Link from "../UI-Elements/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import { Config } from "../../Config/Config";
import Alert from "../UI-Elements/Alert";
import Dropdown from "../UI-Elements/DropDownWrapper";

export class Login extends Component {
  state: {
    error: string | React.JSX.Element | undefined;
    goToMain: boolean;
    loginLoading: boolean;
    username: string;
    password: string;
    remember: boolean;
    backend: "production" | "staging";
  };
  constructor(props) {
    super(props);
    this.state = {
      error: undefined,
      goToMain: false,
      loginLoading: false,
      username: "",
      password: "",
      remember: false,
      backend: Environment === "production" ? "production" : "staging",
    };
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.setState({ error: undefined });
    const storeToken = this.state.remember;
    this.setState({ loginLoading: true });
    //developer mode
    if (Environment !== "development") {
      Config.changeBackendType(Environment);
    } else {
      Config.changeBackendType(this.state.backend);
    }
    //get the right backend
    LMDInterface.createToken(this.state.username, this.state.password)
      .then(async (token) => {
        await AppStateManager.login(token, storeToken);
        this.setState({ goToMain: true, loginLoading: false });
      })
      .catch((error) => {
        this.setState({ loginLoading: false });
        const errorMessage = error.message;
        if (errorMessage === "invalid_client")
          this.setState({ error: "Wrong Password and/or Username" });
        else if (errorMessage === "no_access")
          this.setState({
            error: (
              <span>
                {this.state.username} does not have access to real time market
                data.
                <br />
                <span>
                  Please contact{" "}
                  <a href="mailto:info@keldan.is">info@keldan.is</a> for
                  access.
                </span>
              </span>
            ),
          });
        else if (errorMessage === "no_system") {
          this.setState({
            error: `${this.state.username} does not have access to keldan markadir`,
          });
        } else this.setState({ error: errorMessage });
      });
  }

  render() {
    return (
      <div className="login">
        {this.state.goToMain && <Navigate to={"/"} />}
        <Header login={true} active={false} />
        <div style={{ height: "100%", overflowY: "auto" }}>
          <div className="login_body">
            <div className="login-box">
              <h2>{TranslationManager.getTranslation().Login_Modal.Title}</h2>
              <h6>
                {TranslationManager.getTranslation().Login_Modal.Sub_title}
              </h6>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleSubmit(e);
                }}
                className="login-form"
                method="POST"
              >
                <div className="form__section">
                  <div className="form__body">
                    <div className="form__row">
                      <div className="form__col">
                        <Input
                          label={
                            TranslationManager.getTranslation().Login_Modal
                              .Username
                          }
                          id="username"
                          value={this.state.username}
                          onChange={(e) =>
                            this.setState({ username: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__col">
                        <Input
                          label={
                            TranslationManager.getTranslation().Login_Modal
                              .Password
                          }
                          inputType="password"
                          id="password"
                          value={this.state.password}
                          onChange={(e) =>
                            this.setState({ password: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                  </div>
                  {this.state.error && (
                    <div className="form__row" style={{ marginBottom: "30px" }}>
                      <div className="form__col">
                        <Alert
                          type={"error"}
                          headText={this.state.error}
                          withCloseIcon={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="form__actions jc-center">
                    <Button
                      noMargin
                      fullWidth
                      type="submit"
                      showLoader={this.state.loginLoading}
                      buttonType="primary"
                      size="lg"
                    >
                      {TranslationManager.getTranslation().Login_Modal.Login}
                    </Button>
                  </div>
                </div>
              </form>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "15px",
                  flexWrap: "wrap",
                  maxWidth: '304px',
                  margin: '0 auto',
                }}
              >
                <button
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    this.setState({ remember: !this.state.remember })
                  }
                >
                  <Checkbox
                    value={this.state.remember ? "true" : "false"}
                    label={
                      TranslationManager.getTranslation().Login_Modal
                        .Remember_me
                    }
                  />
                </button>
                <Link linkSize="16" url="/new-password">
                  <FontAwesomeIcon icon={faAngleRight} />
                  {TranslationManager.getTranslation().Login_Modal.New_password}
                </Link>
              </div>
              {Environment === "development" && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "20px",
                    justifyContent: "center",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    Backend connection:
                  </span>
                  <Dropdown
                    width="120px"
                    initialValue={this.state.backend}
                    onChange={(e) => { this.setState({ backend: e }) }}
                    size="small"
                    menuItems={[
                      { value: "production", title: "Production" },
                      { value: "staging", title: "Staging" }
                    ]}
                  />
                </div>
              )}
            </div>
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}
