import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SvgIcon } from '@mui/material';

import { styled } from '@mui/system';

const StyledSvgIcon = styled(SvgIcon)(({ theme }) => ({
    fontSize: 12,
    marginRight: 6,
    width: 14,
    height: 14,
    padding: 2,
    borderRadius: "50%",
    zIndex: 9,
    cursor: 'pointer', // Ensure the cursor changes to indicate interactivity
    '&.MuiSelect-icon:hover': {
        backgroundColor: 'var(--blue-200)',
        color: 'var(--blue-600)',
    },
}));

const DropdownIcon: React.FC = (props) => {
    return (
        <StyledSvgIcon {...props}>
            <FontAwesomeIcon icon={faChevronDown} />
        </StyledSvgIcon>
    );
};

export default DropdownIcon;