import React, { useState } from "react";
import "./SegmentedControl.css"; // For styling and animation

type Segment = {
    label: string;
    value: string;
};

interface SegmentedControlProps {
    segments: Segment[];
    onSegmentChange: (value: string) => void;
}

const SegmentedControl: React.FC<SegmentedControlProps> = ({
    segments,
    onSegmentChange,
}) => {
    const [selectedIndex, setSelectedIndex] = useState(0);

    const handleClick = (index: number) => {
        setSelectedIndex(index);
        onSegmentChange(segments[index].value);
    };

    return (
        <div className="segmented-control">
            <div
                className="segmented-control-slider"
                style={{
                    transform: `translateX(${selectedIndex * 100}%)`,
                    width: `${100 / segments.length}%`,
                }}
            />
            {segments.map((segment, index) => (
                <button
                    key={segment.value}
                    className={`segmented-control-item ${index === selectedIndex ? "active" : ""
                        }`}
                    onClick={() => handleClick(index)}
                >
                    {segment.label}
                </button>
            ))}
        </div>
    );
};

export default SegmentedControl;
