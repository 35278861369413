import { Autocomplete, Box, TextField } from "@mui/material";
import cx from "classnames";
import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons";
interface IProps {
    label?: string;
    labelId?: string;
    height?: string;
    width?: string;
    size?: 'small' | 'medium';
    onChange: (value: string) => void;
    menuItems: { value: string, title: string }[];
    initialValue?: string;
}
const Dropdown: React.FC<IProps> = ({ menuItems, label, height, width, size = "small", onChange, initialValue, ...props }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    //check if initialValue is in menuItems
    const isInitialValueValid = initialValue && menuItems.find(option => option.value === initialValue)?.title;
    const [selectedValue, setSelectedValue] = React.useState<string>(isInitialValueValid ? isInitialValueValid : menuItems[0].title);
    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.target.blur(); // Remove focus from the input element
        deselectText(); // Deselect any selected text
    };
    const deselectText = () => {
        if (window.getSelection) {
            // For modern browsers
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
            }
        } else {
            // For older versions of IE
            const selection = document.getSelection();
            if (selection) {
                selection.removeAllRanges();
            }
        }
    };
    const inputRef = useRef<HTMLInputElement | null>(null);
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                inputRef.current.blur();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    return (
        <div ref={inputRef} >
            <Autocomplete
                popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
                options={menuItems.map((option) => option.title)}
                sx={{
                    padding: 0,
                    width: width,
                    '& input': {
                        height: 30,
                    },
                }}
                onOpen={() => {
                    deselectText(); // Deselect any selected text when dropdown is opened
                    setIsOpen(true);
                }}
                onClose={() => setIsOpen(false)}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps, style: { userSelect: 'none', pointerEvents: "none" },
                            //so that the input field is not selectable
                            onFocus: handleFocus
                        }}
                        style={{ width: '100%', userSelect: 'none' }}
                        className={cx('autocomplete-custom readonly', { 'isOpen': isOpen })} />
                }
                value={selectedValue}
                size={size}
                open={isOpen}
                disableClearable
                renderOption={(props, symbol) => (
                    <Box component="li" {...props} sx={{
                        color: 'var(--dark-900, #232530)',
                        fontSize: '11px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        {symbol}
                    </Box >
                )}
                onChange={(event, val) => {
                    setSelectedValue(val);
                    //find item in menuItems with title equal to val and return its value
                    const item = menuItems.find(option => option.title === val);
                    if (item) {
                        onChange(item.value);
                    }
                }}
            />
        </div>
    );
};
export default Dropdown;