import { format } from "date-fns";
import ISLocale from "date-fns/locale/is";
import enGB from "date-fns/locale/en-GB";

import React, { useRef } from "react";
import * as Highcharts from "highcharts/highstock";
import { formatChartNumber } from "../../../Utils/Formatting";
import { TranslationManager } from "../../../Translation/Translation";

///v1/market_data/v1/tradables/${symbol}/history_timeseries_short/?from_date=${formatDayDateForURL(date)

type DataPoint = { date: Date; price: number; volume: number };

const theme = {
  chart: {
    backgroundColor: "var(--white)",
    style: {
      color: "white",
      stroke: "none",
    },
  },
  lang: {
    decimalPoint: ".",
    thousandsSep: ",",
    numericSymbols: ["k", "M", "B", "T"],
    rangeSelectorZoom: "",
  },
  rangeSelector: {
    labelStyle: {
      opacity: 1,
    },
    buttonTheme: {
      r: 6, // border radius
      fill: "var(--blue-100)",
      style: {
        fontFamily: "Roboto",
        fontSize: "11px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "normal",
        color: "var(--blue-900)", // Add this line to change the color of the text inside the buttons
      },
      textTransform: "uppercase",
      states: {
        hover: {
          fill: "var(--blue-200)",
        },
        select: {
          style: {
            // color: 'white',
            fill: "var(--blue-900)",
          },
          fill: "var(--blue-200)",
        },
      },
    },
  },
  scrollbar: { enabled: false },

  tooltip: {
    label: "",
    enabled: true,
    opacity: 0,
    formatter: function () {
      const isValidDrag = this.series.chart.pointer.hasDragged && !this.series.chart.pointer.runChartClick;
      // update the start every time the mouse moves
      // so that we have the initial state where the mouse was clicked
      if (!this.series.chart.pointer.hasDragged) {
        this.series.chart.startX = this.x;
        this.series.chart.startY = this.y;
      }
      //if the mouse starts moving
      if (isValidDrag) {
        //disable the crosshair for the x-axis
        this.series.chart.xAxis[0].crosshair = false;
        // Record the final x and y positions
        this.series.chart.endX = this.x;
        this.series.chart.endY = this.y;

        //calculate values
        const isViceVersa = this.series.chart.startX > this.series.chart.endX;
        const color = this.series.chart.startY === this.series.chart.endY
          ? 'transparent'
          : isViceVersa
            ? this.series.chart.startY > this.series.chart.endY
              ? 'var(--green-100)'
              : 'var(--red-100)'
            : this.series.chart.startY < this.series.chart.endY
              ? 'var(--green-100)'
              : 'var(--red-100)';

        // Remove existing plot lines
        this.series.chart.xAxis[0].removePlotLine('start-plot-line-x');
        this.series.chart.xAxis[0].removePlotLine('end-plot-line-x');
        this.series.chart.xAxis[0].removePlotBand('plot-band-x');

        this.series.chart.xAxis[0].addPlotBand({
          color: color,
          from: this.series.chart.startX,
          to: this.series.chart.endX,
          id: 'plot-band-x',
        });

        // Add plot lines at the start and end positions for x-axis
        this.series.chart.xAxis[0].addPlotLine({
          value: this.series.chart.startX,
          dashStyle: 'dash',
          color: 'var(--blue-600)',
          width: 2,
          id: 'start-plot-line-x',
        });

        this.series.chart.xAxis[0].addPlotLine({
          value: this.series.chart.endX,
          dashStyle: 'dash',
          color: 'var(--blue-600)',
          width: 2,
          id: 'end-plot-line-x',
        });

      } else {
        // Reset start and end x and y positions
        this.series.chart.endX = undefined;
        this.series.chart.endY = undefined;

        //when not dragging enable crosshair
        this.series.chart.xAxis[0].crosshair = true;

        // Remove all plot lines if not dragging
        this.series.chart.xAxis[0].removePlotLine('start-plot-line-x');
        this.series.chart.xAxis[0].removePlotLine('end-plot-line-x');
        this.series.chart.xAxis[0].removePlotBand('plot-band-x');
      }
      const isViceVersa = this.series.chart.startX > this.series.chart.endX;
      const diff = isViceVersa ? this.series.chart.startY - this.series.chart.endY : this.series.chart.endY - this.series.chart.startY;
      //calculate percentage
      const ratio = isViceVersa ? diff / this.series.chart.endY : diff / this.series.chart.startY;
      const formattedDiff = diff > 0 ? `+${diff.toFixed(2)}` : diff.toFixed(2);
      const color = diff === 0 ? 'var(--dark-400)' : diff > 0 ? 'var(--green-600)' : 'var(--red-600)';
      function formatDate(date: number | Date, locale: string) {
        return format(
          date,
          "dd. MMM yyyy",
          {
            locale: locale === "IS" ? ISLocale : enGB,
          }
        );
      }
      return !isValidDrag ? `
                <div class="chart-tooltip">
                    ${this.points?.reduce(
        function (s, point) {
          if (point.series.name === "Volume") {
            return `${s}<p class="tooltip-title"><span style="color: ${point.color
              }">${TranslationManager.getTranslation().Chart.Volume
              }:</span> <span className="tooltip-value">${formatChartNumber(
                point.y,
                null,
                0
              )}</span></p>`;
          } else {
            return `${s}<p class="tooltip-title"><span style="color: ${point.color
              }">${TranslationManager.getTranslation().Chart.Price
              }:</span> <span className="tooltip-value">${formatChartNumber(
                point.y,
                null,
                3
              )}</span></p>`;
          }
        },
        `<p class="tooltip-date">${format(
          this.x,
          "EEEE, dd. MMM yyyy",
          {
            locale:
              TranslationManager.getActiveLanguage() === "IS"
                ? ISLocale
                : enGB,
          }
        )}</p>`
      )}` : `<div class="chart-tooltip"
              style="
                  color: var(--dark-900);
                  display: flex;
                  flex-direction: column;
                ">
                ${!isViceVersa ? `
                <span>
                  ${TranslationManager.getTranslation().Chart.Start}: ${formatDate(new Date(this.series.chart.startX), TranslationManager.getActiveLanguage())} - ${formatChartNumber(this.series.chart.startY)}
                </span>
                  <span>
                  ${TranslationManager.getTranslation().Chart.End}: ${formatDate(new Date(this.series.chart.endX), TranslationManager.getActiveLanguage())} - ${formatChartNumber(this.series.chart.endY)}
                  </span>
                ` : `
                <span>
                    ${TranslationManager.getTranslation().Chart.Start}: ${formatDate(new Date(this.series.chart.endX), TranslationManager.getActiveLanguage())} - ${formatChartNumber(this.series.chart.endY)}
                  </span>
                  <span>
                    ${TranslationManager.getTranslation().Chart.End}: ${formatDate(new Date(this.series.chart.startX), TranslationManager.getActiveLanguage())} - ${formatChartNumber(this.series.chart.startY)}
                  </span>
                `}
                  <span>
                    <span style="color: ${color};">${formattedDiff}</span>
                    <span style="color: ${color};">(${(Math.abs(ratio * 100)).toFixed(2)}%)</span>
                  </span>
                    </div>
            </div>`
    },
    borderWidth: 0,
    shadow: false,
    useHTML: true,
    shared: true,
  },
};
Highcharts.setOptions(theme);
export function HighChart(props: { points: DataPoint[] }) {
  const refChartContainer = React.createRef<any>();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  // After main div has been rendered set a callback
  // for when it is resized so height and width
  React.useEffect(() => {
    const element = refChartContainer.current as HTMLElement;

    const resizeObserver = new ResizeObserver(() => {
      const { height, width } = element.getBoundingClientRect();
      setWidth(width);
      setHeight(height);
    });
    resizeObserver.observe(element);
    return () => resizeObserver.unobserve(element);
  }, [refChartContainer]);

  const divRef = useRef<HTMLDivElement>(null);

  const xAxisExtremes = useRef<{ min: number | undefined; max: number | undefined }>({
    min: undefined,
    max: undefined,
  });

  React.useEffect(() => {
    Highcharts.setOptions({
      lang: {
        resetZoomTitle: "",
      },
      xAxis: {
        events: {
          afterSetExtremes: function (e: any) {
            if (e.type !== 'afterSetExtremes') {
              xAxisExtremes.current.min = e.min;
              xAxisExtremes.current.max = e.max;
              if (e.target.coll !== 'yAxis') {
                this.chart.yAxis[0].setExtremes(
                  this.chart.yAxis[0].getExtremes().dataMin,
                  this.chart.yAxis[0].getExtremes().dataMax
                );
              }
            }
          },
        },
      },
    });
    if (divRef.current !== null) {
      Highcharts.stockChart(
        divRef.current,
        {
          chart: {
            panning: {
              enabled: false,
            },
            animation: false, // Would make the chart look less janky when data is updated
            events: {
              load: function () {
                const chartContainer = this.container;
                const chart = this;
                Highcharts.addEvent(chartContainer, 'mousedown', function (e: MouseEvent) {
                  //set runClick to false to prevent chart from adding plotlines and plotbands
                  //@ts-ignore
                  chart.pointer.runChartClick = true;
                  //since the highchart is broken and you can't enable pankey without zooming for some reason
                  if (e.shiftKey) {
                    // enable panning on x-axis
                    chart.update({
                      chart: {
                        panning: {
                          enabled: true,
                          type: 'x'
                        }
                      }
                    })
                  }
                  else {
                    //@ts-ignore
                    chart.pointer.runChartClick = false;
                    // disable panning on x-axis
                    chart.update({
                      chart: {
                        panning: {
                          enabled: false,
                        }
                      }
                    })
                  }
                });
                // If there was no data for today before now
                // set x-axis to show today once we have data if user was viewing most recent point
                if (
                  props.points[props.points.length - 2] &&
                  xAxisExtremes.current.max === props.points[props.points.length - 2].date.getTime()
                ) {
                  this.xAxis[0].setExtremes(
                    xAxisExtremes.current.min,
                    props.points[props.points.length - 1].date.getTime()
                  );
                }
                if (xAxisExtremes.current.min !== undefined && xAxisExtremes.current.max !== undefined) {
                  this.xAxis[0].setExtremes(xAxisExtremes.current.min, xAxisExtremes.current.max);
                }
                this.yAxis[0].setExtremes(
                  this.yAxis[0].getExtremes().dataMin,
                  this.yAxis[0].getExtremes().dataMax
                );
              },
            },
            height: height,
            width: width,
            reflow: true,
            style: {
              fontFamily: "Roboto",
            },
            selectionMarkerFill: "#4569EE18",
            plotBackgroundColor: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0.05, "#FFF"],
                [0.95, "#F8F8FA"],
              ],
            },
          },

          credits: {
            enabled: false,
          },
          title: {
            text: "",
          },
          navigator: {
            enabled: true,
            adaptToUpdatedData: true,
            handles: {
              height: 22,
              width: 6,
            },
            maskFill: "#D4DEFF80",
            series: {
              lineWidth: 0,
              opacity: 0,
            },
            height: 20,
            xAxis: {
              labels: {
                style: {
                  color: "#7B7C83",
                  fontSize: "10px",
                },
                y: -6,
              },
              gridLineWidth: 0,
              minTickInterval: 1000 * 60 * 60 * 24 * 365,
              lineWidth: 0,
            },
            yAxis: {
              lineWidth: 0,
            },
            outlineWidth: 0,
          },
          scrollbar: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            borderWidth: 0,
            shadow: false,
            shared: true,
          },
          xAxis: {
            type: "datetime",
            minTickInterval: 1000 * 60 * 60 * 24 * 1,
            tickWidth: 0,
            lineWidth: 0,
          },
          yAxis: [
            {
              labels: {
                enabled: true,
                formatter: function () {
                  return formatChartNumber(this.value as number, null, 3) as string;
                },
                style: {
                  color: "var(--chart-axis-text, #000000)",
                  fontFamily: "Roboto",
                  fontSize: "9px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                },
              },
              minTickInterval: 0,
              gridLineDashStyle: "Dash",
              gridLineWidth: 1,
              gridLineColor: "#D3D3D6",
              opposite: true,
              height: "70%",
            },
            {
              labels: {
                enabled: true,
                style: {
                  color: "var(--chart-axis-text, #000000)",
                  fontFamily: "Roboto",
                  fontSize: "9px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                },
              },
              minTickInterval: 1,
              gridLineDashStyle: "Dash",
              gridLineWidth: 1,
              gridLineColor: "#D3D3D6",
              opposite: true,
              top: "70%",
              height: "30%",
              offset: 0,
            },
          ],
          plotOptions: {
            series: {
              color: "#4569EE",
              enableMouseTracking: true,
              marker: {
                enabled: false,
              },
              animation: false,
              turboThreshold: 0,
              dataGrouping: {
                enabled: false,
              },
            },
          },
          legend: {
            enabled: false,
          },
          rangeSelector: {
            buttons: [
              {
                type: "day",
                count: 1,
                text: "1d",
              },
              {
                type: "week",
                count: 1,
                text: "1w",
              },
              {
                type: "month",
                count: 1,
                text: "1m",
              },
              {
                type: "month",
                count: 6,
                text: "6m",
              },
              {
                type: "year",
                count: 1,
                text: "1y",
              },
              {
                type: "all",
                text: "All",
              },
            ],
            allButtonsEnabled: true,
            inputEnabled: false,
            selected: 3,
          },
          series: [
            {
              type: "area",
              name: "Price",
              color: "#4569EE",
              lineWidth: 3,
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0, "rgba(69, 105, 238, 0.20)"],
                  [0.8469, "rgba(69, 105, 238, 0.00)"],
                ],
              },
              data: props.points.map((point) => [
                point.date.getTime(),
                point.price,
              ]), //[point.date.getTime(), point.price])
            },
            {
              type: "column",
              grouping: false,
              name: "Volume",
              color: "#232530",
              data: props.points.map((point) => [
                point.date.getTime(),
                point.volume,
              ]),
              yAxis: 1,
            },
          ],
        },
        () => { }
      );
    }
  }, [props.points, divRef, width, height]);

  return (
    <div
      className="fill"
      style={{ overflow: "hidden" }}
      ref={refChartContainer}
    >
      <div className="chart" ref={divRef}></div>
    </div>
  );
}
