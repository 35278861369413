// getCustomer

export type RetailCustomerResponse = RetailCustomerSuccess | RetailCustomerError;

interface Account {
    id: number;
    name: string;
    number: string;
    currency: string;
    is_default: boolean;
}

export interface Portfolio {
    id: number;
    name: string;
    currency: string;
    description: string;
    portfolio_ssn: string;
    is_default: boolean;
    accounts: Account[];
}

export interface RetailCustomerSuccess {
    customer_id: number;
    name: string;
    username: string;
    ssn: string;
    system: string;
    customer_type: string;
    user_id: number;
    group_id: number;
    group_name: string;
    trader_id: number;
    portfolios: Portfolio[];
    accounts: Account[];
}

export interface RetailCustomerError {
    text: string;
    input: string;
    reason: string;
}

// api/portfolio/overview

export interface Position {
    symbol: string;
    instrument: string;
    currency: string;
    asset_class: string;
    holdings: number;
    can_over_sell: boolean;
    position_total_value: number;
    profit_loss_amount: number;
    profit_loss_percent: number;
    reference_price: number;
    average_purchase_price: number;
    unsettled_buy_quantity: number;
    unsettled_sell_quantity: number;
    has_access: boolean;
    active_buy_quantity: number;
    active_sell_quantity: number;
    unallocated_buy_quantity: number;
    unallocated_sell_quantity: number;
    shares_on_hand: number;
    from_date: string;
    to_date: string;
    contribution: number;
    in_flow: number;
    out_flow: number;
    net_in_flow: number;
    net_profit: number;
    start_of_period_total_value: number;
    profit: number;
    realized_profit: number;
    tax: number;
}

export interface EndOfDayPrice {
    date: string;
    value: number;
}

export interface PortfolioOverviewSuccess {
    id: number;
    currency: string;
    value: number;
    has_access: boolean;
    unsettled_buy_amount: number;
    unsettled_sell_amount: number;
    net_in_flow: number;
    profit: number;
    real_return: number;
    realized_profit: number;
    return: number;
    tax: number;
    start_of_period_total_value: number;
    from_date: string;
    to_date: string;
    positions: Position[];
    end_of_day_prices: EndOfDayPrice[];
}

export type PortfolioOverviewResponse = PortfolioOverviewSuccess | RetailCustomerError;

export function isPortfolioOverviewSuccess(response: PortfolioOverviewResponse): response is PortfolioOverviewSuccess {
    return (response as PortfolioOverviewSuccess).positions !== undefined;
}

export type PortfolioResponse = Portfolio[] | RetailCustomerError;