// Package imports:
import React from 'react';

// Component imports:
import "./NewPassword.scss";
import NewPasswordForm from './NewPasswordForm';
import Link from '../UI-Elements/Link';
import { Header } from '../Header/Header';
import Footer from '../Footer/BigFooter';
import { TranslationManager } from '../../Translation/Translation';

const NewPassword: React.FC = () => {

    return (
        <div className='main KCL_new_password-page'>
            <Header login={true} active={false} />
            <div className="section section--primary">
                 <div className="section__body">
                    <div className="shell">
                        <div className="new_password_container">
                            <h2>{TranslationManager.getTranslation().Login_Modal
                              .New_password}</h2>
                            <NewPasswordForm />
                            <p className="note paragraph-small">
                                {TranslationManager.getTranslation().New_Password.Affected_systems}
                            </p>
                            <div className='keldan__links'>
                                <Link linkSize='16' url="https://keldan.is" target="_blank">Keldan.is</Link>
                                <Link linkSize='16' url="/" target="_blank">Keldan Markaðir</Link>
                                <Link linkSize='16' url="https://kodiak.is/kodiak-pro.html" target="_blank">KODIAK Pro</Link>
                            </div>
                        </div>
                    </div>
                    <Footer />
                 </div>
            </div>
        </div>
    )
}

export default NewPassword;