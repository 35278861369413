import React, { useEffect, useRef, useState } from 'react';
import { Box, TextField, Dialog } from '@mui/material';
import Button from '../UI-Elements/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { TranslationManager } from '../../Translation/Translation';
import ClearInputIcon from '../UI-Elements/ClearInputIcon';

const InputModal: React.FC<{ open: boolean, onClose: () => void, onSave: (value: string) => void, headerTitle: string }> = ({ open, onClose, onSave, headerTitle }) => {
    const [inputValue, setInputValue] = useState('');
    const inputValueRef = useRef(inputValue);

    const handleSave = () => {
        onSave(inputValueRef.current);
        setInputValue('');
        onClose();
    };

    const handleCancel = () => {
        setInputValue('');
        onClose();
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && inputValueRef.current.length > 0) {
            event.preventDefault();
            handleSave();
        }
    };

    useEffect(() => {
        inputValueRef.current = inputValue;
    }, [inputValue]);

    useEffect(() => {
        if (open) {
            window.addEventListener('keydown', handleKeyDown);
        } else {
            window.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <Box className='KM_modal' sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 695,
                bgcolor: 'background.paper',
                boxShadow: 24,
                borderRadius: '24px',
                p: 5,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
                    <h1 className="modal_title" style={{ marginBottom: 0 }}>{headerTitle}</h1>
                    <FontAwesomeIcon
                        className="modalCloseButton"
                        onClick={() => handleCancel()}
                        icon={faXmark}
                    />
                </Box>
                <Box sx={{ display: 'flex', alignSelf: 'stretch', alignItems: 'center', marginTop: '16px' }}>
                    <TextField
                        size='medium'
                        sx={{ display: 'flex', flexGrow: 1, marginTop: '4px', height: '30px' }}
                        label={TranslationManager.getTranslation().WatchList.MyLists.NewListLabel}
                        variant="outlined"
                        autoFocus
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}

                    />{
                        inputValue.length > 0 &&
                        <div style={{ position: 'relative', width: 0 }}>
                            <ClearInputIcon style={{ position: 'absolute', left: '-24px', top: '1px' }} onClick={() => setInputValue("")} />
                        </div>
                    }

                    <Button disabled={inputValue.length === 0} size='sm' buttonType="primary" onClick={handleSave}>{TranslationManager.getTranslation().WatchList.MyLists.SaveList}</Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default InputModal;