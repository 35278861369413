import React, { ChangeEvent, } from 'react';
import { NewsWindowComponent } from "../AbstractWindow";
import { Autocomplete, Box, OutlinedInput, TextField } from '@mui/material';
import { Subscription } from 'rxjs';
import NewsContainer, { SaveSourceState } from './NewsContainer';
import { INewsFeedSourceFilter } from '../../../Types/NewsType';
import { TranslationManager } from '../../../Translation/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import cx from 'classnames';
import ClearInputIcon from '../../UI-Elements/ClearInputIcon';

type SaveState = {
  symbol: string | undefined;
  linked: boolean;
  category: string;
  sources: SaveSourceState;
};

type RunState = {
  isFocused?: boolean;
  symbol?: string;
  tableParentRef: React.RefObject<HTMLDivElement>;
  loading: boolean;
  search: string;
  category: string;
  sources: SaveSourceState
}

export type CategoryKeys = 'is_fish' | 'is_pod' | 'is_bus' | 'is_gen' | 'is_spo';

type ValueType = {
  domesticOnly: string;
  foreignOnly: string;
  category: CategoryKeys;
  domesticUsage: string;
};

type CategoriesValue = {
  [K in CategoryKeys]: ValueType;
};

export class News extends NewsWindowComponent<RunState, SaveState> {
  linkSubscriptions: Subscription[];
  intervalId: NodeJS.Timer | undefined;
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      loading: false,
      linked: false,
      search: "",
      category: this.state.category ?? 'is_bus',
      sources: this.state.sources,
      isFocused: false,
    };
    this.intervalId = undefined;
    this.linkSubscriptions = [];
  }
  async setNewSymbol(symbolOrList: string) {
    this.setState({ loading: true })
    if (this.state.linked) {
      this.setState({ symbol: symbolOrList, search: symbolOrList });
      this.subscriptions.forEach(subscription => subscription.unsubscribe());
      this.subscriptions = [];
    }
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target.value })
  };

  handleSelectChange = (event: string) => {
    this.setState({ category: event });
    this.saveState({
      linked: this.state.linked,
      symbol: this.state.symbol,
      category: event,
      sources: this.state.sources
    })
  };

  categoriesValue: CategoriesValue = {
    'is_bus': {
      domesticOnly: "false",
      foreignOnly: "true",
      category: "is_bus",
      domesticUsage: "true"
    }, 'is_gen': {
      domesticOnly: "true",
      foreignOnly: "false",
      category: "is_gen",
      domesticUsage: "true"
    }, 'is_spo': {
      domesticOnly: "false",
      foreignOnly: "true",
      category: "is_spo",
      domesticUsage: "true"
    }, 'is_fish': {
      domesticOnly: "false",
      foreignOnly: "false",
      category: "is_fish",
      domesticUsage: "true"
    }, 'is_pod': {
      domesticOnly: "false",
      foreignOnly: "true",
      category: "is_pod",
      domesticUsage: "true"
    },
  }
  handleSaveSources = (source: INewsFeedSourceFilter[]) => {
    const updatedSources: SaveSourceState = {
      ...this.state.sources
    };
    updatedSources[this.state.category] = source;
    this.setState({ sources: updatedSources })
    this.saveState({
      linked: this.state.linked,
      symbol: this.state.symbol,
      category: this.state.category,
      sources: updatedSources
    });
  }
  inputRef = React.createRef<HTMLInputElement>();

  render() {
    return (
      <div className="window" ref={this.state.tableParentRef}>
        <div className="fill" style={{ display: "flex" }}>
          <div style={{ display: "flex", gap: "10px" }}>
            <CategoryDropDown
              category={this.state.category}
              handleChange={(e: string) =>
                this.handleSelectChange(e)
              }
            />
            <OutlinedInput
              inputProps={{ style: { height: "13px", fontSize: "11px" } }}
              placeholder={TranslationManager.getTranslation().Search}
              id="filled-hidden-label-small"
              defaultValue={this.state.search}
              size={"small"}
              value={this.state.search}
              endAdornment={this.state.search === "" ? (
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{ width: "10px" }}
                />
              ) : <ClearInputIcon isFocused={this.state.isFocused} onClick={() => {
                this.setState({ search: "" });
                //make the input focus again
                this.inputRef.current?.focus();
              }} />
              }
              onFocus={() => this.setState({ isFocused: true })}
              onBlur={() => this.setState({ isFocused: false })}
              onChange={this.handleInputChange}
              inputRef={this.inputRef}
            />
          </div>
          <div>{this.linkedButton()}</div>
        </div>
        {
          <NewsContainer
            saveSourceState={(source) => this.handleSaveSources(source)}
            initialSources={this.state.sources}
            searchInput={this.state.search}
            refreshRateMs={1 * 60 * 1000} // 1min
            newsFeedCategoryDetails={
              this.categoriesValue[this.state.category]
            }
          />
        }
      </div>
    );
  }
}

const CategoryDropDown: React.FC<{
  handleChange: (e: string) => void,
  category: string
}> = ({ handleChange, category }) => {
  interface Option {
    title: string;
    value: string;
  }
  const available_options: Option[] = [
    { title: TranslationManager.getTranslation().News.Category_DropDown.Business, value: 'is_bus' },
    { title: TranslationManager.getTranslation().News.Category_DropDown.General, value: 'is_gen' },
    { title: TranslationManager.getTranslation().News.Category_DropDown.Sports, value: 'is_spo' },
    { title: TranslationManager.getTranslation().News.Category_DropDown.Fishing_industry, value: 'is_fish' },
    { title: TranslationManager.getTranslation().News.Category_DropDown.Podcast, value: 'is_pod' }
  ];
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <Autocomplete
      autoHighlight
      popupIcon={<FontAwesomeIcon icon={faChevronDown} style={{ fontSize: '10px' }} />}
      options={available_options.map((option) => option.title)}
      sx={{
        padding: 0,
        width: 120,
        '& input': {
          height: 10
        },
      }}
      // open
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      renderInput={(params) =>
        <TextField
          {...params}
          style={{ width: '100%' }}
          className={cx('autocomplete-custom', { 'isOpen': isOpen })} />
      }
      // disableClearable
      value={available_options.find((option) => option.value === category)?.title ?? ''}
      size='small'

      disableClearable
      renderOption={(props, symbol) => (
        <Box component="li" {...props} sx={{
          color: 'var(--dark-900, #232530)',
          fontSize: '11px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: 'normal',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          {symbol}
        </Box >
      )}

      onChange={(event, newValue) => handleChange(available_options.find(option => option.title === newValue)?.value as string)}
    />);
}

export default CategoryDropDown;


