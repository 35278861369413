import { formatPercent, formatNumber, formatWithLetters } from '../../../Utils/Formatting';
import { TranslationManager } from '../../../Translation/Translation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownLong, faUpLong } from '@fortawesome/pro-solid-svg-icons';

export function formatWatchListCell(column, row, prevValue, lastAnimationID: number, flicker: boolean): React.JSX.Element {
    let value = row[column];
    const columns = Object.keys(row);
    const style = {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    }

    if (typeof value === 'number') {
        // All kinds of number formating for all kinds of columns
        let str = '';
        style['justifyContent'] = 'flex-end';

        if (['market_capital', 'intraday_accumulated_turnover', 'intraday_accumulated_volume'].includes(column))
            str = formatWithLetters(value);
        else if (column === 'last_price_diff_closing_percent') {
            str = formatPercent(value);
            if (value < 0) style['color'] = 'var(--red)';
            else if (value > 0) style['color'] = 'var(--green-600)';
        } else
            str = formatNumber(value);


        // Flicker animation on rising or lowering prices
        if (flicker && (prevValue !== undefined && prevValue !== value && column !== 'last_price_diff_closing_percent')) {
            if (prevValue < value)
                style['animationName'] = `up-animation-${lastAnimationID}`;
            else
                style['animationName'] = `down-animation-${lastAnimationID}`;
            style['animationTimingFunction'] = 'ease';
            style['animationDuration'] = `1.75s`;
        }
        const withArrow = () => {
            if (value < 0) return <FontAwesomeIcon icon={faDownLong} style={{ color: 'var(--red-600)', fontSize: '9px', lineHeight: 'normal' }} />;
            else if (value > 0) return <FontAwesomeIcon icon={faUpLong} style={{ color: 'var(--green-600)', fontSize: '9px', lineHeight: 'normal' }} />;
            else return;
        }
        if (column === 'last_price_diff_closing_percent') {
            style['gap'] = '5px';
            return <div style={style}>{withArrow()} {str}</div>
        }
        //we don't want 'last_yield_diff_closing_basispoints' to be formatted as a percentage
        if (column.includes('yield') && column !== 'last_yield_diff_closing_basispoints') {
            str = formatPercent(value);
        }
        return <div style={style}>{str}</div>;
    } else {
        if (['Name', 'orderbook_state', 'symbol'].includes(column) && columns.includes('orderbook_state')) {
            if (column === 'orderbook_state') value = TranslationManager.getTranslation().OrderbookState[value || ''];
            // Trading status color
            const state = row['orderbook_state'];
            if (state === "closed" || state === "not_available") style['color'] = 'var(--red-600)';
            else if (state === "trading") style['color'] = 'var(--green-600)';
            else style['color'] = 'var(--orange)';
            return <div style={style}>{value}</div>;
        } else if (column === 'change') {
            const priceDiff: number | undefined = row['last_price_diff_closing_percent'];
            if (priceDiff === undefined) {
                return <div></div>
            } else if (priceDiff > 0) {
                return <div className='center-container fill'><FontAwesomeIcon icon={faUpLong} style={{ color: 'var(--green-600)', fontSize: '9px', lineHeight: 'normal' }} /></div>
            } else if (priceDiff < 0) {
                return <div className='center-container fill'><FontAwesomeIcon icon={faDownLong} style={{ color: 'var(--red-600)', fontSize: '9px', lineHeight: 'normal' }} /></div>
            } else {
                return <div></div>
            }
        } else
            return <div style={style}>{value}</div>;
    }
}