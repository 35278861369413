// ChatGPT
export function convertKODITimeStringToDate(timeString: string): Date {
    const currentDate = new Date();
    const [hours, minutes, seconds, milliseconds] = timeString.split(":").map(Number);
  
    // Set the time components to the current date
    currentDate.setHours(hours || 0);
    currentDate.setMinutes(minutes || 0);
    currentDate.setSeconds(seconds || 0);
    currentDate.setMilliseconds(milliseconds || 0);
  
    return currentDate;
  }

export function formatNewsDate(dateString: string): string {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    // Check if the input date is the same day as the current date
    const sameDay = inputDate.getDate() === currentDate.getDate() &&
                    inputDate.getMonth() === currentDate.getMonth() &&
                    inputDate.getFullYear() === currentDate.getFullYear();

    if (sameDay) {
        // If it's the same day, return the time in HH:MM:SS format
        return inputDate.toISOString().substring(11, 19);
    } else {
        // If it's not the same day, return the date in DD:MM:YYYY format
        const day = inputDate.getDate();
        const month = inputDate.getMonth() + 1; // Months are 0-based in JS
        const year = inputDate.getFullYear();

        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year}`;
    }
}