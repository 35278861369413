// Package imports:
import React from 'react';
import cx from 'classnames';
import { TranslationManager } from '../../Translation/Translation';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import { Button, Popover } from '@mui/material';



const LanguageSelectDropdown: React.FC = () => {
    const LANG = TranslationManager.getActiveLanguage();
    const FLAG = LANG === 'IS' ? 'ISK' : 'GBP'

    const handleLanguageChange = (lang: 'IS' | 'EN') => {
        if (lang === LANG) return;
        else TranslationManager.setActiveLanguage(lang);
    }
    return (
        <PopupState variant="popover">
            {(popupState) => (
                <>
                    <Button
                        className="noHoverButton"
                        disableRipple
                        size="small"
                        variant="text"
                        {...bindTrigger(popupState)}
                    >
                        <div className='language-dropdown-button'>
                            <img
                                src={`https://cdn.livemarketdata.com/Avatars/Flags/${FLAG}.svg`}
                                width="20"
                                height="20"
                            />
                            <FontAwesomeIcon
                                className={cx("fa-icon rotate", {
                                    "rotate-180": popupState.isOpen,
                                })}
                                style={{ marginLeft: "8px" }}
                                icon={faAngleDown}
                            />
                        </div>
                    </Button>
                    <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <div className='language-dropdown'>
                            {
                                ['IS', 'EN'].map((lang) => (
                                    <div
                                        className='language-item'
                                        key={lang}
                                        onClick={() => {
                                            handleLanguageChange(lang as 'IS' | 'EN');
                                            popupState.close();
                                        }}
                                    >
                                        <img
                                            src={`https://cdn.livemarketdata.com/Avatars/Flags/${lang === 'IS' ? 'ISK' : 'GBP'}.svg`}
                                            width="20"
                                            height="20" />
                                        <span>{lang}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </Popover>
                </>

            )
            }
        </PopupState>
    );
}

export default LanguageSelectDropdown;