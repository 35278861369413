/**
 * Private Data Streamer API
 * Private Data Streamer Swagger API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: dagur@kodi.is
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CommissionType } from './commissionType';
import { OrderType } from './orderType';
import { SideTypes } from './side';
import { TimeInForceTypes } from './timeInForce';

/**
 * Represents an order submission request
 */
export interface OrderSubmitRequest {
    /**
     * Reference defined by the client to identify orders
     */
    reference_id?: string;
    /**
     * The symbol/ticker for which this order applies
     */
    symbol: string;
    side: SideTypes;
    /**
     * The minimum/maximum price for which this order can execute at Can not be set if the order type = market
     */
    price?: number;
    /**
     * The maximum quantity of the order; how many shares to buy or sell at most
     */
    quantity?: number;
    /**
     * The visible quantity of the order.
     * If not set, then the entire order will be visible.
     * If set, then only visible_quantity shares will be visible on the limit orderbook at once,
     * but (remaining_quantity - visible_quantity) shares will be hidden.
     * Note that depending on the trading gateway,
     * hidden orders might have less priority than fully displayed orders.
     */
    visible_quantity?: number;
    order_type: OrderType;
    time_in_force: TimeInForceTypes;
    /**
     * A free-form field that's indented to identify the client owning the order.
     * It'll be included as-is in responses related to this order.
     * It's mapped to different FIX fields on different trading gateways,
     * but the mapping is as follows inet => ClRefID geniuminet => Account
     */
    client_reference?: string;
    /**
     * ID of an account in OMS
     */
    account_id?: number;
    /**
     * ID of a portfolio in OMS
     */
    portfolio_id?: number;
    /**
     * best bid on the market
     */
    reference_best_bid?: number;
    /**
     * best bid on the market
     */
    reference_best_bid_volume?: number;
    /**
     * best ask on the market
     */
    reference_best_ask?: number;
    /**
     * best ask volume on the market
     */
    reference_best_ask_volume?: number;
    /**
     * last prices
     */
    reference_last_price?: number;
    /**
     * percentage
     */
    commission?: number;
    commission_type?: CommissionType;
    /**
     * fixed amount
     */
    processing_fee?: number;
    broker?: string;
}


interface typeExtension { broker: string, visible_quantity: number }
export type OrderSubmitRequestKeys = keyof (OrderSubmitRequest & typeExtension)
export type DoubleCheckOrderKeysToDisplay = 'symbol' | 'side' | 'order_type' | 'quantity' | 'price' | 'time_in_force' | 'broker' | 'visible_quantity' | 'portfolio_id' | 'account_id';

export const DuplicateCheckKeysToDisplay: OrderSubmitRequestKeys[] = ['symbol', 'side', 'order_type', 'quantity', 'price', 'time_in_force', 'visible_quantity', 'portfolio_id', 'account_id'];
