import ReactDOM from 'react-dom/client';
import React from 'react';
// BUGSNAG import
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import './GlobalCSS/index.css';

// Golden Layout CSS, order matters
import './GlobalCSS/golden-layout.com_files_latest_css_goldenlayout-base.css';
import './GlobalCSS/golden-layout.com_files_latest_css_goldenlayout-dark-theme.css';
import './GlobalCSS/GoldenRatioDarkStyleOverWrite.css';

// toastify css
import 'react-toastify/dist/ReactToastify.css';

import reportWebVitals from './reportWebVitals';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { AppTheme } from './Config/MUITheme';
import { App } from './App';

import { LicenseInfo } from '@mui/x-license-pro';
import { Environment } from './Config/Environment';
import { Analytics } from 'analytics';
import googleAnalytics from '@analytics/google-analytics'

LicenseInfo.setLicenseKey('390091a71837f10df4598f4a6b01fdb5Tz05NDc2MSxFPTE3NTMzNjQyMDcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');
Bugsnag.start({
  apiKey: '76d2a834b152282aef4c09e6374205e1',
  plugins: [new BugsnagPluginReact()],
  releaseStage: Environment
})
//connect to google analytics
export const GoogleAnalytics = Analytics({
  app: 'Keldan Markaðir - Web',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-Q3BGWYFH10']
    })
  ]
})
let ErrorBoundary;
const reactPlugin = Bugsnag.getPlugin('react');
if (reactPlugin) {
  ErrorBoundary = reactPlugin.createErrorBoundary(React)
}
// Render main
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ErrorBoundary>
    <ThemeProvider theme={AppTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </ErrorBoundary>
);

// Add strict mode when in the mood for it
// </React.StrictMode>




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
