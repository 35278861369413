import { MenuItem, Select } from "@mui/material";
import cx from "classnames";
import { FieldInputProps, useField } from "formik";
import React from "react";
import { TranslationManager } from "../../../Translation/Translation";
interface ColorSelectProps extends FieldInputProps<string> {
    label?: string;
    labelId?: string;
    disabled?: boolean;
}
const ColorSelect: React.FC<ColorSelectProps> = ({ label, disabled, ...props }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [field] = useField({ ...props, name: props.name });
    return (
        <Select
            disabled={disabled}
            sx={{ height: '30px', width: '100%' }}
            {...field}
            style={{
                borderRadius: isOpen ? '6px 6px 0 0 ' : '6px',
                color: field.value === "buy" ? "var(--green-600)" : "var(--red-600)",
            }}
            labelId={props.labelId}
            onOpen={() => setIsOpen(true)}
            onClose={() => setIsOpen(false)}
            className={cx('formik-dropdown', { 'isOpen': isOpen })}
            MenuProps={{
                PaperProps: { elevation: 5 }
            }}
        >
            <MenuItem value="buy">{TranslationManager.getTranslation().New_Order_Modal.Dropdown_options.Buy}</MenuItem>
            <MenuItem value="sell">{TranslationManager.getTranslation().New_Order_Modal.Dropdown_options.Sell}</MenuItem>
        </Select>
    );
};

export default ColorSelect;