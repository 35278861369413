import { ColumnInfo } from "../../../Types/MUITable";

export const OrderColumnsInfo: ColumnInfo[] = [
  { name: "last_updated", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "created_at", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "symbol", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "broker", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "status", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "side", defaultHidden: false, dataType: "string", alignment: 'center' },
  { name: "quantity", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "price", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "average_price", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "currency", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "filled_quantity", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "last_price", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "last_updated_by", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "order_type", defaultHidden: false, dataType: "string", alignment: 'left' },
  { name: "remaining_quantity", defaultHidden: false, dataType: "number", alignment: 'right' },
  { name: "time_in_force", defaultHidden: false, dataType: "string", alignment: 'left' },

  { name: "isin", defaultHidden: true, dataType: "string", alignment: 'left' },
  { name: "secondary_order_id", defaultHidden: true, dataType: "string", alignment: 'left' },
  { name: "visible_quantity", defaultHidden: true, dataType: "number", alignment: 'right' },
  //   { name: "id", defaultHidden: true, dataType: "string" },
];
